import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import FilterButton from '../../Common/Components/FilterPanel/FilterButton'
import CreateReportModal from '../Components/CreateReportModal'
import theme from '../../theme/muiTheme'
import { Add } from '@material-ui/icons'
import {
  getCommissionReportPDFByReportId,
  getNewSettings,
  getReportMixGroupsByDivision,
  getReports,
  getUrlFromFilters,
  getReportCreators,
} from '../Data/TSSDataHelpers'
import {
  getConnectorString,
  getCurrentDateOnlyStringISOFormat,
  isAbortedFetchError,
  sanitizeStringForFilename,
} from '../../Common/Helpers/GeneralHelpers'
//@ts-ignore
import { useLocation, useHistory } from 'react-router-dom'
import {
  CommissionReportStatus,
  CommissionReportStatusKey,
  DialogModalSize,
  DialogModalType,
  ILibraryReport,
} from '../Logic/Types'
import {
  digestLibraryReport,
  getFilterChip,
} from '../Helpers/ReportLibraryHelpers'
import ReportLibraryTableLogical from '../Components/ReportLibraryTable/ReportLibraryTableLogical'
import {
  Filter,
  FilterComponentTypes,
  IActiveFilter,
  ICurrentCustomerAtom,
  ISimpleTableSettings,
} from '../../Common/Logic/Types'
import FilterPanelLogical from '../../Common/Components/FilterPanel/FilterPanelLogical'
import { getDivisionNames } from '../../Common/Helpers/DataHelpers'
import { useRecoilState, useRecoilValue } from 'recoil'
import { atomCurrentCustomer, atomJWT } from '../../Common/atoms'
import { IBaselineMixGroups } from '../Components/MixGroupOptionWithBaselineConditions'
import { orcaReadOnly, tssCanWrite } from '../Logic/TSSLogic'
import {
  READ_ONLY_DESCRIPTION,
  TSS_WRITE_DESCRIPTION,
  REPORT_LIBRARY_DEFAULT_TABLE_SETTINGS,
  REPORT_LIBRARY_HELPER_VARIABLES,
} from '../Constants/CommissionReportConstants'
import {
  atomErrorOpeningReport,
  atomReviewedReport,
  atomSelectedMixGroup,
} from '../../Common/tssAtomsB'
import FilterPanelChips from '../../Common/Components/FilterPanel/FilterPanelChips'
import DialogModal from '../../Common/Components/DialogModal/DialogModal'

const useStyles = makeStyles({
  //@ts-ignore
  ...theme.customClasses.filterPanel,
  filterPanelOpen: {
    //@ts-ignore
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '140px',
    zIndex: 50,
    [theme.breakpoints.up(1340)]: {
      top: '120px',
    },
  },
  filterPanel: {
    //@ts-ignore
    ...theme.customClasses.filterPanel.filterPanel,
    top: '140px',
    zIndex: -50,
    [theme.breakpoints.up(1340)]: {
      top: '120px',
    },
  },
  btnContainer: {
    marginTop: '0px',
    paddingLeft: '0px',
  },
  //@ts-ignore
  ...theme.customClasses.backdrop,
})

// Modal styling
const useModalStyles = makeStyles({
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses.successModal,
})

const useErrorModalStyles = makeStyles({
  // @ts-ignore
  ...theme.customClasses.errorModal,
  paragraphTwo: {
    marginTop: '16px',
  },
})

interface IReportLibraryRequestConstraints {
  Limit: number
  Offset: number
  SortOrder: string
  SortColumn: string
  divisionId?: number
  mixDesignId?: number
  createdByUserId?: number
  reportStatus?: CommissionReportStatusKey
}

interface IReportCreator {
  userId: number
  name: string
}

interface IReportLibraryFilterSettings {
  divisionId: number[]
  mixGroupId: number[]
  createdById: number[]
  status: CommissionReportStatusKey[]
}

interface IReportStatusOption {
  id: CommissionReportStatusKey
  name: CommissionReportStatus
}

const statusOptions: IReportStatusOption[] = [
  {
    id: 'Draft',
    name: CommissionReportStatus.Draft,
  },
  {
    id: 'PendingReview',
    name: CommissionReportStatus.PendingReview,
  },
  {
    id: 'Reviewed',
    name: CommissionReportStatus.Reviewed,
  },
]

export interface IProducerOptions {
  corporationId: number
  divisionId: number
  isImperial: number
  name: string
}

export default function ReportLibraryView() {
  const classes = useStyles()
  const modalClasses = useModalStyles()
  const errorModalClasses = useErrorModalStyles()

  const JWT = useRecoilValue(atomJWT)
  const [currentCustomer, setCurrentCustomer] = useRecoilState<
    ICurrentCustomerAtom
  >(atomCurrentCustomer)
  const { search } = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [reports, setReports] = useState<ILibraryReport[]>([])
  const [reportCount, setReportCount] = useState<number>(0)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [tableSettings, setTableSettings] = useState<ISimpleTableSettings>(
    //@ts-ignore (For Future Resolution)
    REPORT_LIBRARY_DEFAULT_TABLE_SETTINGS
  )
  const [filterSettings, setFilterSettings] = useState<
    IReportLibraryFilterSettings
  >({
    divisionId: [],
    mixGroupId: [],
    createdById: [],
    status: orcaReadOnly(JWT.roles) ? ['Reviewed'] : [],
  })
  const [producerOptions, setProducerOptions] = useState<IProducerOptions[]>([])
  const [mixGroupOptions, setMixGroupOptions] = useState<IBaselineMixGroups[]>(
    []
  )
  const [reportCreators, setReportCreators] = useState<IReportCreator[]>([])
  const [expandFilters, setExpandFilters] = useState({ filterBy: true })
  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false)
  const [filterCount, setFilterCount] = useState(0)
  const [activeFilters, setActiveFilters] = useState<IActiveFilter[]>([])
  const [reviewedReportInfo, setReviewedReportInfo] = useRecoilState(
    atomReviewedReport
  )
  const [isReviewSuccessModalOpen, setIsReviewSuccessModalOpen] = useState(
    reviewedReportInfo.reportId !== 0
  )
  const [isDownloadErrorModalOpen, setIsDownloadErrorModalOpen] = useState(
    false
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [currentMixGroup, setCurrentMixGroup] = useRecoilState(
    atomSelectedMixGroup
  )
  const reportInstruction = tssCanWrite(JWT.roles)
    ? TSS_WRITE_DESCRIPTION
    : READ_ONLY_DESCRIPTION

  const [
    showErrorOpeningReportModal,
    setShowErrorOpeningReportModal,
  ] = useRecoilState(atomErrorOpeningReport)
  const history = useHistory()

  const handleProducerChange = (_: Filter, value: IProducerOptions) => {
    setFilterSettings((prevSettings: IReportLibraryFilterSettings) => {
      const newSettings = {
        ...prevSettings,
        divisionId: value ? [value.divisionId] : [],
        mixGroupId: [],
      }
      return newSettings
    })
    setCurrentCustomer(prevCurrentCustomer => ({
      ...prevCurrentCustomer,
      division: value ? { divisionId: value.divisionId } : null,
      plant: null,
    }))
  }

  const handleMixGroupChange = (_: Filter, value: IBaselineMixGroups) => {
    setFilterSettings((prevSettings: IReportLibraryFilterSettings) => {
      const newSettings = {
        ...prevSettings,
        mixGroupId: value?.id ? [value.id] : [],
      }
      return newSettings
    })
  }

  const handleStatusChange = (_: Filter, value: IReportStatusOption) => {
    setFilterSettings(prevSettings => {
      const newSettings = {
        ...prevSettings,
        status: value ? [value.id] : [],
      }
      return newSettings
    })
  }

  const handleCreatedByChange = (_: Filter, value: IReportCreator) => {
    setFilterSettings(prevSettings => {
      const newSettings = {
        ...prevSettings,
        createdById: value ? [value.userId] : [],
      }
      return newSettings
    })
  }

  const deleteChipHandler = (filterToDelete: IActiveFilter) => {
    switch (filterToDelete.property) {
      case 'producer':
        setFilterSettings(prevSettings => {
          const newSettings = {
            ...prevSettings,
            divisionId: [],
            mixGroupId: [],
          }
          return newSettings
        })
        break
      case 'mixGroup':
        setFilterSettings(prevSettings => {
          const newSettings = {
            ...prevSettings,
            mixGroupId: [],
          }
          return newSettings
        })
        break
      case 'status':
        setFilterSettings(prevSettings => {
          const newSettings = {
            ...prevSettings,
            status: [],
          }
          return newSettings
        })
        break
      case 'createdBy':
        setFilterSettings(prevSettings => {
          const newSettings = {
            ...prevSettings,
            createdById: [],
          }
          return newSettings
        })
        break
      default:
        return
    }
  }

  const deleteAllChipHandler = () => {
    setFilterSettings({
      divisionId: [],
      mixGroupId: [],
      createdById: [],
      status: [],
    })
  }

  /** Determines the value of a filter. */
  const getValue = (filter: Filter) => {
    switch (filter.property) {
      case 'producer': {
        const matchedProducerOption = producerOptions.find(
          option => option.divisionId === filterSettings.divisionId[0]
        )
        return matchedProducerOption || null
      }

      case 'mixGroup': {
        const matchedMixGroupOption = mixGroupOptions.find(
          option => option.id === filterSettings.mixGroupId[0]
        )
        return matchedMixGroupOption || null
      }

      case 'status': {
        const matchedStatusOption = statusOptions.find(
          option => option.id === filterSettings.status[0]
        )
        return matchedStatusOption || null
      }

      case 'createdBy': {
        const matchedCreatedByOption = reportCreators.find(
          option => option.userId === filterSettings.createdById[0]
        )
        return matchedCreatedByOption || null
      }

      default:
        return null
    }
  }

  const getIsMixGroupFilterDisabled = () => {
    return filterSettings.divisionId.length === 0
  }

  const getIsStatusFilterDisabled = () => {
    return orcaReadOnly(JWT.roles)
  }

  const handleDownloadPDF = async () => {
    setIsLoading(true)
    try {
      const reportId = reviewedReportInfo.reportId
      const divisionName = sanitizeStringForFilename(
        reviewedReportInfo.divisionName
      )
      const res = await getCommissionReportPDFByReportId(reportId)
      if (res.ok) {
        const currentDate = getCurrentDateOnlyStringISOFormat()
        const blob = await res.blob()
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.href = url
        link.download = `CommReport_${divisionName}_${currentDate}.pdf`

        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
        resetReviewedReportAtom()
      } else {
        const errorResponse = await res.json()
        throw new Error(errorResponse.error)
      }
    } catch (err) {
      console.error(err)
      setIsDownloadErrorModalOpen(true)
    } finally {
      setIsReviewSuccessModalOpen(false)
      setIsLoading(false)
    }
  }

  const filterPanel = [
    {
      name: 'FILTER BY',
      category: 'filterBy',
      filters: [
        {
          property: 'producer',
          name: 'Producer',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: producerOptions,
          onChangeHandler: handleProducerChange,
        },
        {
          property: 'mixGroup',
          name: 'Mix Group',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: mixGroupOptions,
          disabled: getIsMixGroupFilterDisabled,
          onChangeHandler: handleMixGroupChange,
        },
        {
          property: 'status',
          name: 'Status',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: statusOptions,
          disabled: getIsStatusFilterDisabled,
          onChangeHandler: handleStatusChange,
        },
        {
          property: 'createdBy',
          name: 'Created By',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: reportCreators,
          onChangeHandler: handleCreatedByChange,
        },
      ],
    },
  ]

  const filterButtonClickHandler = () => {
    setIsFilterPanelOpen(prev => !prev)
  }

  /** Get Division Names for Producer filter options */
  useEffect(() => {
    if (producerOptions.length === 0) {
      setIsLoading(true)
      getDivisionNames(true)
        .then(result => {
          if (result.ok)
            result
              .json()
              .then(data => {
                setProducerOptions(data)
                setIsLoading(false)
              })
              .catch(e => {
                setIsLoading(false)
                console.log(e)
              })
        })
        .catch(e => {
          setIsLoading(false)
          console.log(e)
        })
    }
  }, [producerOptions, setProducerOptions])

  /** Get Mix Group options for autocomplete */
  useEffect(() => {
    if (filterSettings.divisionId.length === 0) return
    setIsLoading(true)
    const getMixGroupOptions = async () => {
      try {
        const responseJson = await getReportMixGroupsByDivision(
          filterSettings.divisionId[0],
          { onlyIncludeReportMixGroups: true }
        )
        const response = await responseJson.json()
        const convertedOptions = response.map((option: any) => {
          return {
            id: option.mixDesignId,
            name: option.mixCode,
            mixCode: option.mixCode,
            baseLineCementReduction: option.baseLineCementReduction,
            baseLineCO2: option.baseLineCO2,
          }
        })
        setMixGroupOptions(convertedOptions)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    }
    getMixGroupOptions()
  }, [filterSettings.divisionId])

  useEffect(() => {
    if (!isFirstRender) {
      const settings = {
        ...filterSettings,
      }
      window.history.replaceState(
        null,
        'New Page Title',
        getUrlFromFilters(settings, 'ReportLibrary')
      )
    } else {
      const params = new URLSearchParams(search)
      const settings = {
        ...filterSettings,
      }

      const newSettings: any = getNewSettings(settings, params)
      if (newSettings['divisionId'][0])
        newSettings['divisionId'][0] = Number(newSettings['divisionId'][0])

      if (currentCustomer.division)
        newSettings['divisionId'][0] = currentCustomer.division.divisionId

      if (newSettings['divisionId'][0] && newSettings['mixGroupId'][0]) {
        newSettings['mixGroupId'][0] = Number(newSettings['mixGroupId'][0])
      } else {
        newSettings['mixGroupId'] = []
      }

      if (newSettings['createdById'][0])
        newSettings['createdById'][0] = Number(newSettings['createdById'][0])

      setFilterSettings(newSettings)
      setIsFirstRender(false)
    }
  }, [filterSettings, isFirstRender, search, currentCustomer])

  useEffect(() => {
    if (isFirstRender) return
    setIsLoading(true)
    const abortController = new AbortController()
    const { order, orderBy, rowsPerPage, page } = tableSettings
    const constraints: IReportLibraryRequestConstraints = {
      Limit: rowsPerPage,
      Offset: page * rowsPerPage,
      SortOrder: order === 'asc' ? 'Ascending' : 'Descending',
      SortColumn: orderBy,
    }

    if (filterSettings.divisionId.length > 0) {
      constraints.divisionId = filterSettings.divisionId[0]
    }
    if (filterSettings.mixGroupId.length > 0) {
      constraints.mixDesignId = filterSettings.mixGroupId[0]
    }
    if (filterSettings.createdById.length > 0) {
      constraints.createdByUserId = filterSettings.createdById[0]
    }
    if (filterSettings.status.length > 0) {
      constraints.reportStatus = filterSettings.status[0]
    }

    getReports(constraints, {}, abortController)
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            const digestedReports = digestLibraryReport(data.results)
            setReports(digestedReports)
            setReportCount(data.count)
            setIsLoading(false)
          })
        }
      })
      .catch(e => {
        setIsLoading(false)
        // aborted fetch requests are fine, we can ignore them since we purposefully abort
        if (!isAbortedFetchError(e)) {
          console.error(e)
        }
      })
  }, [tableSettings, filterSettings, isFirstRender])

  useEffect(() => {
    const filterChips = []

    const producerChip = getFilterChip(
      filterSettings,
      producerOptions,
      'divisionId',
      'divisionId',
      'producer'
    )
    if (producerChip) filterChips.push(producerChip)

    const mixGroupChip = getFilterChip(
      filterSettings,
      mixGroupOptions,
      'mixGroupId',
      'id',
      'mixGroup'
    )
    if (mixGroupChip) filterChips.push(mixGroupChip)

    const statusChip = getFilterChip(
      filterSettings,
      statusOptions,
      'status',
      'id',
      'status'
    )
    if (statusChip) filterChips.push(statusChip)

    const createdByChip = getFilterChip(
      filterSettings,
      reportCreators,
      'createdById',
      'userId',
      'createdBy'
    )
    if (createdByChip) filterChips.push(createdByChip)

    setActiveFilters(filterChips)
    setFilterCount(filterChips.length)
  }, [filterSettings, mixGroupOptions, producerOptions, reportCreators])

  useEffect(() => {
    const fetchReportCreators = async () => {
      try {
        const res = await getReportCreators()
        if (res.ok) {
          const data = await res.json()
          setReportCreators(data)
        }
      } catch (e) {
        console.error(e)
      }
    }

    fetchReportCreators()
  }, [])

  const resetReviewedReportAtom = () => {
    setReviewedReportInfo(prevState => ({
      ...prevState,
      reportId: 0,
      divisionName: '',
      mixCodes: [],
    }))
  }

  return (
    <>
      <Backdrop open={isLoading} className={classes.loadingScreen}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container
        maxWidth="xl"
        style={{ padding: '0em 0.5em 1.5em 0.5em', position: 'relative' }}
      >
        <div style={{ position: 'relative' }}>
          <div className={classes.filterPanelContainer}>
            <FilterPanelLogical
              //@ts-ignore (For Future Resolution)
              filterPanel={filterPanel}
              parentClasses={classes}
              expandFilters={expandFilters}
              //@ts-ignore (For Future Resolution)
              setExpandFilters={setExpandFilters}
              //@ts-ignore (For Future Resolution)
              helperVariables={REPORT_LIBRARY_HELPER_VARIABLES}
              open={isFilterPanelOpen}
              //@ts-ignore (For Future Resolution)
              setOpen={setIsFilterPanelOpen}
              //@ts-ignore (For Future Resolution)
              getValue={getValue}
            />
          </div>
          <div
            className={
              isFilterPanelOpen
                ? classes.wrapperShifted
                : classes.wrapperUnshifted
            }
          >
            <Typography variant="h2">Report Library</Typography>
            <Typography variant="body1" style={{ margin: '24px 0px' }}>
              {reportInstruction}
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item>
                <FilterButton
                  open={false}
                  filterCount={filterCount}
                  parentClasses={classes}
                  onClickHandler={filterButtonClickHandler}
                />
                <FilterPanelChips
                  activeFilters={activeFilters}
                  chipClickHandler={deleteChipHandler}
                  buttonClickHandler={deleteAllChipHandler}
                  isDeleteDisabled={orcaReadOnly(JWT.roles)}
                />
              </Grid>
              <Grid item>
                {tssCanWrite(JWT.roles) && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => setModalOpen(true)}
                  >
                    Create Report
                  </Button>
                )}
              </Grid>
            </Grid>
            {
              <Box style={{ marginTop: '32px' }}>
                <ReportLibraryTableLogical
                  reports={reports}
                  reportCount={reportCount}
                  tableSettings={tableSettings}
                  setTableSettings={setTableSettings}
                  roles={JWT.roles}
                />
              </Box>
            }
          </div>
        </div>
        <CreateReportModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          currentCustomer={currentCustomer}
          setCurrentCustomer={setCurrentCustomer}
          currentMixGroup={currentMixGroup}
          setCurrentMixGroup={setCurrentMixGroup}
        />
        <DialogModal
          modalOpen={isReviewSuccessModalOpen}
          modalType={DialogModalType.success}
          headerText={'Report Successfully Reviewed & Saved'}
          contentText={
            <Typography variant="body2">
              <strong>{reviewedReportInfo.divisionName}</strong> report for{' '}
              {reviewedReportInfo.mixes.map((mix, index) => (
                <React.Fragment key={mix.mixDesignId}>
                  <strong>{mix.mixCode}</strong>
                  {getConnectorString(reviewedReportInfo.mixes.length, index)}
                </React.Fragment>
              ))}{' '}
              successfully reviewed and saved.
            </Typography>
          }
          parentClasses={modalClasses}
          modalSize={DialogModalSize.Small}
          handleCancel={() => {
            resetReviewedReportAtom()
            setIsReviewSuccessModalOpen(false)
          }}
          handleConfirm={() => {
            handleDownloadPDF()
          }}
          cancelButtonText={'Close'}
          confirmButtonText={'Download Report'}
          hasAction={true}
          hasCard={false}
        />

        <DialogModal
          modalOpen={isDownloadErrorModalOpen}
          modalType={DialogModalType.error}
          headerText={'Error Downloading Report'}
          contentText={
            <>
              <Typography variant="body2">
                An error occurred when trying to download the report. This can
                happen when there is an issue connecting to the server or an
                issue with your internet.
              </Typography>
              <Typography variant="body2" style={{ marginTop: '1em' }}>
                Please try to troubleshoot these issues and retry downloading
                the report. If you are still unable to save the report, send a
                message to the #info-orca Slack channel and we can help resolve
                this issue.
              </Typography>
            </>
          }
          parentClasses={errorModalClasses}
          modalSize={DialogModalSize.Small}
          handleCancel={() => {
            resetReviewedReportAtom()
            setIsDownloadErrorModalOpen(false)
          }}
          handleConfirm={() => {
            setIsDownloadErrorModalOpen(false)
            handleDownloadPDF()
          }}
          cancelButtonText={'Close'}
          confirmButtonText={'Retry Download'}
          hasAction={true}
          hasCard={false}
        />
        <DialogModal
          modalOpen={showErrorOpeningReportModal}
          modalType={DialogModalType.error}
          headerText={'Error Opening Report'}
          contentText={
            <>
              <Typography variant="body2">
                An error occurred when trying to open the report you selected.
                This can happen when mix groups and variations were changed or
                switched since the time of saving the report.
              </Typography>
              <Typography
                variant="body2"
                className={errorModalClasses.paragraphTwo}
              >
                Please try to troubleshoot these issues on the Add Data page and
                retry opening the report. If you are still unable to access the
                report, send a message to the #info-orca Slack channel and we
                can help resolve this issue.
              </Typography>
            </>
          }
          parentClasses={errorModalClasses}
          modalSize={DialogModalSize.Small}
          handleCancel={() => {
            setShowErrorOpeningReportModal(false)
          }}
          handleConfirm={() => {
            setShowErrorOpeningReportModal(false)
            history.push('/Concrete/AddData')
          }}
          cancelButtonText={'Close'}
          confirmButtonText={'Go To Add Data'}
          hasAction={true}
          hasCard={false}
        />
      </Container>
    </>
  )
}
