import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { metricSwitch } from '../../Common/atoms'
import { useRecoilValue } from 'recoil'

GeneralizedEnhancedTableHead.propTypes = {
  /** Function to fire after clicking the sort label */
  onRequestSort: PropTypes.func,
  /** An array of objects that will be mapped to the table head cells
   *
   * In the shape of: [
   * { id: "headId", numeric: false, disablePadding: true, label: "Head Label", sortable: true },
   * ]
   */
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      sortable: PropTypes.bool,
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      tooltip: PropTypes.string,
      metricLabel: PropTypes.string,
    })
  ),
  /** The direction order of the selected table head */
  order: PropTypes.oneOf(['asc', 'desc']),
  /** The cell ID that you want to order by */
  orderBy: PropTypes.string,

  checkboxColumn: PropTypes.bool,
}

const useStyles = makeStyles({
  checkboxCol: {
    paddingLeft: '0px',
    paddingRight: '0px',
    marginLeft: '0px',
  },
  collapseCol: {
    paddingLeft: '10px',
  },
})

GeneralizedEnhancedTableHead.defaultProps = {
  checkboxColumn: false,
}

/** A generalized enhanced table head that will handle sorting  */
function GeneralizedEnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, checkboxColumn } = props
  const classes = useStyles()

  const isMetric = useRecoilValue(metricSwitch)

  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  function handleMetricString(impLabel, metLabel) {
    if (isMetric && metLabel) {
      return metLabel
    }
    return impLabel
  }

  return (
    <TableHead>
      <TableRow>
        {checkboxColumn && (
          <TableCell padding="none" className={classes.collapseCol} />
        )}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              headCell.id === 'selectAllIcon' ? classes.checkboxCol : false
            }
          >
            <Tooltip arrow title={headCell.tooltip || ''}>
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  style={{ width: headCell.width }}
                >
                  {handleMetricString(headCell.label, headCell.metricLabel)}
                </TableSortLabel>
              ) : (
                <span>
                  {handleMetricString(headCell.label, headCell.metricLabel)}
                </span>
              )}
            </Tooltip>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default GeneralizedEnhancedTableHead
