import React, { Dispatch, SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'
import cloneDeep from 'lodash/cloneDeep'
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import {
  CommissionReportOutlierRow,
  ICommissionReportSettings,
  IMixSelection,
  IOutlierSettings,
} from '../../Logic/Types'
import { baseColors } from '../../../theme/colors'
import { NO_OUTLIER_DESCRIPTION } from '../../Constants/CommissionReportConstants'
import { getCommissionReportBaleenUrl } from '../../Helpers/CommissionReportHelpers'
import { useOutlierRows } from '../../Logic/Hooks'

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  tableHeader: {
    borderBottom: 'none',
    backgroundColor: 'transparent',
    paddingTop: '0px',
    paddingBottom: '0',
  },
  tableCell: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  lastRowCell: {
    borderBottom: 'none',
  },
})

export interface IOutliersProps {
  reportSettings: ICommissionReportSettings | undefined
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  mixSelections: IMixSelection[]
  tabValue: number
  setHasUserInput: Dispatch<SetStateAction<boolean>>
}

export default function Outliers(props: Readonly<IOutliersProps>) {
  const {
    reportSettings,
    setReportSettings,
    mixSelections,
    tabValue,
    setHasUserInput,
  } = props
  const classes = useStyles()

  const [outlierRows, setOutlierRows] = useState<CommissionReportOutlierRow[]>(
    []
  )

  /** Custom hook to digest outliers into rows and setReportSettings to false when no outliers */
  useOutlierRows(mixSelections, tabValue, setOutlierRows, setReportSettings)

  const mixDesignId = mixSelections?.[tabValue]?.mixDesignId
  const settings = reportSettings?.mixDesignSettings.find(
    settings => settings.mixDesignId === mixDesignId
  )
  const path = getCommissionReportBaleenUrl(
    mixSelections[tabValue],
    reportSettings
  )

  const handleClick = (key: keyof IOutlierSettings) => {
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings) as ICommissionReportSettings
      const mixDesignSettings = newSettings.mixDesignSettings[tabValue]
      const updatedOutlierSettings = {
        ...mixDesignSettings.outlierSettings,
        [key]: !mixDesignSettings.outlierSettings[key],
      }
      mixDesignSettings.outlierSettings = updatedOutlierSettings
      return {
        ...newSettings,
      }
    })
    setHasUserInput(true)
  }
  return (
    <>
      <Typography
        variant="h3"
        className={classes.title}
        style={{
          color: outlierRows?.length
            ? baseColors.text.primary
            : baseColors.text.disabled,
        }}
      >
        <Checkbox
          color="primary"
          checked={settings?.outlierSettings?.show}
          onClick={() => handleClick('show')}
          disabled={!outlierRows?.length}
          data-testid={'showOutlier-checkbox'}
        />
        Outliers
      </Typography>
      {outlierRows && outlierRows.length > 0 ? (
        <Table
          aria-label="outlier reasons table"
          style={{ marginBottom: '16px' }}
        >
          <TableHead style={{ borderBottom: 'none' }}>
            <TableRow>
              <TableCell
                className={classes.tableHeader}
                style={{ width: '15%' }}
              >
                <Typography variant="h4">Ticket ID</Typography>
              </TableCell>
              <TableCell
                className={classes.tableHeader}
                style={{ width: '15%' }}
              >
                <Typography variant="h4">Condition</Typography>
              </TableCell>
              <TableCell
                className={classes.tableHeader}
                style={{ width: '70%' }}
              >
                <Typography variant="h4">Reason</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outlierRows?.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell
                  className={`${classes.tableCell} ${
                    index === outlierRows.length - 1 ? classes.lastRowCell : ''
                  }`}
                >
                  {row.id}
                </TableCell>
                <TableCell
                  className={`${classes.tableCell} ${
                    index === outlierRows.length - 1 ? classes.lastRowCell : ''
                  }`}
                >
                  {row.description}
                </TableCell>
                <TableCell
                  className={`${classes.tableCell} ${
                    index === outlierRows.length - 1 ? classes.lastRowCell : ''
                  }`}
                >
                  {row.reason}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography style={{ marginLeft: '10px' }}>
          {NO_OUTLIER_DESCRIPTION}
        </Typography>
      )}

      <Button
        startIcon={<ArrowBack />}
        color="primary"
        size="medium"
        style={{ textTransform: 'capitalize' }}
        component={Link}
        target="_blank"
        to={`/Concrete/Baleen?${path}`}
      >
        View Baleen
      </Button>
    </>
  )
}
