import React from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  InfoOutlined,
  ReportProblemOutlined,
  CheckCircleOutlineOutlined,
  CancelOutlined,
  DeleteOutlined,
} from '@material-ui/icons'
import { baseColors } from '../../../theme/colors'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { DialogModalSize, DialogModalType } from '../../../TSS/Logic/Types'
import Close from '@material-ui/icons/Close'

export interface IDialogModalProps {
  /** Determines if modal is open. */
  modalOpen: boolean
  /** The type of the modal (Merge, Edit, Success etc.). */
  modalType: DialogModalType
  /** Handles the modal cancel button functionality. */
  handleCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handles the modal Edit button functionality. */
  handleConfirm?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Boolean for loading state. */
  isLoading?: boolean
  /** String to be displayed at the dialog header. */
  headerText: string
  /** String to be displayed at the dialog body. */
  contentText: string | JSX.Element
  /** String to be displayed at the cancel button. */
  confirmButtonText?: string
  /** String to be displayed at the confirm button. */
  cancelButtonText?: string
  /** Component to be displayed after the prompt is expanded. */
  cardContent?: React.ReactNode
  /** Style classes defined in the parent. */
  parentClasses: ClassNameMap<string>
  /** Determines if the modal has action buttons. */
  hasAction?: boolean
  /** Determines if action buttons should be disabled. */
  shouldDisable?: boolean
  /** Determines if the modal includes a card component. */
  hasCard?: boolean
  /** The size of the modal. */
  modalSize: DialogModalSize
  /** Determines if the modal includes content that isn't a card */
  hasBody?: boolean
  /** Component to be displayed that is not inside a card */
  bodyContent?: React.ReactNode
  /** Determines if the modal has a destructive action. */
  hasDeleteAction?: boolean
  /** The text for the destructive action button. */
  deleteButtonText?: string
  /** Handles the modal Delete button/text functionality. */
  handleDelete?: () => void
  /** Handles the closing action for the success modal */
  handleClose?: () => void
}

const DialogModal = (props: IDialogModalProps) => {
  const {
    headerText,
    contentText,
    parentClasses,
    modalOpen,
    modalType,
    isLoading,
    handleCancel,
    handleConfirm,
    handleDelete,
    handleClose,
    confirmButtonText,
    cancelButtonText,
    deleteButtonText,
    cardContent,
    hasAction,
    hasCard,
    shouldDisable,
    modalSize,
    hasBody,
    bodyContent,
    hasDeleteAction,
  } = props

  interface HeaderIcons {
    [key: string]: { name: React.ElementType; color: string }
  }

  interface DeleteIcons {
    [key: string]: { name: React.ElementType; color: string }
  }

  const headerIcons: HeaderIcons = {
    info: { color: baseColors.info.dark, name: InfoOutlined },
    warning: { color: baseColors.warning.hover, name: ReportProblemOutlined },
    success: {
      color: baseColors.success.hover,
      name: CheckCircleOutlineOutlined,
    },
    error: {
      color: baseColors.error.headerText,
      name: CancelOutlined,
    },
  }

  const deleteIcons: DeleteIcons = {
    warning: {
      color: baseColors.error.main,
      name: DeleteOutlined,
    },
  }

  const getHeaderIcon = () => {
    const Icon = headerIcons[modalType].name
    return (
      <Icon
        fontSize="small"
        style={{ display: 'flex', alignItems: 'center' }}
        className={parentClasses.icon ? parentClasses.icon : classes.icon}
      />
    )
  }

  const getDeleteIcon = () => {
    const Icon = deleteIcons[modalType].name
    return (
      <Icon
        fontSize="small"
        className={
          parentClasses.deleteIcon
            ? parentClasses.deleteIcon
            : classes.deleteIcon
        }
      />
    )
  }

  const useStyles = makeStyles({
    icon: {
      color: headerIcons[modalType].color,
    },
    deleteIcon: {
      color: baseColors.error.main,
    },
  })

  const classes = useStyles()

  return (
    <Dialog
      open={modalOpen}
      scroll="body"
      fullWidth
      maxWidth={modalSize}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <Paper elevation={1}>
        <Grid container>
          <Grid
            container
            item
            justify="space-between"
            alignItems="center"
            className={parentClasses.headerBackground}
          >
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={0}>
                <Grid item>{getHeaderIcon()}</Grid>
                <Grid item>
                  <Typography
                    variant="h5"
                    className={`${parentClasses.fontColor} ${parentClasses.header}`}
                  >
                    {headerText}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {modalType === DialogModalType.success && handleClose && (
              <Grid item>
                <IconButton
                  data-testid="close-button"
                  onClick={handleClose}
                  className={parentClasses.closeIcon}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <DialogContent className={parentClasses.dialogContent}>
              <Typography
                variant="body2"
                className={parentClasses.fontColor}
                style={{ marginBottom: hasAction ? '8px' : '16px' }}
              >
                {contentText}
              </Typography>
              {hasCard && cardContent}
              {hasBody && bodyContent}
              {hasAction && (
                <DialogActions className={parentClasses.dialogActions}>
                  <Grid
                    container
                    justifyContent={
                      hasDeleteAction ? 'space-between' : 'flex-end'
                    }
                    alignItems="center"
                    spacing={2}
                    className={parentClasses.gridContainer}
                  >
                    {hasDeleteAction && (
                      <Button
                        data-testid="delete-button"
                        startIcon={getDeleteIcon()}
                        onClick={handleDelete}
                        className={parentClasses.deleteButton}
                      >
                        {deleteButtonText}
                      </Button>
                    )}
                    <Grid item>
                      {cancelButtonText && (
                        <Button
                          data-testid="cancel-button"
                          variant="outlined"
                          className={parentClasses.cancelButton}
                          onClick={handleCancel}
                          style={{ marginRight: '8px' }}
                        >
                          {cancelButtonText}
                        </Button>
                      )}
                      <Button
                        data-testid="confirm-button"
                        variant="contained"
                        className={parentClasses.confirmButton}
                        onClick={handleConfirm}
                        disabled={shouldDisable}
                      >
                        {isLoading && (
                          <CircularProgress
                            color="inherit"
                            size="16px"
                            className={
                              parentClasses.confirmButtonLoadingSpinner
                            }
                          />
                        )}
                        {confirmButtonText}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              )}
            </DialogContent>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  )
}

export default DialogModal
