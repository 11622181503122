import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import { useRecoilValue } from 'recoil'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { atomJWT } from '../../Common/atoms'
import QuicksightDashboard from '../Components/QuicksightDashboard'
import {
  dashboardNameList,
  quicksightDashboardList,
} from '../Constants/UrlMapping'

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
  },
}))

export default function ProductionDashboards(props) {
  const { currentDashboard, setCurrentDashboard } = props
  const JWT = useRecoilValue(atomJWT)
  const hasPrintAccess =
    JWT.roles.includes('TSS_W') || JWT.roles.includes('OrcaAdmin')
  const classes = useStyles()
  const isQuickSightEnabled =
    process.env.REACT_APP_IS_QUICKSIGHT_ENABLED === 'true'
  const history = useHistory()
  const [printInvoked, setPrintInvoked] = useState(false)

  const changeCurrentDashboard = event => {
    setCurrentDashboard(event.target.value)
    history.push(`QuickSight/${event.target.value}`)
  }

  const renderQuickSightComponent = () => {
    if (isQuickSightEnabled) {
      return (
        <QuicksightDashboard
          dashboard={quicksightDashboardList[currentDashboard]}
          printInvoked={printInvoked}
          setPrintInvoked={setPrintInvoked}
          height="1500px"
          width="98%"
        />
      )
    } else {
      return (
        <Alert severity="warning">
          <AlertTitle>Warning: Ongoing Maintenance</AlertTitle>
          Dashboards are disabled during planned maintenance window. If urgent,
          please contact #info-devops.
        </Alert>
      )
    }
  }

  const handlePrintClick = () => {
    setPrintInvoked(true)
  }

  return (
    <Grid
      container
      direction="row"
      justifycontent="flex-start"
      alignItems="flex-start"
      spacing={2}
      style={{ marginTop: '1%', marginLeft: '8px', marginRight: '8px' }}
    >
      <Grid item>
        <Typography variant="h2">QuickSight Dashboard</Typography>
      </Grid>

      {isQuickSightEnabled && (
        <Grid item xs={12}>
          <Grid container direction="row" justifycontent="space-between">
            <Grid item xs={6} sm={3} md={2}>
              <FormControl id="form-select-dashboard">
                <InputLabel>Select Dashboard</InputLabel>
                <Select
                  id="dashboard-list"
                  value={currentDashboard}
                  defaultValue=""
                  label="Select Dashboard"
                  onChange={changeCurrentDashboard}
                >
                  {dashboardNameList.map(dashboard => (
                    <MenuItem
                      value={dashboard}
                      key={quicksightDashboardList[dashboard].dashboardId}
                    >
                      {quicksightDashboardList[dashboard].title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={6}
              sm={9}
              md={10}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              {hasPrintAccess && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePrintClick}
                >
                  Show Print Preview
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          {renderQuickSightComponent()}
        </Paper>
      </Grid>
    </Grid>
  )
}

ProductionDashboards.propTypes = {
  currentDashboard: PropTypes.string.isRequired,
  setCurrentDashboard: PropTypes.func.isRequired,
}
