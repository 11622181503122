import { useCallback, useEffect, useState, useRef } from 'react'
import {
  createEmbeddingContext,
  DashboardExperience,
  EmbeddingEvents,
} from 'amazon-quicksight-embedding-sdk'
import QuicksightHelper from '../Helpers/QuicksightHelper'

export interface IDashboardConfig {
  title: string
  baseUrl: string
  dashboardId: string
  dashboardRegion: string
  dashboardName: string
}

export interface IUseQuicksightDashboardProps {
  dashboard: IDashboardConfig
  height?: string
  width?: string
  printInvoked: boolean
  setPrintInvoked: (value: boolean) => void
}

const useQuicksightDashboard = ({
  dashboard,
  height = '1500px',
  width = '98%',
  printInvoked,
  setPrintInvoked,
}: IUseQuicksightDashboardProps) => {
  const containerRef = useRef(null)
  const [
    dashboardExperience,
    setDashboardExperience,
  ] = useState<DashboardExperience | null>(null)
  const [isContentLoaded, setIsContentLoaded] = useState<boolean>(false)

  // CALLBACKS
  const handleOnMessage = useCallback(
    async (messageEvent: EmbeddingEvents) => {
      const eventName = messageEvent.eventName

      switch (eventName) {
        case 'CONTENT_LOADED': {
          setIsContentLoaded(true)
          break
        }

        case 'ERROR_OCCURRED': {
          console.error('Error occurred:', messageEvent)
          break
        }

        case 'SIZE_CHANGED': {
          setPrintInvoked(false)
          break
        }

        default: {
          break
        }
      }
    },
    [setPrintInvoked]
  )

  const embedDashboard = useCallback(async () => {
    const invokeUrl = dashboard.baseUrl
    const urlParams = {
      dashboardId: dashboard.dashboardId,
      dashboardName: dashboard.dashboardName,
      dashboardRegion: dashboard.dashboardRegion,
    }
    const embedUrl = await QuicksightHelper.fetchQuicksightEmbedURL(
      invokeUrl,
      urlParams
    )
    const embeddingContext = await createEmbeddingContext()

    if (containerRef.current) {
      const frameOptions = {
        url: embedUrl,
        container: containerRef.current,
        height,
        width,
      }

      const contentOptions = {
        toolbarOptions: {
          export: false,
          undoRedo: false,
          reset: false,
        },
        sheetOptions: {
          singleSheet: false,
          emitSizeChangedEventOnSheetChange: false,
        },
        attributionOptions: {
          overlayContent: false,
        },
        onMessage: handleOnMessage,
      }

      const dashboardExperience = await embeddingContext.embedDashboard(
        frameOptions,
        contentOptions
      )
      setDashboardExperience(dashboardExperience)
    }
  }, [
    dashboard.baseUrl,
    dashboard.dashboardId,
    dashboard.dashboardName,
    dashboard.dashboardRegion,
    handleOnMessage,
    height,

    width,
  ])

  const printDashboard = useCallback(async () => {
    // Ensure that the dashboard and the content is loaded
    if (isContentLoaded && dashboardExperience) {
      try {
        await dashboardExperience.initiatePrint()
      } catch (error) {
        console.error('Print initiation failed:', error)
      }
    }
  }, [dashboardExperience, isContentLoaded])

  // EFFECTS
  useEffect(() => {
    embedDashboard()
  }, [embedDashboard])

  useEffect(() => {
    if (printInvoked) {
      printDashboard()
    }
  }, [printDashboard, printInvoked])

  return { containerRef, embedDashboard, handleOnMessage, printDashboard }
}

const hook = {
  useQuicksightDashboard,
}

export default hook
