import { subDays } from 'date-fns'
import dataHelpers from '../../../Common/Helpers/DataHelpers'
import {
  IActiveFilter,
  ICustomerFilterData,
  ICustomerFilterOptions,
  ICustomerFilterPlant,
  ICustomerFilterProducer,
  ICustomerFilterSystem,
  ISonarAlarmType,
  ISonarFilter,
  ISonarFilterOption,
  ISonarFrontendFilter,
} from '../../../Common/Logic/Types'
import {
  defaultCustomerFilterOptions,
  alarmSeverityOptions,
  technologyTypes,
  snoozeStatusOptions,
} from '../Constants/SonarConstants'
import { getLabelForDateRange } from '../Helpers/SonarHelpers'
import { formatDateObjectToString } from '../../../Common/Helpers/GeneralHelpers'

const processSelectedTechnologyFilter = (technology: string[]) => {
  const activeFilters: IActiveFilter[] = []
  const allTechnologiesIncluded = technologyTypes.every(technologyType =>
    technology.includes(technologyType.id)
  )

  if (allTechnologiesIncluded) {
    activeFilters.push({
      id: 'technology-all',
      label: 'Technology: All',
      property: 'technology',
    })
  } else {
    technologyTypes.forEach(technologyType => {
      if (technology.includes(technologyType.id)) {
        activeFilters.push({
          id: `technology-${technologyType.id}`,
          label: `Technology: ${technologyType.name}`,
          property: 'technology',
        })
      }
    })
  }

  return activeFilters
}

const processSelectedCustomerFilter = (
  sonarFilter: ISonarFilter,
  customerFilterOptions: ICustomerFilterOptions
) => {
  const activeFilters: IActiveFilter[] = []

  if (!sonarFilter) return activeFilters

  Object.keys(sonarFilter).forEach(key => {
    if (sonarFilter[key]?.length && customerFilterOptions[key]?.length) {
      const selectedOption = customerFilterOptions[key].find(
        option => option.id.toString() === sonarFilter[key][0].toString()
      )

      if (selectedOption)
        activeFilters.push({
          id: `customer-${key}`,
          label:
            key === 'systemId'
              ? `System ID: ${selectedOption.name}`
              : `${selectedOption.name}`,
          property: 'customer',
        })
    }
  })
  return activeFilters
}

const processSelectedDateRangeFilter = (
  tabValue: number,
  sonarFilter: ISonarFilter,
  range: string | null,
  startDateSelected?: string | null,
  endDateSelected?: string | null
) => {
  const activeFilters: IActiveFilter[] = []

  Object.keys(sonarFilter).forEach(key => {
    if (key === 'lastSeenDateRange') {
      if (sonarFilter[key] !== null && range) {
        activeFilters.push({
          id: 'date-range',
          label: getLabelForDateRange(
            tabValue,
            range,
            startDateSelected,
            endDateSelected
          ),
          property: 'dateRange',
        })
      }
    }
  })

  return activeFilters
}

const processSelectedTimeDownFilter = (timeDown: string, tabValue: number) => {
  const activeFilters: IActiveFilter[] = []
  // ENSURE TO MAP THE CORRECT TAB VALUE FOR THE DOWN SYSTEM WHEN OTHER TABS ARE INTEGRATED.
  // THIS IS SET TO 1 as THE DOWN SYSTEM TAB IS at INDEX 1
  const isDownSystemTabActive = tabValue === 1
  if (isDownSystemTabActive && timeDown) {
    activeFilters.push({
      id: 'time-down',
      label: `Time Down: ${timeDown === '>180' ? '\u2265180' : timeDown} days`,
      property: 'timeDown',
    })
  }

  return activeFilters
}

const processSelectedSnoozeStatusFilter = (snoozeStatus: string[]) => {
  const activeFilters: IActiveFilter[] = []
  snoozeStatusOptions.forEach(status => {
    if (snoozeStatus.includes(status.id)) {
      activeFilters.push({
        id: `snoozeStatus-${status.id}`,
        label: `Status: ${status.name}`,
        property: 'snoozeStatus',
      })
    }
  })
  return activeFilters
}

const processSelectedAlarmSeverityFilter = (
  severityFilter: string[],
  tabValue: number
) => {
  const activeFilters: IActiveFilter[] = []
  // ENSURE TO MAP THE CORRECT TAB VALUE FOR THE ALARMS WHEN OTHER TABS ARE INTEGRATED.
  // THIS IS SET TO 0 as THE ALARMS TAB IS at INDEX 0
  const isAlarmsTabActive = tabValue === 0
  alarmSeverityOptions.forEach(severity => {
    if (isAlarmsTabActive && severityFilter.includes(severity.id)) {
      activeFilters.push({
        id: `alarmSeverity-${severity.id}`,
        label: `Severity: ${severity.name}`,
        property: 'alarmSeverity',
      })
    }
  })
  return activeFilters
}

const processSelectedAlarmTypeFilter = (
  alarmTypeFilter: string[],
  tabValue: number,
  alarmTypeOptions: ISonarFilterOption[]
) => {
  if (!alarmTypeOptions?.length) return
  const activeFilters: IActiveFilter[] = []
  // ENSURE TO MAP THE CORRECT TAB VALUE FOR THE ALARMS WHEN OTHER TABS ARE INTEGRATED.
  // THIS IS SET TO 0 as THE ALARMS TAB IS at INDEX 0
  const isAlarmsTabActive = tabValue === 0

  alarmTypeOptions.forEach(type => {
    if (isAlarmsTabActive && alarmTypeFilter.includes(type.id.toString())) {
      activeFilters.push({
        id: `alarmType-${type.id}`,
        label: `Type: ${type.name}`,
        property: 'alarmType',
      })
    }
  })
  return activeFilters
}

const getSonarCustomerFilterOptions = async (
  constraints: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setIsLoading(true)
    const endpoint = `/Hardware/Sonar/Overview/FilterData`
    const response = await dataHelpers.fetchDataHelper(endpoint, constraints)

    if (response.ok && response.status === 200) {
      const data = await response.json()
      const digestedOptions = digestCustomerFilterOptions(data)
      setIsLoading(false)
      return digestedOptions
    } else {
      console.error(response.statusText)
      setIsLoading(false)
      return defaultCustomerFilterOptions
    }
  } catch (error) {
    console.error(error)
    setIsLoading(false)
    return defaultCustomerFilterOptions
  }
}

const getSonarAlarmTypeFilterOptions = async (
  constraints: any,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setIsLoading(true)
    const endpoint = `/Hardware/Sonar/Alarms/FilterData`
    const response = await dataHelpers.fetchDataHelper(endpoint, constraints)

    if (response.ok && response.status === 200) {
      const data = await response.json()
      const digestedOptions = digestAlarmTypeFilterOptions(data)
      return digestedOptions
    } else {
      console.error(response.statusText)
      return []
    }
  } catch (error) {
    console.error(error)
    return []
  } finally {
    setIsLoading(false)
  }
}

const getTimeDownDateRanges = (timeDown: string[], date: Date) => {
  const oldestDate = new Date('2000-01-01')
  let startDate, endDate
  // Start date is inclusive, end date is not inclusive. start date @ midnight <= x < end date @ midnight
  switch (timeDown[0]) {
    case '<10':
      // The end date is midnight tomorrow, therefore today counts as 1 day.
      // subtract 9 because anything on the 10th day is counted as 10 days.
      // eg if today is oct18 UTC, oct9th UTC is the 10th day. any system last seen on oct9th UTC will be considered 10 days ago and fall into 10-30 days
      endDate = date
      startDate = subDays(endDate, 9)
      break
    case '10-30':
      endDate = subDays(date, 9)
      startDate = subDays(date, 29) // not 30 because we don't want 30 included in this range.
      break
    case '30-180':
      endDate = subDays(date, 29)
      startDate = subDays(date, 179)
      break
    case '>180':
      endDate = subDays(date, 179)
      startDate = oldestDate
      break
    default:
      endDate = date
      startDate = date
  }

  return {
    startDate: formatDateObjectToString(startDate, 'YYYY-MM-DD', 'true'),
    endDate: formatDateObjectToString(endDate, 'YYYY-MM-DD', 'true'),
  }
}

// The original map and sort has been refactored so that it is explicit
// on which sort function to use (either alphabetically or numerically).
const mapAndSortAlphabetical = (
  data: any[],
  mapFn: (item: any) => { id: any; name: string }
) => {
  return (data || [])
    .filter(Boolean) // Remove null or undefined
    .map(mapFn)
    .sort((a, b) => a.name.localeCompare(b.name))
}

const mapAndSortNumerical = (
  data: any[],
  mapFn: (item: any) => { id: any; name: string }
) => {
  return (data || [])
    .filter(Boolean) // Remove null or undefined
    .map(mapFn)
    .sort((a, b) => Number(a.name) - Number(b.name))
}

const digestCustomerFilterOptions = (data: ICustomerFilterData) => {
  const digestedOptions: ICustomerFilterOptions = {
    divisionId: mapAndSortAlphabetical(
      data.producers,
      (producer: ICustomerFilterProducer) => ({
        id: producer.divisionId,
        name: producer.name,
      })
    ),
    plantId: mapAndSortAlphabetical(
      data.plants,
      (plant: ICustomerFilterPlant) => ({
        id: plant.plantId,
        name: plant.name,
      })
    ),
    systemId: mapAndSortNumerical(
      data.systems,
      (system: ICustomerFilterSystem) => {
        const id =
          system.systemId || system.remoteUnitId || system.reclaimedWaterUnitId
        return {
          id,
          name: id.toString(),
        }
      }
    ), // Numeric sorting for systemIds
    region: mapAndSortAlphabetical(data.regions, (region: string) => ({
      id: region,
      name: region,
    })),
    country: mapAndSortAlphabetical(data.countries, (country: string) => ({
      id: country,
      name: country,
    })),
    state: mapAndSortAlphabetical(data.states, (state: string) => ({
      id: state,
      name: state,
    })),
    city: mapAndSortAlphabetical(data.cities, (city: string) => ({
      id: city,
      name: city,
    })),
  }
  return digestedOptions
}

const digestAlarmTypeFilterOptions = (data: ISonarAlarmType[]) => {
  const digestedOptions: ISonarFilterOption[] = mapAndSortAlphabetical(
    data,
    (alarmType: ISonarAlarmType) => ({
      id: alarmType.alarmCode.toString(),
      name: alarmType.description,
    })
  )
  return digestedOptions
}

const encodeQueryParam = (key: string, value: string | number): string =>
  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`

const flattenFilterValues = (sonarFilter: ISonarFilter): string[] =>
  Object.keys(sonarFilter).flatMap(
    key =>
      sonarFilter[key]?.map((value: string | number) =>
        encodeQueryParam(key, value)
      ) || []
  )

const generateQueryParams = (sonarFilter: ISonarFilter): string => {
  const queryParams = flattenFilterValues(sonarFilter).filter(Boolean)
  return queryParams.join('&')
}

const filterQueryParams = (
  queryParams: string,
  allowedKeys: string[]
): string => {
  const params = new URLSearchParams(queryParams)
  const filteredParams = new URLSearchParams()

  // @ts-ignore
  for (const [key, value] of params.entries()) {
    if (allowedKeys.includes(key)) {
      filteredParams.append(key, value)
    }
  }

  return filteredParams.toString()
}

const timezoneChangeHandlerHelper = (frontendFilters: ISonarFrontendFilter) => {
  const tempSonarFrontendFilter = { ...frontendFilters }
  const currentIsUTCValue = frontendFilters.isUTC?.[0]
  if (currentIsUTCValue === 'true') {
    tempSonarFrontendFilter.isUTC = ['false']
  } else {
    tempSonarFrontendFilter.isUTC = ['true']
  }
  return tempSonarFrontendFilter
}

const SonarViewHelper = {
  digestCustomerFilterOptions,
  digestAlarmTypeFilterOptions,
  filterQueryParams,
  generateQueryParams,
  getSonarCustomerFilterOptions,
  getSonarAlarmTypeFilterOptions,
  getTimeDownDateRanges,
  mapAndSortAlphabetical,
  mapAndSortNumerical,
  processSelectedTechnologyFilter,
  processSelectedCustomerFilter,
  processSelectedDateRangeFilter,
  processSelectedTimeDownFilter,
  processSelectedSnoozeStatusFilter,
  processSelectedAlarmSeverityFilter,
  processSelectedAlarmTypeFilter,
  timezoneChangeHandlerHelper,
}
export default SonarViewHelper
