import { FlyToInterpolator } from 'react-map-gl'
import { echoColors } from '../../theme/colors'
import { MaterialSubTypeOptions } from '../../TSS/Logic/Types'

/** Colours used for when a "Filter by" filter is selected */
export const uniqueColors = [
  { rgb: [241, 119, 0, 150], hex: '#F17700' },
  { rgb: [255, 189, 51, 150], hex: '#FFBD33' },
  { rgb: [38, 190, 157, 150], hex: '#26BE9D' },
  { rgb: [231, 64, 50, 150], hex: '#E74032' },
  { rgb: [0, 78, 119, 150], hex: '#004E77' },
  { rgb: [8, 25, 33, 150], hex: '#081921' },
  { rgb: [243, 151, 73, 150], hex: '#F39749' },
  { rgb: [255, 206, 99, 150], hex: '#FFCE63' },
  { rgb: [238, 119, 107, 150], hex: '#EE776B' },
  { rgb: [107, 208, 181, 150], hex: '#6BD0B5' },
  { rgb: [66, 122, 152, 150], hex: '#427A98' },
  { rgb: [71, 83, 89, 150], hex: '#475359' },
  { rgb: [245, 190, 143, 150], hex: '#F5BE8F' },
  { rgb: [255, 222, 153, 150], hex: '#FFDE99' },
  { rgb: [246, 164, 154, 150], hex: '#F6A49A' },
  { rgb: [160, 224, 206, 150], hex: '#A0E0CE' },
]

/** Default colours used for the concrete map markers when a "Filter by" filter is NOT selected */
export const ownershipColors = {
  customers: echoColors.customers,
  privatelyOwned: echoColors.privatelyOwned,
  verticallyIntegrated: echoColors.verticallyIntegrated,
}

export const opportunitiesColors = {
  opportunities: echoColors.opportunities,
}

export const radiuses = {
  selected: 14,
  notSelected: 7,
}

export const co2MarkerSizes = {
  selected: 60,
  noSelected: 35,
}

export const projectColors = {
  specified: { rgb: [26, 67, 115], hex: '#1A4373' },
  unspecified: { rgb: [137, 137, 137], hex: '#898989' },
  ongoing: { rgb: [255, 193, 68], hex: '#FFC144' },
  finished: { rgb: [59, 141, 114], hex: '#3B8D72' },
}

export const translatedCementTypes = {
  CementBlendedHydraulic: 'Blended Hydraulic',
  CementTypeI: 'Type I',
  CementTypeIA: 'Type IA',
  CementTypeII: 'Type II',
  CementTypeIIA: 'Type IIA',
  CementTypeIIMH: 'Type II MH',
  CementTypeIIMHA: 'Type II MHA',
  CementTypeIII: 'Type III',
  CementTypeIIIA: 'Type IIIA',
  CementTypeIL: 'Type IL',
  CementTypeIV: 'Type IV',
  CementTypeV: 'Type V',
  CementTypeI_II: 'Type I/II',
  CementTypeI_II_LA: 'Type I/II/LA',
  CementTypeII_V: 'Type II/V',
  CementTypeGU: 'Type GU',
  CementTypeGUL: 'Type GUL',
  CementTypeN: 'Type N',
  CementTypeS: 'Type S',
  CementTypeIS: 'Type IS',
  CementTypeIP: 'Type IP',
  Cement: 'Cement',
}

export const cementLegendTooltips = {
  high: 'Anticipate a good performer',
  medium: 'Anticipate moderate performance',
  low: 'Performance unknown',
  notTested: 'Not yet tested in the lab',
}

/** Mapbox API access token (can be generated in your Mapbox account: https://www.mapbox.com/ ) saved as a CI/CD env variable in GitLab */
export const mapboxApiAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

/** CSV table headers for upcoming projects */
export const upcomingProjectHeaders = [
  { label: 'project_id', key: 'project_id' },
  { label: 'organization', key: 'organization' },
  { label: 'description', key: 'description' },
  { label: 'location', key: 'location' },
  { label: 'country', key: 'country' },
  { label: 'start_date', key: 'start_date' },
  { label: 'size_m2', key: 'size_m2' },
  { label: 'monetaryValue', key: 'monetaryValue' },
  { label: 'stage', key: 'stage' },
  { label: 'closest_plants', key: 'closest_plants' },
]

/** CSV table headers for CarbonCure projects */
export const carbonCureProjectHeaders = [
  { label: 'divisionID', key: 'divisionID' },
  { label: 'projectName', key: 'projectName' },
  { label: 'location', key: 'city' },
  { label: 'customer', key: 'customer' },
  { label: 'Concrete Delivered (yd3)', key: 'concreteDelivered' },
  { label: 'Est. CO2 Savings (tons)', key: 'co2Savings' },
  { label: 'projectStart', key: 'startDate' },
  { label: 'projectStatus', key: 'projectStatus' },
]

/** CSV table headers for corpData projects */
export const corpDataHeaders = [
  { label: 'group_name', key: 'group_name' },
  { label: 'company_name', key: 'company_name' },
  { label: 'plant_city', key: 'plant_city' },
  { label: 'plant_state', key: 'plant_state' },
  { label: 'sales', key: 'sales' },
  { label: 'type', key: 'type' },
]

/** CSV table headers for CO2 vendors */
export const co2VendorHeaders = [
  { label: 'id', key: 'id' },
  { label: 'companyName', key: 'companyName' },
  { label: 'state', key: 'fullStateName' },
  { label: 'depotCount', key: 'depotCount' },
  { label: 'avgCO2PriceUsdPerKg', key: 'avgCO2PriceUsdPerKg' },
  { label: 'tankLeadTimeWeeks', key: 'tankLeadTimeWeeks' },
  { label: 'customerServiceRating', key: 'customerServiceRating' },
  { label: 'contact', key: 'contact' },
]

/** Tab labels and order for the tables */
export const tabHeaders = {
  'Ready Mix': { label: 'Ready Mix', order: 0 },
  Precast: { label: 'Precast', order: 1 },
  'Cement Plants': { label: 'Cements', order: 2 },
  'Upcoming Projects': { label: 'Upcoming Projects', order: 3 },
  'Ongoing Projects': { label: 'Ongoing Projects', order: 4 },
  'Finished Projects': { label: 'Finished Projects', order: 5 },
  'CO₂ Depots': { label: 'CO₂ Depots', order: 6 },
}

/** Abbreviations for the domestic sales regions, used for geocoding */
export const domesticSalesRegions = 'ca,us,mx,au,nz'

/** Default Echo settings to reset the Echo settings atom */
export const defaultEchoSettings = {
  salesPeople: [],
  layers: [],
  projects: [],
  countries: [],
  states: [],
  cities: [],
  groups: [],
  companies: [],
  zipCodes: [],
  addresses: [],
  ownership: [],
  isGreenfield: false,
  isLoading: false,
  co2ConnectedToCustomers: false,
  plantsLimits: [],
  startDate: '',
  endDate: '',
  keywords: '',
  markerLocation: [],
  cementTypes: [],
  labResults: [],
  cementSuppliers: [],
  rmProducerRange: [],
}

/** Default map viewport settings */
export const defaultMapViewport = {
  latitude: 10,
  longitude: -150,
  zoom: 1,
  transitionDuration: 2000,
  transitionInterpolator: new FlyToInterpolator(),
}

/** Default project search values */
export const defaultProjectSearchValues = {
  projects: [], // 'Upcoming Projects', 'Ongoing Projects', 'Finished Projects'
  countries: [],
  states: [],
  cities: [],
  groups: [],
  companies: [],
  zipCodes: [],
  startDate: '',
  endDate: '',
  keywords: '',
}

/** SCM Type Labels */
export const translatedScmTypes = {
  ...MaterialSubTypeOptions,
  Filler: 'Filler',
}
