import { formatISO, startOfDay } from 'date-fns'
import dataHelpers from '../../../../Common/Helpers/DataHelpers'
import { TSonarTechnologyType } from '../../../../Common/Logic/Types'
import { DateRange, SonarDateRange } from '../../../../TSS/Logic/Types'
import { ISnoozeAlarmReqBody, SnoozeAlarmReason } from '../../../Logic/Types'
import { isDateWithinRange } from '../../Helpers/SonarHelpers'

async function postAlarmSnooze(
  alarmId: number,
  technologyType: TSonarTechnologyType,
  body: ISnoozeAlarmReqBody
) {
  let endpoint = `/Hardware/Sonar/SnoozeAlarm/${alarmId}?`
  let systemType: string = 'Mixer'
  if (technologyType === 'ReclaimedWater') systemType = 'ReclaimedWater'
  endpoint += `systemType=${systemType}`

  return dataHelpers.postDataHelper(endpoint, body)
}

async function putAlarmSnooze(alarmId: number, body: ISnoozeAlarmReqBody) {
  const endpoint = `/Hardware/Sonar/SnoozeAlarm/${alarmId}`
  return dataHelpers.putDataHelper(endpoint, body)
}

const saveNewSnooze = async (
  alarmId: number,
  technologyType: TSonarTechnologyType,
  body: ISnoozeAlarmReqBody
) => {
  const response = await postAlarmSnooze(alarmId, technologyType, body)
  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.error)
  }
}

const saveSnooze = async (
  alarmId: number,
  reason: SnoozeAlarmReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange,
  technologyType: TSonarTechnologyType
) => {
  const body = {
    reasonDetail: reasonDetails,
    snoozeUntilTimestamp: new Date(snoozeEnd as string).toISOString(),
    alarmReason: reason,
  }
  await saveNewSnooze(alarmId, technologyType, body as ISnoozeAlarmReqBody)
}

const saveEditedSnooze = async (
  snoozeId: number,
  body: ISnoozeAlarmReqBody
) => {
  const response = await putAlarmSnooze(snoozeId, body)
  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.error)
  }
}

const editSnooze = async (
  snoozeId: number,
  reason: SnoozeAlarmReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange
) => {
  const body = {
    reasonDetail: reasonDetails,
    snoozeUntilTimestamp: new Date(snoozeEnd as string).toISOString(),
    alarmReason: reason,
  }
  await saveEditedSnooze(snoozeId, body as ISnoozeAlarmReqBody)
}

const isConfirmDisabled = (
  alarmId: number | null | undefined,
  technology: TSonarTechnologyType,
  reason: SnoozeAlarmReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange,
  rangeSelected: SonarDateRange | null
) => {
  return (
    (!alarmId && !technology) ||
    !reason ||
    (reason === SnoozeAlarmReason.Other && !reasonDetails) ||
    !snoozeEnd ||
    !rangeSelected
  )
}

const isEditConfirmDisabled = (
  snoozeId: number | null | undefined,
  reason: SnoozeAlarmReason | null,
  reasonDetails: string,
  snoozeEnd: DateRange,
  snoozeEditDate: string | null,
  snoozeMin: string,
  snoozeMax: string
): boolean => {
  return (
    !snoozeId ||
    !reason ||
    (reason === SnoozeAlarmReason.Other && !reasonDetails) ||
    !snoozeEnd ||
    !snoozeEditDate ||
    !isDateWithinRange(snoozeEditDate, snoozeMin, snoozeMax)
  )
}

const getLastUpdatedDate = (
  lastUpdatedDate: string | null | undefined
): string | undefined => {
  if (!lastUpdatedDate) return
  const lastUpdated = formatISO(
    startOfDay(new Date(lastUpdatedDate))
  ).substring(0, 10)
  return lastUpdated
}

const getSnoozeReason = (
  reason: string | null | undefined,
  reasonDetails: string | null | undefined
) => {
  switch (reason) {
    case SnoozeAlarmReason.CarbonCureMaintenance:
      return 'Maintenance (CarbonCure)'
    case SnoozeAlarmReason.ProducerMaintenance:
      return 'Maintenance (Producer)'
    case SnoozeAlarmReason.PartInShipment:
      return 'Part in Shipment'
    case SnoozeAlarmReason.Other:
      return reasonDetails
    default:
      return null
  }
}

const snoozeAlarmModalHelper = {
  editSnooze,
  getLastUpdatedDate,
  getSnoozeReason,
  isConfirmDisabled,
  isEditConfirmDisabled,
  saveEditedSnooze,
  saveSnooze,
}

export default snoozeAlarmModalHelper
