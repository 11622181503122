import React from 'react'
import { makeStyles, Grid, Chip, Button } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { IActiveFilter } from '../../Logic/Types'
import theme from '../../../theme/muiTheme'
import { Cancel } from '@material-ui/icons'
export interface IFilterPanelChipsProps {
  activeFilters: Array<IActiveFilter>
  chipClickHandler: (arg0: IActiveFilter) => void
  buttonClickHandler: () => void
  isDeleteDisabled?: boolean
  buttonText?: string
}

const useStyles = makeStyles(() => ({
  ...theme.customClasses.filterPanel,
}))

const FilterPanelChips = ({
  activeFilters,
  chipClickHandler,
  buttonClickHandler,
  isDeleteDisabled,
  buttonText = 'Clear All Filters',
}: IFilterPanelChipsProps) => {
  const classes: ClassNameMap<string> = useStyles()

  return (
    <Grid container spacing={1} wrap="wrap" className={classes.chipsContainer}>
      {activeFilters.map((filter, index) => (
        <Grid item key={`${index}-${filter.property}`}>
          <Chip
            label={filter.label}
            className={classes.activeChip}
            onDelete={() => {
              chipClickHandler(filter)
            }}
            data-testid={`${filter.label}-filterChip`}
            deleteIcon={
              isDeleteDisabled ? (
                <></>
              ) : (
                <Cancel data-testid={`cancel-${filter.label}`} />
              )
            }
          />
        </Grid>
      ))}
      {activeFilters.length > 0 && !isDeleteDisabled && (
        <Button
          variant="outlined"
          color="default"
          size="small"
          onClick={buttonClickHandler}
          className={classes.clearButton}
          data-testid="clear-filter-button"
        >
          {buttonText}
        </Button>
      )}
    </Grid>
  )
}

export default FilterPanelChips
