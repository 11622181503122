import React from 'react'
import PropTypes from 'prop-types'
import hook, { IUseQuicksightDashboardProps } from './useQuicksightDashboard'

export interface IQuicksightDashboardProps
  extends IUseQuicksightDashboardProps {}

const QuicksightDashboard = ({
  dashboard,
  height,
  width,
  printInvoked,
  setPrintInvoked,
}: IQuicksightDashboardProps) => {
  const { containerRef } = hook.useQuicksightDashboard({
    dashboard,
    height,
    width,
    printInvoked,
    setPrintInvoked,
  })

  return <div ref={containerRef} style={{ height, width }} />
}

export default QuicksightDashboard

QuicksightDashboard.propTypes = {
  dashboard: PropTypes.object.isRequired,
  height: PropTypes.string,
  printInvoked: PropTypes.bool,
  width: PropTypes.string,
}

QuicksightDashboard.defaultProps = {
  height: '1500px',
  width: '100%',
}
