import React, { useState } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import ProductionDashboards from './ProductionDashboards'
import { Helmet } from 'react-helmet'
import { dashboardNameList } from '../Constants/UrlMapping'
import { convertCamelCaseToPascalCase } from '../../Common/Helpers/GeneralHelpers'

function QuickSightHome() {
  const [currentDashboard, setCurrentDashboard] = useState(dashboardNameList[3])
  let { path } = useRouteMatch()
  const pageTitle = 'QuickSight'

  return (
    <Grid container>
      <Switch>
        {dashboardNameList.map(dashboard => {
          return (
            <Route
              key={dashboard.id}
              exact
              path={`${path}/${convertCamelCaseToPascalCase(dashboard)}`}
            >
              <Helmet>
                <title>{pageTitle}</title>
              </Helmet>
              <ProductionDashboards
                currentDashboard={dashboard}
                setCurrentDashboard={setCurrentDashboard}
              />
            </Route>
          )
        })}
        <Redirect
          to={`/${'QuickSight'}/${convertCamelCaseToPascalCase(
            currentDashboard
          )}`}
        />
      </Switch>
    </Grid>
  )
}

export default QuickSightHome
