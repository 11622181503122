import { FilterOption } from '../../Common/Logic/Types'

export enum SnoozeDownSystemReason {
  Seasonal = 'Seasonal',
  ProducerMaintenance = 'MaintenanceProducer',
  CarbonCureMaintenance = 'MaintenanceCarbonCure',
  CO2TankMaintenance = 'MaintenanceCO2Tank',
  Churned = 'Churned',
  Other = 'Other',
}

export enum SnoozeDownSystemReasonString {
  Seasonal = 'Seasonal',
  MaintenanceProducer = 'Maintenance Producer',
  MaintenanceCarbonCure = 'Maintenance CarbonCure',
  MaintenanceCO2Tank = 'Maintenance CO\u2082 Tank',
  Churned = 'Churned',
  Other = 'Other',
}

export interface ISnoozeDownSystemReqBody {
  systemReason: SnoozeDownSystemReason
  reasonDetail: string
  snoozeUntilTimestamp: string
}

export interface IKelownaSnooze {
  snoozeId: number
  remoteUnitId: number | null
  reclaimedWaterUnitId: number | null
  creationTimestamp: string
  createdByUserId: number
  createdByUserFirstAndLastName: string
  startTimestamp: string
  endTimestamp: string
  snoozeReasonDetails: string
  resolvedTimestamp: string
  lastUpdatedTimestamp: string
  lastUpdatedByUserId: number
  lastUpdatedByUserFirstAndLastName: string
  isResolved: boolean
  alarmCode: number
  isAlarmSnooze: boolean
  alarmSnoozeReason: string
  systemSnoozeReason: SnoozeDownSystemReason
}

export interface IDownSystemSnooze {
  reason: SnoozeDownSystemReason
  reasonDetails: string
  snoozeUntil: string
}

export enum SnoozeAlarmReason {
  ProducerMaintenance = 'MaintenanceProducer',
  CarbonCureMaintenance = 'MaintenanceCarbonCure',
  PartInShipment = 'PartInShipment',
  Other = 'Other',
}

export interface ISnoozeAlarmReqBody {
  alarmReason: SnoozeAlarmReason
  reasonDetail: string
  snoozeUntilTimestamp: string
}
export interface SonarHelperVariables {
  isChipActive: (option: FilterOption) => boolean
}
