import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  ICommissionReportSettings,
  IMixSelection,
  ITestResultsPropertyOption,
  NumericIntervals,
  testResultPropertiesKelownaId,
  VariationTypes,
} from '../../Logic/Types'
import cloneDeep from 'lodash.clonedeep'
import {
  getAllSelectedTestResultsProperties,
  getSelectedTestResultsInterval,
  validateTestResultsSelection,
} from '../../Helpers/CommissionReportHelpers'
import { findVariationByType, toggleItemInArray } from '../../Logic/TSSLogic'
import {
  useDefaultSelectedTestResultsProperties,
  useTestResultsPropertyOptions,
} from '../../Logic/Hooks'
import TestResultsTablePresentational from './TestResultsTablePresentational'
import PdfCommissionReportTestResultsTable from './PdfCommissionReportTestResultsTable'

export interface ITestResultsTableLogicalProps {
  mixSelection: IMixSelection
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  reportSettings: ICommissionReportSettings
  tabValue: number
  isCommissionReportViewMode: boolean
  isPrinting: boolean
  setHasUserInput: Dispatch<SetStateAction<boolean>>
}

const TestResultsTableLogical = (props: ITestResultsTableLogicalProps) => {
  const {
    reportSettings,
    mixSelection,
    setReportSettings,
    tabValue,
    isCommissionReportViewMode,
    isPrinting,
    setHasUserInput,
  } = props

  const [allSelectedProperties, setAllSelectedProperties] = useState<string[]>(
    getAllSelectedTestResultsProperties(reportSettings, tabValue)
  )

  const showFrequencyGraph =
    reportSettings.mixDesignSettings[tabValue]?.frequencyGraph?.isSelected
  const showAvgStrengthGraph =
    reportSettings.mixDesignSettings[tabValue]?.avgStrengthGraph?.isSelected
  const showDiff =
    reportSettings?.mixDesignSettings[tabValue]?.testResults?.showDifference

  const baselineVariation = findVariationByType(
    mixSelection.digestedVariations,
    VariationTypes.BASELINE
  )
  const carbonCureVariation = findVariationByType(
    mixSelection.digestedVariations,
    VariationTypes.OPTIMIZED
  )
  const isDifferentCO2DosageUnit =
    baselineVariation?.cO2DosageUnit !== carbonCureVariation?.cO2DosageUnit
  const isCO2DosagePercentage =
    baselineVariation?.cO2DosageUnit === 'PercentOfCement' &&
    carbonCureVariation?.cO2DosageUnit === 'PercentOfCement'

  // memoize the selected test results interval to avoid recalculating on every render
  const selectedTestResultsInterval = useMemo(
    () =>
      getSelectedTestResultsInterval(reportSettings, mixSelection, tabValue),
    [reportSettings, mixSelection, tabValue]
  )

  // update allSelectedProperties when testResultsSettings changes
  // allSelectedProperties is a combination of selectedProperties, selectedStrengthHours and selectedStrengthStDevHours
  useEffect(() => {
    const allSelectedProperties: string[] = getAllSelectedTestResultsProperties(
      reportSettings,
      tabValue
    )
    setAllSelectedProperties(allSelectedProperties)
  }, [reportSettings, tabValue])

  validateTestResultsSelection(tabValue, reportSettings)

  /** Custom hook to set default selected test results properties if there are no selected properties */
  useDefaultSelectedTestResultsProperties(
    allSelectedProperties.length,
    selectedTestResultsInterval,
    tabValue,
    setReportSettings
  )

  /** Custom hook to update the test results property options based on the graph settings, interval options and co2DosageUnit */
  useTestResultsPropertyOptions(
    mixSelection,
    reportSettings,
    tabValue,
    isCO2DosagePercentage
  )

  const handleCheckboxClick = () => {
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings) as ICommissionReportSettings
      const mixDesignSettings = newSettings.mixDesignSettings[tabValue]
      const updatedTestResultsSettings = {
        ...mixDesignSettings.testResults,
        showDifference: !mixDesignSettings.testResults.showDifference,
      }
      mixDesignSettings.testResults = updatedTestResultsSettings
      return newSettings
    })
    setHasUserInput(true)
  }

  const handleChipClick = (option: ITestResultsPropertyOption) => {
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings) as ICommissionReportSettings
      const mixDesignSettings = newSettings.mixDesignSettings[tabValue]
      let updatedSettings

      switch (option.type) {
        case 'strength':
          updatedSettings = {
            ...mixDesignSettings.testResults,
            selectedStrengthHours: toggleItemInArray(
              option.kelownaId,
              mixDesignSettings.testResults.selectedStrengthHours
            ) as NumericIntervals[],
          }
          break
        case 'standardDeviation':
          updatedSettings = {
            ...mixDesignSettings.testResults,
            selectedStrengthStDevHours: toggleItemInArray(
              option.kelownaId,
              mixDesignSettings.testResults.selectedStrengthStDevHours
            ) as NumericIntervals[],
          }
          break
        default:
          updatedSettings = {
            ...mixDesignSettings.testResults,
            selectedProperties: toggleItemInArray(
              option.kelownaId,
              mixDesignSettings.testResults.selectedProperties
            ) as testResultPropertiesKelownaId[],
          }
      }
      mixDesignSettings.testResults = updatedSettings
      return newSettings
    })
    setHasUserInput(true)
  }

  const isChipActive = (option: ITestResultsPropertyOption) => {
    return option.type === 'strength' || option.type === 'standardDeviation'
      ? allSelectedProperties.includes(option.id)
      : allSelectedProperties.includes(option.kelownaId)
  }

  const getOptionDisabled = (option: ITestResultsPropertyOption) => {
    const isSelected =
      allSelectedProperties.includes(option.kelownaId) ||
      allSelectedProperties.includes(option.id)

    return allSelectedProperties.length === 6 && !isSelected
  }

  const testResultsTableRows = mixSelection?.testResultsPropertyOptions?.filter(
    option =>
      allSelectedProperties.includes(option.kelownaId) ||
      allSelectedProperties.includes(option.id)
  )

  const horizontalView = showAvgStrengthGraph && showFrequencyGraph

  return (
    <>
      {!isPrinting && (
        <TestResultsTablePresentational
          rows={testResultsTableRows}
          testResultsPropertyOptions={
            mixSelection?.testResultsPropertyOptions || []
          }
          baselineVariation={baselineVariation}
          carbonCureVariation={carbonCureVariation}
          showDiff={showDiff}
          isDifferentCO2DosageUnit={isDifferentCO2DosageUnit}
          isMetric={reportSettings.isMetric}
          handleChipClick={handleChipClick}
          isChipActive={isChipActive}
          getOptionDisabled={getOptionDisabled}
          handleCheckboxClick={handleCheckboxClick}
          horizontalView={horizontalView}
          isCommissionReportViewMode={isCommissionReportViewMode}
          specifiedMaturityAge={mixSelection.specifiedMaturityAge}
          mixDesignSettings={reportSettings.mixDesignSettings}
        />
      )}
      {isPrinting && (
        <PdfCommissionReportTestResultsTable
          testResultsTableRows={testResultsTableRows}
          isDifferentCO2DosageUnit={isDifferentCO2DosageUnit}
          baselineVariation={baselineVariation}
          carbonCureVariation={carbonCureVariation}
          showDiff={showDiff}
          horizontalView={horizontalView}
          isMetric={reportSettings.isMetric}
          specifiedMaturityAge={mixSelection.specifiedMaturityAge}
          mixDesignSettings={reportSettings.mixDesignSettings}
        />
      )}
    </>
  )
}

export default TestResultsTableLogical
