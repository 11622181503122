import {
  Backdrop,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import DialogModal from '../../../../Common/Components/DialogModal/DialogModal'
import {
  DialogModalSize,
  DialogModalType,
  SonarFilterType,
} from '../../../../TSS/Logic/Types'
import { SnoozeAlarmReason } from '../../../Logic/Types'
import SonarSnoozeReasonDetails from '../SonarSnoozeReasonDetails'
import {
  ISonarAlarm,
  TSonarTechnologyType,
} from '../../../../Common/Logic/Types'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import hook from './UseSnoozeAlarmModal'
import SonarDateRangeSelect from '../SonarDateRangeSelect'
import { addDays, formatISO, startOfDay } from 'date-fns'
import snoozeDownSystemsModalHelper from '../SnoozeDownSystemsModal/snoozeDownSystemsModalHelper'
import snoozeAlarmModalHelper from './SnoozeAlarmModalHelper'
import { baseColors } from '../../../../theme/colors'

const useStyles = makeStyles(theme => ({
  textField: {
    margin: '2px 0px',
  },
  card: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  cardContent: {
    padding: '8px 16px !important',
  },
  fullWidth: {
    width: '100%',
  },
  upperBackdrop: {
    zIndex: Math.max(...Object.values(theme.zIndex)) + 1,
  },
  // @ts-ignore ts won't acknowledge customClasses being added to theme
  ...theme.customClasses?.warningModal,
}))

const useStyles2 = makeStyles(theme => ({
  // @ts-ignore
  ...theme.customClasses?.successModal,
}))

const SNOOZE_TEXT = (
  <Typography variant="body2">
    You are about to snooze the following alarm. Please provide the reason and
    date you wish to unsnooze or reengage the alarm to save and continue.
  </Typography>
)

const EDIT_SNOOZE_TEXT = (
  <Typography variant="body2">
    Edit the following snoozed alarm. Please provide expected reason to save and
    continue.
  </Typography>
)

export interface ISnoozeAlarmModal {
  clickedAlarm: ISonarAlarm | null
  showSnoozeModal: boolean
  setShowSnoozeModal: Dispatch<SetStateAction<boolean>>
  setClickedAlarm: Dispatch<SetStateAction<ISonarAlarm | null>>
  setSnoozeSavedFlag: Dispatch<SetStateAction<boolean>>
  showSnoozeSuccessModal: boolean
  setShowSnoozeSuccessModal: Dispatch<SetStateAction<boolean>>
}

const SnoozeAlarmModal = (props: ISnoozeAlarmModal) => {
  const {
    clickedAlarm,
    showSnoozeModal,
    setShowSnoozeModal,
    setClickedAlarm,
    setSnoozeSavedFlag,
    showSnoozeSuccessModal,
    setShowSnoozeSuccessModal,
  } = props
  const classes: ClassNameMap = useStyles()
  const successClasses: ClassNameMap = useStyles2()
  const snoozeMin = formatISO(addDays(new Date(), 1)).substring(0, 10)
  const snoozeMax = formatISO(addDays(new Date(), 30)).substring(0, 10)
  const today = formatISO(startOfDay(new Date())).substring(0, 10)
  const {
    errorMessage,
    handleConfirmSnooze,
    handleDateChange,
    handleReasonChange,
    handleReasonDetailsChange,
    handleSnoozeModalClose,
    handleSnoozeSuccessModalClose,
    handleUnsnooze,
    isSaving,
    isSavingModal,
    JWT,
    lastUpdatedDate,
    rangeSelected,
    reason,
    reasonDetails,
    setRangeSelected,
    setSnoozeEnd,
    snoozeEditDate,
    snoozeEnd,
  } = hook.useSnoozeAlarmModal({
    clickedAlarm,
    setClickedAlarm,
    setShowSnoozeModal,
    setSnoozeSavedFlag,
    showSnoozeModal,
    setShowSnoozeSuccessModal,
  })

  const renderAlarmInfoCard = () => {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2">{clickedAlarm?.divisionName}</Typography>
          <Typography variant="body2">{clickedAlarm?.location}</Typography>
          <Typography variant="body2">{clickedAlarm?.systemId}</Typography>
          <Typography variant="body2">{clickedAlarm?.description}</Typography>
          {clickedAlarm?.snoozed && !showSnoozeSuccessModal && (
            <Typography variant="body2">
              Last Updated By: {clickedAlarm?.snoozeLastUpdatedByUserFullName}
            </Typography>
          )}
        </CardContent>
      </Card>
    )
  }

  const renderUnsnoozedAlarmInfoCard = () => {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="body2">{clickedAlarm?.divisionName}</Typography>
          <Typography variant="body2">{clickedAlarm?.location}</Typography>
          <Typography variant="body2">{clickedAlarm?.systemId}</Typography>
          <Typography variant="body2">
            Reason for Snoooze:{' '}
            {snoozeAlarmModalHelper.getSnoozeReason(
              clickedAlarm?.snoozeReason,
              clickedAlarm?.snoozeDetails
            )}
          </Typography>
          <Typography variant="body2">
            Date Snoozed: {lastUpdatedDate}
          </Typography>
          <Typography variant="body2">
            Unsnoozed By: {JWT.firstName} {JWT.lastName}
          </Typography>
          <Typography variant="body2">Date Unsnoozed: {today}</Typography>
        </CardContent>
      </Card>
    )
  }

  const renderModalBody = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <TextField
            select
            size="small"
            variant="outlined"
            label="Reason*"
            onChange={handleReasonChange}
            className={classes.textField}
            value={reason}
          >
            <MenuItem value={SnoozeAlarmReason.ProducerMaintenance}>
              Maintenance (Producer)
            </MenuItem>
            <MenuItem value={SnoozeAlarmReason.CarbonCureMaintenance}>
              Maintenance (CarbonCure)
            </MenuItem>
            <MenuItem value={SnoozeAlarmReason.PartInShipment}>
              Part in Shipment
            </MenuItem>
            <MenuItem value={SnoozeAlarmReason.Other}>Other</MenuItem>
          </TextField>
        </Grid>
        <Collapse
          in={reason === SnoozeAlarmReason.Other}
          timeout="auto"
          unmountOnExit
          className={classes.fullWidth}
        >
          <Grid item xs={12}>
            <SonarSnoozeReasonDetails
              reasonDetails={reasonDetails}
              handleReasonDetailsChange={handleReasonDetailsChange}
              parentClasses={classes}
            />
          </Grid>
        </Collapse>
        <Grid item xs={12}>
          {clickedAlarm?.snoozed ? (
            <TextField
              name="date"
              label="Snooze Until*"
              value={snoozeDownSystemsModalHelper.formatUTCISODate(
                snoozeEditDate as string
              )}
              size="small"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleDateChange}
              type="date"
              InputProps={{
                inputProps: { min: today, max: snoozeMax },
              }}
            />
          ) : (
            <SonarDateRangeSelect
              dateRangeStart={null}
              dateRangeEnd={snoozeEnd}
              rangeSelected={rangeSelected}
              setRangeSelected={setRangeSelected}
              showOnlyEndDate={true}
              filterType={SonarFilterType.AlarmSnoozedModal}
              parentClasses={classes}
              setEndDateSelected={setSnoozeEnd}
              endDateMin={snoozeMin}
              endDateMax={snoozeMax}
              disableCustomRangeEndDate={true}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ color: baseColors.error.main }}
            variant="caption"
          >
            {errorMessage}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const renderUnsnoozeAlarmSuccessModal = () => {
    return (
      <DialogModal
        modalOpen={showSnoozeSuccessModal}
        modalType={DialogModalType.success}
        headerText={'Successfully Unsnoozed Alarm'}
        contentText={`You've unsnoozed the following alarm:`}
        parentClasses={successClasses}
        modalSize={DialogModalSize.Small}
        hasCard={true}
        cardContent={renderUnsnoozedAlarmInfoCard()}
        handleClose={handleSnoozeSuccessModalClose}
      />
    )
  }
  const renderSnoozeAlarmModal = () => (
    <>
      {/* @ts-ignore */}
      <Backdrop open={isSavingModal} className={classes.upperBackdrop}>
        <CircularProgress color="primary" />
      </Backdrop>
      <DialogModal
        modalOpen={showSnoozeModal}
        modalType={DialogModalType.warning}
        headerText={
          clickedAlarm?.snoozed ? 'Edit Snoozed Alarm' : 'Snooze Alarm'
        }
        contentText={clickedAlarm?.snoozed ? EDIT_SNOOZE_TEXT : SNOOZE_TEXT}
        parentClasses={classes}
        modalSize={DialogModalSize.Small}
        handleCancel={handleSnoozeModalClose}
        handleConfirm={handleConfirmSnooze}
        cancelButtonText="Cancel"
        confirmButtonText={'Save'}
        hasAction={true}
        hasCard={true}
        cardContent={renderAlarmInfoCard()}
        hasBody={true}
        bodyContent={renderModalBody()}
        isLoading={isSaving}
        hasDeleteAction={clickedAlarm?.snoozed as boolean}
        deleteButtonText={clickedAlarm?.snoozed ? 'Unsnooze Alarm' : ''}
        handleDelete={handleUnsnooze}
        shouldDisable={
          clickedAlarm?.snoozed
            ? snoozeAlarmModalHelper.isEditConfirmDisabled(
                clickedAlarm?.snoozeId,
                reason,
                reasonDetails,
                snoozeEnd,
                snoozeEditDate,
                snoozeMin,
                snoozeMax
              )
            : snoozeAlarmModalHelper.isConfirmDisabled(
                clickedAlarm?.alarmId,
                clickedAlarm?.technology as TSonarTechnologyType,
                reason,
                reasonDetails,
                snoozeEnd,
                rangeSelected
              )
        }
      />
    </>
  )

  if (showSnoozeSuccessModal) {
    return renderUnsnoozeAlarmSuccessModal()
  }

  if (showSnoozeModal) {
    return renderSnoozeAlarmModal()
  }
  return null
}

export default SnoozeAlarmModal
