import { formatDateObjectToString } from '../../../Common/Helpers/GeneralHelpers'
import { ISonarFilter } from '../../../Common/Logic/Types'
import { DateRange, SonarDateRange } from '../../../TSS/Logic/Types'
import { addDays, formatISO, subDays } from 'date-fns'
import { getUTCDateBounds } from '../Helpers/SonarHelpers'

export interface HandleDateRangeResult {
  startDate: string | null
  endDate: string | null
  selectedRange: string | null
}

export interface AdjustedDateRange {
  lastSeenDateRange: string | null
  lastSeenStartDate: string | null
  lastSeenEndDate: string | null
}

const formatDate = (date: Date | null): string | null => {
  return date ? formatISO(date).substring(0, 10) : null
}

export const handleDateRange = (
  value: SonarDateRange | null,
  currentDateStart: DateRange,
  currentDateEnd: DateRange,
  currentDate: Date = new Date()
): HandleDateRangeResult => {
  const { currentDateUTC, cutoffDate } = getUTCDateBounds(currentDate)

  let startDate: Date | null = null
  let endDate: Date | null = null
  let selectedRange: string | null = value

  switch (value) {
    case SonarDateRange.Today:
      startDate = currentDateUTC
      endDate = cutoffDate
      break
    case SonarDateRange.Yesterday:
      startDate = subDays(currentDateUTC, 1)
      endDate = currentDateUTC
      break
    case SonarDateRange.Last7Days:
      startDate = subDays(cutoffDate, 7)
      endDate = cutoffDate
      break
    case SonarDateRange.Last30Days:
      startDate = subDays(cutoffDate, 30)
      endDate = cutoffDate
      break
    case SonarDateRange.Last90Days:
      startDate = subDays(cutoffDate, 90)
      endDate = cutoffDate
      break
    case SonarDateRange.Last180Days:
      startDate = subDays(cutoffDate, 180)
      endDate = cutoffDate
      break
    case SonarDateRange.OneDay:
      startDate = currentDateUTC
      endDate = addDays(currentDateUTC, 1)
      break
    case SonarDateRange.TwoDays:
      startDate = currentDateUTC
      endDate = addDays(currentDateUTC, 2)
      break
    case SonarDateRange.SevenDays:
      startDate = currentDateUTC
      endDate = addDays(currentDateUTC, 7)
      break
    case SonarDateRange.FourteenDays:
      startDate = currentDateUTC
      endDate = addDays(currentDateUTC, 14)
      break
    case SonarDateRange.ThirtyDays:
      startDate = currentDateUTC
      endDate = addDays(currentDateUTC, 30)
      break
    case SonarDateRange.NinetyDays:
      startDate = currentDateUTC
      endDate = addDays(currentDateUTC, 90)
      break
    case SonarDateRange.CustomRange:
      startDate = new Date(currentDate)
      endDate = new Date(currentDate)
      break
    default:
      selectedRange = null
  }

  return {
    startDate: formatDateObjectToString(startDate, 'YYYY-MM-DD', 'true'),
    endDate: formatDateObjectToString(endDate, 'YYYY-MM-DD', 'true'),
    selectedRange,
  }
}

/**
 * Recalculates date ranges based on the provided date range type
 * @param dateRangeType The type of date range (e.g., 'last7Days', 'today', etc.)
 * @returns Processed date range with recalculated start and end dates
 */
export const adjustDateRangeFromUrl = (
  dateRangeType: string | null
): AdjustedDateRange => {
  // If no date range type provided, return null values
  if (!dateRangeType) {
    return {
      lastSeenDateRange: null,
      lastSeenStartDate: null,
      lastSeenEndDate: null,
    }
  }

  const currentDate = new Date()

  // Calculate new start and end dates based on the date range type
  const { startDate, endDate } = handleDateRange(
    dateRangeType as SonarDateRange,
    null,
    null,
    currentDate
  )

  // Return the original date range type with newly calculated dates
  return {
    lastSeenDateRange: dateRangeType,
    lastSeenStartDate: startDate,
    lastSeenEndDate: endDate,
  }
}

/**
 * Updates the sonar filter with new date range values
 * @param currentFilter Current sonar filter state
 * @param adjustedDates Processed date range values
 * @returns Updated sonar filter
 */
export const updateSonarFilterWithDates = (
  currentFilter: ISonarFilter,
  adjustedDates: AdjustedDateRange
): ISonarFilter => {
  // If we have a date range type, we want to use it and its calculated dates
  if (adjustedDates.lastSeenDateRange) {
    return {
      ...currentFilter,
      // Keep the original date range type from URL
      lastSeenDateRange: [adjustedDates.lastSeenDateRange],
      // Use the newly calculated dates
      lastSeenStartDate: adjustedDates.lastSeenStartDate
        ? [adjustedDates.lastSeenStartDate]
        : [],
      lastSeenEndDate: adjustedDates.lastSeenEndDate
        ? [adjustedDates.lastSeenEndDate]
        : [],
    }
  }

  // If no date range type was provided, return the current filter unchanged
  return currentFilter
}

const sonarDateRangeSelectHelper = {
  handleDateRange,
  formatDate,
  adjustDateRangeFromUrl,
  updateSonarFilterWithDates,
}

export default sonarDateRangeSelectHelper
