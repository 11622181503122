import { createMuiTheme } from '@material-ui/core/styles'
import typography from './typography'
import { baseColors } from './colors'
import breakpoints from './breakpoints'

//All common modal styles go here. Individual modal types should only define colours.
const modalStyles = {
  modal: {
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  header: {
    fontWeight: typography.fontWeight.semiBold,
    margin: 0,
  },
  gridContainer: {
    padding: '0',
  },
  confirmButtonLoadingSpinner: {
    marginRight: '8px',
  },
  dialogActions: {
    padding: '8px 0 0 0',
  },
  dialogContent: {
    padding: '16px !important',
  },
  icon: {
    marginRight: '10px',
    fontSize: '24px',
  },
  headerBackground: {
    padding: '8px 16px',
  },
  cancelButton: {
    textTransform: 'capitalize',
    fontFamily: typography.fontFamily.body,
    fontWeight: typography.fontWeight.bold,
    height: '36px',
  },
  confirmButton: {
    textTransform: 'capitalize',
    fontFamily: typography.fontFamily.body,
    fontWeight: typography.fontWeight.bold,
    height: '36px',
  },
  deleteButton: {
    textTransform: 'capitalize',
    fontFamily: typography.fontFamily.body,
    fontWeight: typography.fontWeight.bold,
    height: '36px',
  },
}

const theme = createMuiTheme({
  typography: {
    h1: {
      fontSize: typography.fontSize.XXL,
      fontWeight: typography.fontWeight.bold,
      lineHeight: 1.6,
      letterSpacing: '-0.4px',
      fontFamily: typography.fontFamily.heading,
    },
    h2: {
      fontSize: typography.fontSize.XL,
      fontWeight: typography.fontWeight.semiBold,
      lineHeight: 1.2,
      letterSpacing: '-0.4px',
      fontFamily: typography.fontFamily.heading,
    },
    h3: {
      fontSize: typography.fontSize.L,
      fontWeight: typography.fontWeight.medium,
      lineHeight: 1.6,
      letterSpacing: '0px',
      fontFamily: typography.fontFamily.heading,
    },
    h4: {
      fontSize: typography.fontSize.M,
      fontWeight: typography.fontWeight.medium,
      lineHeight: 1.32,
      letterSpacing: '0.28px',
      fontFamily: typography.fontFamily.heading,
    },
    h5: {
      fontSize: typography.fontSize.regular,
      fontWeight: typography.fontWeight.medium,
      lineHeight: 1.6,
      letterSpacing: '0.2px',
      fontFamily: typography.fontFamily.heading,
    },
    body1: {
      fontSize: typography.fontSize.regular,
      fontWeight: typography.fontWeight.regular,
      lineHeight: 1.5,
      letterSpacing: '0.68px',
      fontFamily: typography.fontFamily.body,
      margin: '1% 0',
    },
    body2: {
      fontSize: typography.fontSize.S,
      fontWeight: typography.fontWeight.regular,
      lineHeight: 1.4,
      letterSpacing: '0.6px',
      fontFamily: typography.fontFamily.body,
    },
    subtitle1: {
      fontFamily: typography.fontFamily.heading,
      fontSize: typography.fontSize.regular,
      fontWeight: typography.fontWeight.medium,
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    subtitle2: {
      color: '#000000',
      fontFamily: typography.fontFamily.body,
      fontSize: typography.fontSize.S,
      fontWeight: typography.fontWeight.semiBold,
      lineHeight: 1.4,
      letterSpacing: '0.4px',
    },
    caption: {
      color: '#000000',
      fontSize: typography.fontSize.S,
      fontWeight: typography.fontWeight.regular,
      lineHeight: 1.66,
      letterSpacing: '0.4px',
      fontFamily: typography.fontFamily.body,
    },
    overline: {
      color: '#000000',
      fontSize: typography.fontSize.XS,
      fontWeight: typography.fontWeight.regular,
      lineHeight: 2,
      letterSpacing: '1px',
      fontFamily: typography.fontFamily.body,
      textTransform: 'uppercase',
    },
    headerInfo: {
      color: '#000000',
      fontSize: typography.fontSize.XS,
      fontWeight: typography.fontWeight.regular,
      lineHeight: 1.4,
      letterSpacing: '0.4px',
      fontFamily: typography.fontFamily.body,
    },
    fontFamily: ['Inter', 'Urbanist', 'sans-serif'].join(','),
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: baseColors.primary.light,
      main: baseColors.primary.main,
      dark: baseColors.primary.dark,
      contrastText: baseColors.primary.contrast,
    },
    secondary: {
      light: baseColors.secondary.light,
      main: baseColors.secondary.main,
      dark: baseColors.primary.dark,
      contrastText: baseColors.secondary.contrast,
    },
    warning: {
      light: baseColors.warning.light,
      main: baseColors.warning.main,
      dark: baseColors.warning.dark,
      contrastText: baseColors.warning.contrast,
    },
    action: {
      disabled: 'rgba(0, 0, 0, 0.26) !important',
    },
    error: {
      main: baseColors.error.light,
    },
  },
  overrides: {
    MuiDrawer: {
      background: baseColors.primary.main,
    },
    MuiFormControl: {
      root: {
        width: '100%',
        margin: '5px 0',
      },
    },
    MuiFormHelperText: {
      contained: {
        'margin-left': '0',
        'margin-right': '0',
        'margin-top': '0',
        'margin-bottom': '2%',
      },
    },
    MuiFormLabel: {
      root: {
        color: baseColors.text.secondary,
      },
      colorSecondary: {
        color: '#eee !important',
      },
    },
    MuiListItem: {
      root: {
        width: '160px',
      },
    },
    MuiSnackbar: {
      root: {
        borderRadius: '4px',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'rgba(97, 97, 97, 0.9)',
        fontFamily: typography.fontFamily.heading,
        fontSize: '10px',
        minWidth: '100px',
        fontWeight: typography.fontWeight.medium,
      },
    },
    MuiInputBase: {
      input: {
        lineHeight: '1.5',
      },
      colorSecondary: {
        color: '#eee !important',
        borderColor: '#eee !important',
      },
    },
    MuiOutlinedInput: {
      root: {
        minHeight: '42px',
        notchedOutline: {
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)  !important',
          },
        },
      },
      input: {
        '&:hover': {
          borderColor: '#ddd !important',
        },
      },
    },
    MuiSelect: {
      outlined: {
        '&:focus': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: typography.fontWeight.medium,
        fontFamily: typography.fontFamily.heading,
        backgroundColor: baseColors.table.background,
      },
      body: {
        fontWeight: typography.fontWeight.medium,
        fontSize: typography.fontSize.S,
        fontFamily: typography.fontFamily.body,
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
        textOverflow: 'ellipsis',
      },
      sizeSmall: {
        padding: '6px 24px 6px 24px', // Customize the padding here
      },
    },
    MuiTableBody: {
      root: {
        width: '100%',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected, &$checked': {
          backgroundColor: baseColors.table.selected,
        },
      },
      hover: {
        '&$hover:hover': {
          backgroundColor: baseColors.table.background,
        },
        '&$selected:hover': {
          backgroundColor: baseColors.table.selected,
        },
      },
    },
    MuiTablePagination: {
      caption: {
        fontWeight: typography.fontWeight.semiBold,
      },
      toolbar: {
        '& > p:nth-of-type(2)': {
          fontWeight: typography.fontWeight.semiBold, //total pages text
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '4px',
      },
    },
    MuiButton: {
      root: {
        lineHeight: '23.52px',
        letterSpacing: '0.6px',
        fontFamily: typography.fontFamily.body,
        fontWeight: typography.fontWeight.bold,
      },
      sizeSmall: {
        fontSize: typography.fontSize.XS,
        lineHeight: '20.64px',
        letterSpacing: '0.8px',
        padding: '4px 10px',
      },
      sizeMedium: {
        fontSize: typography.fontSize.S,
        lineHeight: '23.52px',
        letterSpacing: '0.6px',
        padding: '6px 16px',
      },
      sizeLarge: {
        fontSize: typography.fontSize.regular,
        lineHeight: '25.6px',
        letterSpacing: '0.6px',
        padding: '8px 22px',
      },
      textPrimary: {
        color: '#e37f1c !important',
      },
      textSecondary: {
        color: '#707070 !important',
      },
      containedPrimary: {
        textTransform: 'capitalize',
        borderRadius: '4px',
      },
      outlinedPrimary: {
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: baseColors.primary.hover,
          color: baseColors.primary.main,
        },
        borderRadius: '4px',
        border: '1px solid #e37f1c',
        colorInherit: '#fff',
        color: '#e37f1c !important',
        textTransform: 'capitalize',
      },
      outlinedSecondary: {
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#eee',
          color: '#707070 !important',
        },
        background: '#707070',
        borderRadius: '4px',
        border: '1px solid #eee',
        colorInherit: '#eee',
        color: '#eee !important',
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiChip: {
      root: {
        borderRadius: '8px',
        backgroundColor: baseColors.action.selected,
        height: '24px',
        fontSize: typography.fontSize.XS,
        lineHeight: '1.5',
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiTab: {
      textColorInherit: {
        opacity: 0.6,
      },
    },
    MuiAlert: {
      standardInfo: {
        backgroundColor: baseColors.info.fiftyMainOpacity,
        color: baseColors.text.secondary,
        fontFamily: typography.fontFamily.heading,
        fontSize: typography.fontSize.regular,
        fontWeight: typography.fontWeight.semiBold,
        letterSpacing: '0.2px',
        lineHeight: '24px',
      },
      icon: {
        marginTop: 'auto',
        marginBottom: 'auto',
        color: `${baseColors.text.secondary} !important`,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0px 16px',
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: '0',
        '&$expanded': {
          minHeight: 0,
        },
      },
      content: {
        margin: '8px 0px',
        '&$expanded': {
          margin: '8px 0px',
        },
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '500px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '16px',
        '&:first-child': {
          paddingTop: '16px', // overriding default padding for the first child
        },
      },
    },
  },
  // Custom Classes are to be used with the "useTheme" hook. Primarily for Warning/Success/Info Buttons.
  // This is provided due to the lack of CSS APIs to overwrite certain Mui Button variants
  customClasses: {
    // Common styles like fontSize, margin, padding, etc., scroll up to modalStyles.
    // ONLY colors should be defined here:
    warningModal: {
      ...modalStyles,
      fontColor: {
        color: baseColors.warning.hover,
      },
      icon: {
        ...modalStyles.icon,
        color: baseColors.warning.hover,
      },
      headerBackground: {
        ...modalStyles.headerBackground,
        backgroundColor: baseColors.warning.background,
      },
      confirmButton: {
        ...modalStyles.confirmButton,
        backgroundColor: baseColors.warning.dark,
        color: baseColors.text.contrast,
        '&:hover': {
          backgroundColor: baseColors.warning.hover,
        },
      },
      cancelButton: {
        ...modalStyles.cancelButton,
        borderColor: baseColors.error.dark,
        color: baseColors.error.dark,
      },
      deleteButton: {
        ...modalStyles.deleteButton,
        color: baseColors.error.main,
      },
      deleteIcon: {
        color: baseColors.error.main,
      },
    },
    successModal: {
      ...modalStyles,
      fontColor: {
        color: baseColors.success.hover,
      },
      icon: {
        ...modalStyles.icon,
        color: baseColors.success.hover,
      },
      closeIcon: {
        color: baseColors.success.hover,
      },
      headerBackground: {
        ...modalStyles.headerBackground,
        backgroundColor: baseColors.success.background,
      },
      confirmButton: {
        ...modalStyles.confirmButton,
        backgroundColor: baseColors.success.dark,
        color: baseColors.text.contrast,
        '&:hover': {
          backgroundColor: baseColors.success.hover,
        },
      },
      cancelButton: {
        ...modalStyles.cancelButton,
        borderColor: baseColors.success.dark,
        color: baseColors.success.dark,
      },
    },
    errorModal: {
      ...modalStyles,
      fontColor: {
        color: baseColors.error.dark,
      },
      icon: {
        ...modalStyles.icon,
        color: baseColors.error.dark,
      },
      headerBackground: {
        ...modalStyles.headerBackground,
        backgroundColor: baseColors.error.background,
      },
      confirmButton: {
        ...modalStyles.confirmButton,
        backgroundColor: baseColors.error.dark,
        color: baseColors.text.contrast,
        '&:hover': {
          backgroundColor: baseColors.error.dark,
        },
      },
      cancelButton: {
        ...modalStyles.cancelButton,
        borderColor: baseColors.error.dark,
        color: baseColors.error.dark,
      },
    },
    filterPanel: {
      mainContainer: {
        position: 'relative',
        overflow: 'visible',
      },
      wrapperUnshifted: {
        width: '100%',
        marginLeft: 'auto',
        transition: 'width 0.3s ease-in-out',
      },
      wrapperShifted: {
        marginLeft: 'auto',
        transition: 'width 0.3s ease-in-out',
        [`@media (min-width: ${breakpoints.xxl}px)`]: {
          width: '80%',
        },
        [`@media (min-width: ${
          breakpoints.xl
        }px) and (max-width: ${breakpoints.xxl - 1}px)`]: {
          width: '75%',
        },
        [`@media (min-width: ${
          breakpoints.lg
        }px) and (max-width: ${breakpoints.xl - 1}px)`]: {
          width: '70%',
        },
        [`@media (min-width: ${
          breakpoints.md
        }px) and (max-width: ${breakpoints.lg - 1}px)`]: {
          width: '60%',
        },
        [`@media (min-width: ${
          breakpoints.sm
        }px) and (max-width: ${breakpoints.md - 1}px)`]: {
          width: '50%',
        },
        [`@media (max-width: ${breakpoints.sm - 1}px)`]: {
          width: '100%',
        },
      },
      filterButtonInactive: {
        backgroundColor: 'white',
        color: baseColors.secondary.main,
        '&:hover': {
          backgroundColor: 'white',
        },
        textTransform: 'capitalize',
        fontFamily: typography.fontFamily.body,
        fontWeight: typography.fontWeight.bold,
      },
      filterButtonActive: {
        backgroundColor: 'rgba(115, 156, 206, 0.26)',
        color: baseColors.secondary.main,
        '&:hover': {
          backgroundColor: 'rgba(115, 156, 206, 0.26)',
        },
        textTransform: 'capitalize',
        fontFamily: typography.fontFamily.body,
        fontWeight: typography.fontWeight.bold,
      },
      btnContainer: {
        marginTop: '8px',
        paddingLeft: '8px',
      },
      filterPanelOpen: {
        width: '236px',
        position: 'absolute',
        top: '130px',
        opacity: '100% !important',
        transition: 'opacity 300ms',
        transitionTimingFunction: 'ease-in',
        display: 'inline-block',
        [`@media (max-width: ${breakpoints.sm - 1}px)`]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '90%',
          height: '100%', //for scrolling when filters expand
          backgroundColor: '#f9f9f9',
          zIndex: 50,
          padding: '5%',
          top: '50px',
        },
      },
      filterPanel: {
        width: '236px',
        position: 'absolute',
        top: '130px',
        opacity: '0%',
        transition: 'opacity 100ms',
        transitionTimingFunction: 'ease-in',
        display: 'inline-block',
        zIndex: -1,
      },
      filterPanelContainer: {
        display: 'inline-block',
        height: '100%',
      },
      chipsContainer: {
        margin: '16px 0px 16px -5px',
      },
      activeChip: {
        backgroundColor: 'rgba(112, 112, 112, 0.08)',
        letterSpacing: '0.8px',
        height: '32px',
        fontWeight: typography.fontWeight.medium,
      },
      clearButton: {
        textTransform: 'none',
        letterSpacing: '0.5px',
        borderRadius: '8px',
        height: '30px',
        marginTop: '5px',
        marginLeft: '5px',
        fontSize: '0.75em',
      },
    },
    expandablePrompt: {
      container: {
        margin: '16px',
        borderRadius: '4px',
      },
      headingContainer: {
        backgroundColor: baseColors.info.fiftyMainOpacity,
        padding: '10px',
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
      expandButton: {
        textTransform: 'capitalize',
        color: baseColors.info.main,
        whiteSpace: 'nowrap',
        borderColor: baseColors.info.main,
      },
    },
    backdrop: {
      loadingScreen: {
        zIndex: '1200',
      },
    },
    outlinedDeleteButton: {
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: baseColors.error.hover,
        color: baseColors.error.main,
      },
      borderRadius: '4px',
      border: '1px solid #D74F39',
      colorInherit: '#fff',
      color: baseColors.error.main,
      textTransform: 'capitalize',
    },
  },
})

export default theme
