import { atom } from 'recoil'
import { IReviewedReportModal } from '../TSS/Logic/Types'

/**
 * Atom for TSS/AddData page settings
 */

const atomAddDataSettings = atom({
  key: 'addDataSettings',
  default: {
    divisionId: [],
    plantId: [],
    mixCode: [],
    isCO2Design: [],
    minNumSamples: [],
    maxNumSamples: [],
    mixDesignId: [],
    showArchived: ['false'],
    includeOnlyMissingDesignStrength: [],
    includeOnlyMissingMixAssociation: [],
  },
})

const atomMixCodeQuery = atom({
  key: 'mixCode',
  default: '',
})

const atomCustomerOptions = atom({
  key: 'customerOptions',
  default: [],
})

const atomPlantOptions = atom({
  key: 'plantOptions',
  default: [],
})

const atomSelectedMixGroup = atom({
  key: 'selectedMixGroup',
  default: {
    mixGroup: [],
  },
})

const atomReviewedReport = atom<IReviewedReportModal>({
  key: 'reviewedReport',
  default: {
    reportId: 0,
    divisionName: '',
    mixes: [],
  },
})

const atomErrorOpeningReport = atom<boolean>({
  key: 'errorOpeningCommissioningReport',
  default: false,
})

export {
  atomAddDataSettings,
  atomMixCodeQuery,
  atomCustomerOptions,
  atomPlantOptions,
  atomSelectedMixGroup,
  atomReviewedReport,
  atomErrorOpeningReport,
}
