import React from 'react'
import {
  Backdrop,
  Button,
  CircularProgress,
  Typography,
  makeStyles,
  Grid,
  Box,
  Tooltip,
} from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import AccessAlarmsOutlinedIcon from '@material-ui/icons/AccessAlarmsOutlined'
import theme from '../../../theme/muiTheme'
import hook from './UseAlarms'
import AlarmsTable from './AlarmsTable/AlarmsTable'
import { CSVLink } from 'react-csv'
import {
  alarmsCsvDownloadHeaders,
  ALARMS_DATA_DOWNLOAD_THRESHOLD,
} from '../Constants/SonarConstants'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
  },
  lastRefreshedText: {
    ...theme.typography.body2,
    fontStyle: 'italic',
    opacity: 0.6,
  },
  subTitle: {
    ...theme.typography.subtitle2,
  },
  tableWrapper: {
    height: '100%',
    paddingTop: '16px',
    width: '100%',
    marginBottom: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: { margin: '8px 0px' },
  backDropZIndex: {
    zIndex: 50,
  },
  tooltip: {
    marginTop: '10px !important',
  },
}))

const Alarms: React.FC = () => {
  const classes = useStyles()
  const {
    alarmsData,
    alarmsDownloadData,
    csvFilename,
    disableDownloadButton,
    isDownloadLoading,
    isLoading,
    lastRefreshed,
    onChangePage,
    onChangeRowsPerPage,
    onColumnHeaderClick,
    order,
    orderBy,
    page,
    rowsPerPage,
    setSnoozeSavedFlag,
    tableCount,
  } = hook.useAlarms()

  const renderDownloadButton = () => {
    const defaultButton = (
      <Button
        color="primary"
        variant="contained"
        startIcon={<GetAppIcon />}
        disabled={disableDownloadButton}
      >
        Download Data
      </Button>
    )

    const buttonWithTooltip = (
      <Tooltip
        title={
          <span style={{ marginTop: '32px' }}>
            Cannot download over {ALARMS_DATA_DOWNLOAD_THRESHOLD.text} entries.
            <br />
            Filter data or reach out to #info-kelowna.
          </span>
        }
        placement="top"
      >
        <span>{defaultButton}</span>
      </Tooltip>
    )

    const button = disableDownloadButton ? buttonWithTooltip : defaultButton

    // Return the button wrapped in CSVLink only if not disabled
    return disableDownloadButton ? (
      button
    ) : (
      <CSVLink
        data={alarmsDownloadData}
        filename={csvFilename}
        headers={alarmsCsvDownloadHeaders}
        id="csvLinkDownloadAlarmsData"
      >
        {button}
      </CSVLink>
    )
  }

  return (
    <>
      <Backdrop
        open={isLoading || isDownloadLoading}
        className={classes.backDropZIndex}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container direction="column">
        <Grid item xs className={classes.container}>
          <Typography variant="h3" align="left" className={classes.header}>
            <AccessAlarmsOutlinedIcon className={classes.header} />
            Alarms
          </Typography>
          <Typography variant="body2" className={classes.lastRefreshedText}>
            Last Refreshed: {lastRefreshed}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle2" className={classes.subTitle}>
            View and manage all alarm data. By default, Down Systems are
            filtered out. To view Down Systems, use the tab above.
          </Typography>
        </Grid>
        <Grid item xs>
          <Box className={classes.tableWrapper}>
            <AlarmsTable
              alarmsData={alarmsData}
              count={tableCount}
              onColumnHeaderClick={onColumnHeaderClick}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              setSnoozeSavedFlag={setSnoozeSavedFlag}
            />
          </Box>
        </Grid>
        <Grid item className={classes.buttonWrapper}>
          {renderDownloadButton()}
        </Grid>
      </Grid>
    </>
  )
}

export default Alarms
