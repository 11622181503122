const fetchQuicksightEmbedURL = async (qsURL: string, qsParams = {}) => {
  const token = localStorage.getItem('JWT')
  const options = {
    mode: 'cors',
    headers: {
      Authorization: `${token}`,
    },
  }
  const url = new URL(qsURL)
  const urlSearchParams = new URLSearchParams(qsParams)
  url.search = urlSearchParams.toString()
  try {
    //@ts-ignore
    let res = await fetch(url, options)
    if (!res.ok) {
      return '<html><p>ERROR!<p><html>'
    }
    let resMetaData = await res.json()
    return resMetaData.EmbedUrl
  } catch (err) {
    console.log('Fetch error:' + err)
  }
}

const QuicksightHelper = {
  fetchQuicksightEmbedURL,
}

export default QuicksightHelper
