import { useRecoilValue } from 'recoil'
import { atomSonarTabValue } from '../../../Common/atoms'

/**
 * Custom React hook to get the current value of the sonar active tab.
 *
 * This hook uses the Recoil library's `useRecoilValue` function to read the current state of the `atomSonarTabValue` atom.
 * The `atomSonarTabValue` atom represents the active tab in the global Recoil state.
 *
 * @returns The current value of the sonar active tab.
 */
export const useSonarTabValue = () => {
  const tabValue: number = useRecoilValue(atomSonarTabValue)
  return tabValue
}
