import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import RedirectToFusionAuthLogin from './Common/Components/RedirectToFusionAuthLogin'
import AuthenticateAndRedirect from './Common/Components/AuthenticateAndRedirect'
import ProtectedRoute from './Common/Components/ProtectedRoute'
import NavBar from './Common/Components/NavBar'
import AllDepartmentObjects from './Common/assets/projectLists'
import LegacyPCBMainPage from './Eng/LegacyPCB/Views/LegacyPCBMainPage'
import QuickSightHome from './Quicksight/Views/QuickSightHome'
import ConcreteHome from './RouteHomes/ConcreteHome'
import ProducersHome from './RouteHomes/ProducersHome'
import HardwareHome from './RouteHomes/HardwareHome'
import { hardwareCanRead } from './Hardware/Logic/HardwareLogic'
import { atomJWT } from './Common/atoms'
import './App.css'

export function initializePendo(userObj) {
  const currentEnv = process.env.REACT_APP_ENV || 'testing'
  const pendoIsAvailable = window.pendo !== undefined
  const rolesAsString = userObj.roles.toString()

  // set defaults
  let account = {
    id: `carboncure-orca-${currentEnv}`,
    name: `Orca (${currentEnv})`,
    user_email: userObj.email,
  }

  if (pendoIsAvailable) {
    window.pendo.initialize({
      visitor: {
        id: userObj.userId,
        email: userObj.email,
        name: userObj.preferred_username,
        role: rolesAsString,
      },
      account: {
        id: account.id,
        name: account.name,
      },
    })
  }
  return account
}

function AppRouter() {
  /** User's JSON Web Token (JWT) from localStorage */
  const JWT = useRecoilValue(atomJWT)
  initializePendo(JWT)
  const hasTSSAccess =
    JWT.roles.includes('TSS') ||
    JWT.roles.includes('TSS_W') ||
    JWT.roles.includes('TSS_Review') ||
    JWT.roles.includes('OrcaAdmin')
  const hasMixerAccess = hardwareCanRead(JWT.roles)

  let finalRedirectPath = '/Producers/Echo'
  if (hasTSSAccess) {
    finalRedirectPath = '/Concrete/AddData'
  } else if (hasMixerAccess) {
    finalRedirectPath = '/Hardware/AlarmsManagement'
  }

  return (
    <BrowserRouter>
      <NavBar navLinks={AllDepartmentObjects} />
      <Switch>
        <Route path="/Login">
          <RedirectToFusionAuthLogin />
        </Route>
        <Route path="/oauthcallback">
          {JWT.roles.includes('Orca') ? (
            <AuthenticateAndRedirect redirectTo="/Producers" />
          ) : (
            <AuthenticateAndRedirect redirectTo="/Concrete" />
          )}
        </Route>
        <ProtectedRoute path="*">
          <Switch>
            {hasTSSAccess ? (
              <Route path="/Concrete">
                <ConcreteHome finalRedirectPath={finalRedirectPath} />
              </Route>
            ) : null}

            {hasTSSAccess || JWT.roles.includes('Orca') ? (
              <Route path="/Producers">
                <ProducersHome finalRedirectPath={finalRedirectPath} />
              </Route>
            ) : null}

            {hasMixerAccess ? (
              <Route path="/Hardware">
                <HardwareHome finalRedirectPath={finalRedirectPath} />
              </Route>
            ) : null}

            <Route path="/QuickSight">
              <QuickSightHome />
            </Route>

            <Route path="/PCB">
              <LegacyPCBMainPage />
            </Route>
            <Redirect from="/Hammer" to="/Hardware" />
            {/* Catch-all route for any non-existing paths */}
            <Route path="*">
              <Redirect to={finalRedirectPath} />
            </Route>
          </Switch>
        </ProtectedRoute>
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
