import {
  FilterOption,
  ICustomerFilterOptions,
} from '../../../Common/Logic/Types'

/**
 * Provides a detailed description for the SonarView component.
 * This description outlines the functionality of managing alarm data,
 * @returns {string} A comprehensive description for the SonarView interface.
 */
export const sonarDescription = `Manage alarm data with Sonar's 24/7 tracking. Keep ahead of alarms and critical issues to ensure 
our systems continue to run smoothly. See an overview of alarms data, spot trends, check on systems 
that are down, and snooze expected alarms.`

/**
 * Represents the technology types options
 */
export const technologyTypes: FilterOption[] = [
  { id: 'Plc', name: 'PLC' },
  { id: 'Pcb', name: 'PCB' },
  { id: 'ReclaimedWater', name: 'RW' },
]

export const defaultCustomerFilterOptions: ICustomerFilterOptions = {
  divisionId: [],
  plantId: [],
  systemId: [],
  region: [],
  country: [],
  state: [],
  city: [],
}

/**
 * Refresh interval for the Down Systems tab in milliseconds.
 */
export const REFRESH_INTERVAL_DOWNSYSTEMS = 1800000 // 30 minutes

/**
 * Refresh interval for the Alarms tab in milliseconds.
 */
export const REFRESH_INTERVAL_ALARMS = 300000 // 5 minutes

/**
 * Represents the Down Systems CSV Download Headers
 */
export const downSystemsCsvDownloadHeaders = [
  { label: 'Last Seen TimeStamp', key: 'lastSeenTimestamp' },
  { label: 'System ID', key: 'systemId' },
  { label: 'Technology', key: 'technologyType' },
  { label: 'Producer', key: 'divisionName' },
  { label: 'Location', key: 'location' },
  { label: 'Time Down (Days)', key: 'daysDown' },
  { label: 'Snoozed', key: 'snoozed' },
  { label: 'Reason', key: 'snoozeReason' },
  { label: 'Other Reason Details', key: 'snoozeReasonDetails' },
  { label: 'Snoozed By', key: 'snoozeCreatedByUserFirstAndLastName' },
  { label: 'Snooze Creation Date', key: 'snoozeCreationTimestamp' },
  { label: 'Snooze Start Date', key: 'snoozeStartTimestamp' },
  { label: 'Snooze End Date', key: 'snoozeEndTimestamp' },
  {
    label: 'Snooze Last Updated Date',
    key: 'snoozeLastUpdatedTimestamp',
  },
  {
    label: 'Snooze Last Updated By',
    key: 'snoozeLastUpdatedByUserFirstAndLastName',
  },
]

/**
 * Represents the options for the Time Down options
 */
export const timeDownOptions = [
  { id: '<10', name: '<10 days' },
  { id: '10-30', name: '10-30 days' },
  { id: '30-180', name: '30-180 days' },
  { id: '>180', name: '\u2265180 days' }, // ≥180 days
]

/**
 * Represents the options for the applicable filter items for the Down Systems
 */
export const downSystemsTableApplicableFilters = [
  { atomKey: 'divisionId', filterKey: 'divisionId' },
  { atomKey: 'plantId', filterKey: 'plantId' },
  { atomKey: 'systemId', filterKey: 'systemId' },
  { atomKey: 'lastSeenStartDate', filterKey: 'startLastSeenTimestamp' },
  { atomKey: 'lastSeenEndDate', filterKey: 'endLastSeenTimestamp' },
  { atomKey: 'region', filterKey: 'region' },
  { atomKey: 'country', filterKey: 'country' },
  { atomKey: 'state', filterKey: 'state' },
  { atomKey: 'city', filterKey: 'city' },
  { atomKey: 'shouldShowSnoozed', filterKey: 'shouldShowSnoozed' },
  { atomKey: 'shouldShowUnsnoozed', filterKey: 'shouldShowUnsnoozed' },
]

export const alarmsTableApplicableFilters = [
  { atomKey: 'divisionId', filterKey: 'divisionId' },
  { atomKey: 'plantId', filterKey: 'plantId' },
  { atomKey: 'systemId', filterKey: 'systemId' },
  { atomKey: 'region', filterKey: 'region' },
  { atomKey: 'country', filterKey: 'country' },
  { atomKey: 'state', filterKey: 'state' },
  { atomKey: 'city', filterKey: 'city' },
  { atomKey: 'alarmSeverity', filterKey: 'alarmSeverity' },
  { atomKey: 'alarmType', filterKey: 'alarmCode' },
  { atomKey: 'lastSeenStartDate', filterKey: 'startTimestamp' },
  { atomKey: 'lastSeenEndDate', filterKey: 'endTimestamp' },
  { atomKey: 'shouldShowSnoozed', filterKey: 'shouldShowSnoozed' },
  { atomKey: 'shouldShowUnsnoozed', filterKey: 'shouldShowActive' },
]

export const alarmsTypeOptionsApplicableFilters = [
  { atomKey: 'technology', filterKey: 'technology' },
  { atomKey: 'alarmSeverity', filterKey: 'alarmSeverity' },
]

export const snoozeStatusOptions = [
  { id: 'shouldShowUnsnoozed', name: 'Unsnoozed' },
  { id: 'shouldShowSnoozed', name: 'Snoozed' },
]

export const alarmSeverityOptions = [
  { id: 'Low', name: 'Low' },
  { id: 'Medium', name: 'Medium' },
  { id: 'High', name: 'High' },
]

/**
 * Threshold to disable .
 */
export const ALARMS_DATA_DOWNLOAD_THRESHOLD = {
  value: 100000,
  text: '100,000',
}

export const alarmsCsvDownloadHeaders = [
  { label: 'TimeStamp', key: 'timestamp' },
  { label: 'System ID', key: 'systemId' },
  { label: 'Technology', key: 'technology' },
  { label: 'Producer', key: 'divisionName' },
  { label: 'Plant', key: 'plantName' },
  { label: 'Location', key: 'location' },
  { label: 'Severity', key: 'alarmSeverity' },
  { label: 'Type', key: 'description' },
  { label: 'Snoozed', key: 'snoozed' },
  { label: 'Reason', key: 'snoozeReason' },
  { label: 'Other Reason Details', key: 'snoozeDetails' },
  { label: 'Snoozed By', key: 'snoozeCreatedByUserFullName' },
  { label: 'Snooze Creation Date', key: 'snoozeCreationTimestamp' },
  { label: 'Snooze Start Date', key: 'snoozeStartTimestamp' },
  { label: 'Snooze End Date', key: 'snoozeEndTimestamp' },
  { label: 'Snooze Last Updated Date', key: 'snoozeLastUpdated' },
  {
    label: 'Snooze Last Updated By',
    key: 'snoozeLastUpdatedByUserFullName',
  },
]
