import { useMemo, useState, useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { atomJWT, atomSonarFrontendFilter } from '../../../../Common/atoms'
import alarmsTableHelper from './AlarmsTableHelper'
import { ISonarAlarm } from '../../../../Common/Logic/Types'

export interface IUseAlarmsTableProps {}

const useAlarmsTable = () => {
  // State and Setters
  const sonarFrontendFilters = useRecoilValue(atomSonarFrontendFilter)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [showThreeDotsMenu, setShowThreeDotsMenu] = useState<boolean>(false)
  const [clickedAlarm, setClickedAlarm] = useState<ISonarAlarm | null>(null)
  const [showSnoozeModal, setShowSnoozeModal] = useState<boolean>(false)
  const [showSnoozeSuccessModal, setShowSnoozeSuccessModal] = useState<boolean>(
    false
  )
  // Memo
  const alarmsTableHeadCells = useMemo(
    () =>
      alarmsTableHelper.getAlarmsTableHeadCells(
        sonarFrontendFilters.isUTC?.[0]
      ),
    [sonarFrontendFilters.isUTC]
  )
  const JWT = useRecoilValue(atomJWT)

  // Callbacks
  const handleActionButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, alarm) => {
      setAnchorEl(event.currentTarget)
      setClickedAlarm(alarm)
      setShowThreeDotsMenu(true)
    },
    []
  )

  const handleCloseThreeDotsMenu = useCallback(() => {
    setShowThreeDotsMenu(false)
    setClickedAlarm(null)
    setAnchorEl(null)
  }, [])

  const handleSnoozeModalOpen = () => {
    setShowSnoozeModal(true)
    setAnchorEl(null)
    setShowThreeDotsMenu(false)
  }

  // Effects

  return {
    alarmsTableHeadCells,
    anchorEl,
    clickedAlarm,
    handleActionButtonClick,
    handleCloseThreeDotsMenu,
    handleSnoozeModalOpen,
    JWT,
    setClickedAlarm,
    setShowSnoozeModal,
    setShowSnoozeSuccessModal,
    showThreeDotsMenu,
    showSnoozeSuccessModal,
    sonarFrontendFilters,
    showSnoozeModal,
  }
}

const hook = {
  useAlarmsTable,
}

export default hook
