import { addDays } from 'date-fns'
import { fetchDataHelper } from '../../../Common/Helpers/DataHelpers'
import {
  IKelownaSonarAlarm,
  ISonarAlarm,
  ISonarFilter,
} from '../../../Common/Logic/Types'
import {
  ALARMS_DATA_DOWNLOAD_THRESHOLD,
  alarmsTableApplicableFilters,
} from '../Constants/SonarConstants'

/**
 * Fetch Alarms Data
 * @param limit
 * @param offset
 * @param order
 * @param orderBy
 * @param sonarFilter
 */
const fetchAlarmsData = async (
  limit: number,
  offset: number,
  order: string | undefined,
  orderBy: string,
  sonarFilter: ISonarFilter,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  abortController: AbortController
) => {
  let alarmsData = { count: 0, results: [] }
  if (limit > ALARMS_DATA_DOWNLOAD_THRESHOLD.value) {
    setIsLoading(false)
    return alarmsData
  }
  const sonarFilterParams = defineAlarmsFilter(sonarFilter)

  const params = {
    Limit: limit,
    Offset: offset,
    SortColumn: orderBy,
    SortOrder: order === 'asc' ? 'ascending' : 'descending',
    ...sonarFilterParams,
  }

  const arrayParams = {
    technologyTypes: sonarFilter.technology,
  }

  const endpoint = `/Hardware/Sonar/Alarms/Table`
  setIsLoading(true)

  try {
    const response = await fetchDataHelper(
      endpoint,
      params,
      arrayParams,
      abortController
    )

    // If the request was aborted, return the alarmsData
    if (abortController.signal.aborted) return alarmsData

    if (response.ok && response.status === 200) {
      alarmsData = await response.json()
    } else {
      console.error('Failed to fetch Alarms data')
    }
  } catch (error) {
    // @ts-ignore
    if (error.name === 'AbortError') return alarmsData
    console.error('Error fetching data:', error)
  } finally {
    if (!abortController.signal.aborted) setIsLoading(false)
  }

  return alarmsData
}

const defineAlarmsFilter = (sonarFilter: ISonarFilter) => {
  const sonarFilterParams = alarmsTableApplicableFilters.reduce(
    (acc, { atomKey, filterKey }) => {
      const value = sonarFilter[atomKey]
      if (
        value !== undefined &&
        value !== null &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        acc[filterKey] = value
      }
      return acc
    },
    {} as Record<string, any>
  )
  // Adjust cutoff date for custom range
  if (sonarFilter?.lastSeenDateRange?.[0] === 'customRange') {
    // Extract end date from params and parse into a Date object
    const endDateObject = new Date(
      `${sonarFilterParams.endTimestamp?.[0]}T00:00:00Z`
    )

    // Adjust cutoff date to the next day at midnight UTC
    const cutoffDate = addDays(endDateObject, 1)
      .toISOString()
      .split('T')[0]

    // Update the endTimestamp with cutoff date
    sonarFilterParams.endTimestamp = [cutoffDate]
  }
  return sonarFilterParams
}

const digestAlarmsData = (
  kelownaAlarms: IKelownaSonarAlarm[]
): ISonarAlarm[] => {
  return kelownaAlarms.map(kelownaAlarm => {
    return {
      ...kelownaAlarm,
      snoozed: kelownaAlarm.snoozeId !== null,
    }
  })
}

const alarmsHelper = {
  defineAlarmsFilter,
  digestAlarmsData,
  fetchAlarmsData,
}

export default alarmsHelper
