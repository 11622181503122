import { useState, useEffect } from 'react'

export interface IUseSonarSnoozeReasonDetails {
  reasonDetails: string
  handleReasonDetailsChange: (reasonDetails: string) => void
}
const useSonarSnoozeReasonDetails = (props: IUseSonarSnoozeReasonDetails) => {
  const { reasonDetails, handleReasonDetailsChange } = props
  const [reasonDetailsInternal, setReasonDetailsInternal] = useState<string>(
    reasonDetails
  )

  const handleInternalReasonDetailsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReasonDetailsInternal(event.target.value)
  }

  useEffect(() => {
    const timeOut = setTimeout(
      () => handleReasonDetailsChange(reasonDetailsInternal),
      500
    )
    return () => clearTimeout(timeOut)
  }, [reasonDetailsInternal, handleReasonDetailsChange])

  return { reasonDetailsInternal, handleInternalReasonDetailsChange }
}

const hook = {
  useSonarSnoozeReasonDetails,
}

export default hook
