import React from 'react'
import { TableSortLabel, Tooltip } from '@material-ui/core'
import { TSortOrder } from '../../Logic/Types'

interface ITableSortLabelComponentProps {
  cell: {
    id: string
    name: string
    sortable: boolean
    tooltip?: string
  }
  order: TSortOrder
  orderBy: string
  onColumnHeaderClick: (
    event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    property: string
  ) => void
  getOrderDirection: (
    id: string,
    order: TSortOrder,
    orderBy: string
  ) => TSortOrder
}

const TableSortLabelComponent = ({
  cell,
  order,
  orderBy,
  onColumnHeaderClick,
  getOrderDirection,
}: ITableSortLabelComponentProps) => {
  return (
    <TableSortLabel
      active={orderBy === cell.id}
      direction={getOrderDirection(cell.id, order, orderBy)}
      onClick={event => cell.sortable && onColumnHeaderClick(event, cell.id)}
    >
      {cell.tooltip ? (
        <Tooltip arrow title={cell.tooltip}>
          <span>{cell.name}</span>
        </Tooltip>
      ) : (
        <span>{cell.name}</span>
      )}
    </TableSortLabel>
  )
}

export default TableSortLabelComponent
