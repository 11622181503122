import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  TableRow,
  Tooltip,
  Collapse,
  Grid,
  Checkbox,
  MenuItem,
  TextField,
} from '@material-ui/core/'
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Visibility,
  ChevronRight,
  Close,
  EditOutlined,
  Undo,
} from '@material-ui/icons'
import {
  CellBehavior,
  SimpleTableSelection,
  SimpleTableRow,
  SimpleTableComplexCell,
  SimpleTableTypes,
  SimpleTableOrder,
} from '../../Logic/Types'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import MixVariationCollapsibleRow from '../../../TSS/Components/MixVariationCollapsibleRow/MixVariationCollapsibleRow'
import EditMixAssociationIconOrange from '../../../TSS/Assets/MixGroupsFilled-Orange.svg'
import { tssCanWrite } from '../../../TSS/Logic/TSSLogic'
import { Link } from 'react-router-dom'
import {
  BaleenOutlierStatus,
  BaleenOutlierType,
  IMaterialCard,
} from '../../../TSS/Logic/Types'
// import MaterialManagerLinkIcon from '../../../TSS/Assets/MaterialManagerLinkIcon.svg'
import BaleenIcon from '../../../TSS/Assets/BaleenIcon.svg'
import { getTooltip, simpleTableStyles } from './SimpleTable'
import MaterialTableCollapsibleRow from '../../../TSS/Components/MaterialTable/MaterialTableCollapsibleRow'
import { baseColors } from '../../../theme/colors'
import BaleenOutlierReasonTextField from '../../../TSS/Components/BaleenOutlierReasonTextField'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './baleenRowTransitionStyles.css'
import MaterialManagerMergeIcon from '../../../TSS/Assets/MaterialManagerMergeIcon.svg'
import { getMaterialCellString } from '../../../TSS/Helpers/MaterialManagerHelpers'
import SimpleTablePagination from './SimpleTablePagination'
import typography from '../../../theme/typography'
import CementTableCollapsibleRow from '../../../Echo/Components/CementTable/CementTableCollapsibleRow'

export interface ISimpleTablePresentationalProps {
  selectionType: SimpleTableSelection
  isExpandable: boolean
  onToggle: (id: number | string) => void
  headCells: Array<any>
  rows: Array<SimpleTableRow>
  selected: Array<string | number>
  expanded: Array<string | number>
  order?: SimpleTableOrder
  orderBy?: string
  onSort?: Function

  rowsPerPage?: number
  rowCount?: number
  page?: number
  rowsPerPageOptions?: number[]
  onChangePage?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void
  onChangeRowsPerPage?: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  onCollapseToggle: Function
  parentClasses: ClassNameMap<string>
  roles: Array<string>
  getButtonUrl: (id: number | string) => string
  getBaleenUrl?: (id: number | string) => string
  getCommissionViewUrl?: (id: number | string) => string
  getAssociatedMixes?: (id: number | string) => void
  showAssociationIcon?: boolean
  tableType: SimpleTableTypes
  materialCards?: Array<IMaterialCard>
  setCurrentView?: () => void
  handleEditMaterial?: (arg0: number) => void
  setIsFilterPanelOpen?: (arg0: boolean) => void
  isFilterPanelOpen?: boolean
  removePotentialOutlierFromOutliersTable?: (arg0: number) => void
  enableRowHover?: boolean
  enablePagination?: boolean
  handleEditOrUndoClick?: (arg1: number, arg2: BaleenOutlierStatus) => void
  handleTextFieldSelectChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  handleTextFieldChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  >
  setIsSaveBtnDisabled: () => void
  areTableRowsDisabled: boolean

  handleModal: (id: number) => void
  handlePreviewClick: (id: number) => void
}

const emptyFn = () => {
  /* This is intentional */
}

const defaultProps: ISimpleTablePresentationalProps = {
  selectionType: SimpleTableSelection.None,
  isExpandable: false,
  onToggle: emptyFn,
  headCells: [],
  rows: [],
  selected: [],
  expanded: [],
  onSort: emptyFn,
  onChangePage: emptyFn,
  onChangeRowsPerPage: emptyFn,
  onCollapseToggle: emptyFn,
  parentClasses: {},
  roles: [''],
  getButtonUrl: () => '',
  getBaleenUrl: () => '',
  getCommissionViewUrl: () => '',
  getAssociatedMixes: emptyFn,
  showAssociationIcon: false,
  //@ts-ignore (For FUTURE RESOLUTION)
  tableType: '',
  materialCards: [],
  setCurrentView: emptyFn,
  handleEditMaterial: () => {},
  setIsFilterPanelOpen: emptyFn,
  handleModal: emptyFn,
  handlePreviewClick: emptyFn,
}

function SimpleTablePresentational(props: ISimpleTablePresentationalProps) {
  const {
    headCells,
    rows,
    selected,
    expanded,
    onToggle,
    selectionType,
    isExpandable,

    order,
    orderBy,
    onSort,

    rowsPerPage,
    rowCount,
    rowsPerPageOptions = [10, 20, 35],
    page,
    onChangePage,
    onChangeRowsPerPage,

    onCollapseToggle,
    parentClasses,
    roles,
    getButtonUrl,
    getBaleenUrl,
    getCommissionViewUrl,
    getAssociatedMixes,
    showAssociationIcon,
    tableType,
    materialCards,
    setCurrentView,
    handleEditMaterial,

    setIsFilterPanelOpen,
    isFilterPanelOpen,
    removePotentialOutlierFromOutliersTable,
    handleTextFieldSelectChange,
    handleTextFieldChange,
    handleEditOrUndoClick,
    setIsSaveBtnDisabled,
    enableRowHover = true,
    enablePagination = true,
    areTableRowsDisabled = false,
    handleModal,
    handlePreviewClick,
  } = props

  const useStyles = makeStyles(theme => ({
    tableContainer: {
      overflowY: 'hidden', // when baleen outliers table has multiple outliers and an error, scrollbar appears on container
    },
    table: {
      minWidth: 650,
      tableLayout: 'fixed',
      overflowY: 'hidden',
    },
    tableCell: {
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      maxWidth: '150px',
    },
    errorText: {
      color: baseColors.error.main,
    },
    root: {
      '& .MuiFormLabel-root': {
        display: 'flex',
        alignItems: 'center',
        '& .baleenWarningIcon': {
          paddingLeft: '85px',
          order: 999,
          color: baseColors.error.main,
        },
      },
    },
    ...simpleTableStyles(
      tableType,
      theme,
      selected,
      areTableRowsDisabled,
      isFilterPanelOpen
    ),
    echoCementTablePlusItem: {
      color: '#1A4373',
      fontSize: '14px',
      fontFamily: 'Urbanist',
      fontWeight: 600,
      paddingLeft: '8px',
    },
  }))

  const classes: ClassNameMap<string> = useStyles()

  const addFirstColumn =
    selectionType !== SimpleTableSelection.None || isExpandable
  const hasPagination =
    rowsPerPage && typeof page !== 'undefined' && typeof page !== 'undefined'

  // ----- SELECTABLE

  // getCurrentIcon
  // returns the right checkbox or radio
  const getCurrentIcon = (isSelected: boolean) => {
    if (selectionType === SimpleTableSelection.Single) {
      return isSelected ? RadioButtonChecked : RadioButtonUnchecked
    } else {
      return isSelected ? CheckBox : CheckBoxOutlineBlank
    }
  }

  // displaySelectorClickable
  // returns the IconButton for the row selection
  const displaySelectorClickable = (id: number | string) => {
    const isSelected = selected.includes(id)
    const color = isSelected ? 'primary' : 'default'
    return (
      <IconButton
        color={color}
        onClick={() => {
          onToggle(id)
        }}
        data-testid={`select-${id}`}
      >
        {React.createElement(getCurrentIcon(isSelected))}
      </IconButton>
    )
  }

  const displaySelectors = (id: number | string) => {
    return selectionType === SimpleTableSelection.None
      ? null
      : displaySelectorClickable(id)
  }

  const getButtonIcon = (
    roles: Array<string>,
    tableType: SimpleTableTypes,
    isIngested: boolean | undefined,
    isDefaultCement?: boolean,
    isDefaultCO2?: boolean | undefined,
    iconName?: string
  ) => {
    switch (tableType) {
      case SimpleTableTypes.AddDataMixGroup:
      case SimpleTableTypes.ViewDesignMixGroup:
        return Visibility
      case SimpleTableTypes.BaleenOutliersTable:
        if (iconName === 'Undo') return Undo
        else if (iconName === 'Edit') return EditOutlined
        else return Close

      //TODO: Refactor when working on link button using MaterialManagerLinkIcon
      case SimpleTableTypes.MaterialManager:
        if (
          tssCanWrite(roles) &&
          isIngested &&
          !isDefaultCement &&
          !isDefaultCO2
        ) {
          return MaterialManagerMergeIcon
        } else {
          return null
        }
      default:
        return null
    }
  }

  // display action icons when selected
  const displayButtonClickable = (
    id: string | number,
    isIngested: boolean | undefined,
    isDefaultCement?: boolean,
    isDefaultCO2?: boolean,
    iconName?: string
  ) => {
    const isSelected = selected.includes(id)
    const path = getButtonUrl(id)
    const icon = getButtonIcon(
      roles,
      tableType,
      isIngested,
      isDefaultCement,
      isDefaultCO2,
      iconName
    )
    const tooltip = getTooltip(tableType, isIngested)
    if (tableType === SimpleTableTypes.BaleenOutliersTable) {
      if (iconName === 'Edit') {
        return (
          <Tooltip title="Edit">
            <IconButton
              variant="contained"
              style={{
                color: areTableRowsDisabled
                  ? baseColors.action.disabled
                  : baseColors.primary.main,
              }}
              size="small"
              onClick={() =>
                handleEditOrUndoClick(id, 'PotentialOutlierChecked')
              }
              data-testid={`${id}-editIcon`}
            >
              {React.createElement(icon)}
            </IconButton>
          </Tooltip>
        )
      } else if (iconName === 'Undo') {
        return (
          <Tooltip title="Undo Edit">
            <IconButton
              variant="contained"
              style={{
                color: areTableRowsDisabled
                  ? baseColors.action.disabled
                  : baseColors.primary.main,
              }}
              size="small"
              onClick={() => handleEditOrUndoClick(id, 'Outlier')}
              data-testid={`${id}-undoIcon`}
            >
              {React.createElement(icon)}
            </IconButton>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title="Remove">
            <IconButton
              variant="contained"
              style={{
                color: areTableRowsDisabled
                  ? baseColors.action.disabled
                  : baseColors.error.main,
              }}
              size="small"
              onClick={() => removePotentialOutlierFromOutliersTable(id)}
            >
              {React.createElement(icon)}
            </IconButton>
          </Tooltip>
        )
      }
    }

    if (tableType === SimpleTableTypes.ReportLibraryTable) {
      const buttonContent = rows
        .find(row => row.id === id)
        ?.cells.find(cell => cell?.behavior === CellBehavior.ButtonLink)
        ?.content
      const previewPDF = buttonContent.text === 'Preview'
      const url =
        !previewPDF && getCommissionViewUrl
          ? getCommissionViewUrl(id)
          : undefined
      return (
        <IconButton
          component={previewPDF ? undefined : Link}
          style={{
            fontFamily: typography.fontFamily.body,
            fontSize: typography.fontSize.S,
            fontWeight: typography.fontWeight.bold,
          }}
          to={url}
          href={url}
          target={previewPDF ? undefined : '_blank'}
          color="primary"
          size="small"
          onClick={previewPDF && (() => handlePreviewClick(Number(id)))}
        >
          {buttonContent.text}
        </IconButton>
      )
    }

    if (tableType === SimpleTableTypes.MaterialManager) {
      return (
        <>
          <Tooltip arrow title={tooltip[0]}>
            {isIngested && !isDefaultCement && !isDefaultCO2 ? (
              <IconButton
                color="primary"
                size="small"
                onClick={() => handleModal(id)}
              >
                <img src={icon} alt={icon ? tooltip[0] : ''} />
              </IconButton>
            ) : (
              <IconButton
                component={Link}
                to={path} //TODO: Update when working on filtering mix designs by materials
                target="_blank"
                variant="contained"
                color="primary"
                size="small"
              >
                <img src={icon} alt={icon ? tooltip[0] : ''} />
              </IconButton>
            )}
          </Tooltip>
        </>
      )
    }
    if (!showAssociationIcon) {
      return (
        <Tooltip arrow title={tooltip[1]}>
          <IconButton
            component={Link}
            to={getButtonUrl(id)}
            target="_blank"
            variant="contained"
            color="primary"
            size="small"
          >
            {React.createElement(icon)}
          </IconButton>
        </Tooltip>
      )
    }
    if (isSelected && showAssociationIcon && getAssociatedMixes) {
      return (
        <Grid
          item
          style={{
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Tooltip arrow title={tooltip[0]}>
            <IconButton onClick={() => getAssociatedMixes(id)}>
              <img
                src={EditMixAssociationIconOrange}
                alt="Mix Association Icon"
              />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title={tooltip[2]}>
            <IconButton
              component={Link}
              to={getBaleenUrl ? getBaleenUrl(id) : ''}
              target="_blank"
              variant="contained"
              color="primary"
              size="small"
            >
              <img src={BaleenIcon} alt="Baleen Icon" />
            </IconButton>
          </Tooltip>

          <Tooltip arrow title={tooltip[1]}>
            <IconButton
              component={Link}
              to={getButtonUrl(id)}
              target="_blank"
              variant="contained"
              color="primary"
              size="small"
            >
              {React.createElement(icon)}
            </IconButton>
          </Tooltip>
        </Grid>
      )
    }
  }

  // ----- EXPANDABLE

  // displayExpanderClickable
  // returns the IconButton for the row expansion
  const displayExpanderClickable = (
    id: number | string,
    row: SimpleTableRow,
    tableType: SimpleTableTypes
  ) => {
    const hasVariations = row.variations?.length
    const isExpanded = expanded.includes(id)
    const icon = isExpanded ? ExpandLess : ExpandMore
    const materialTableIcon = isExpanded ? ExpandLess : ChevronRight

    if (hasVariations) {
      return (
        <IconButton
          onClick={() => {
            onCollapseToggle(id)
          }}
          data-testid={`collapse-${id}`}
        >
          {React.createElement(icon)}
        </IconButton>
      )
    }
    if (tableType === SimpleTableTypes.MaterialManager) {
      return (
        <IconButton
          onClick={() => {
            onCollapseToggle(id)
          }}
          data-testid="expand-material-button"
        >
          {React.createElement(materialTableIcon)}
        </IconButton>
      )
    }
    if (
      tableType === SimpleTableTypes.EchoCementTable &&
      row.collapsibleRows?.length
    ) {
      return (
        <IconButton
          onClick={() => {
            onCollapseToggle(id)
          }}
          data-testid={`collapse-${id}`}
        >
          {React.createElement(icon)}
        </IconButton>
      )
    }
  }
  const displayExpanders = (
    id: number | string,
    row: Array<SimpleTableRow>,
    tableType: SimpleTableTypes
  ) => {
    return isExpandable === false
      ? null
      : displayExpanderClickable(id, row, tableType)
  }

  // ------ CELLS
  const processBehaviour = (
    rowCell: SimpleTableComplexCell,
    id: string | number
  ) => {
    switch (rowCell.behavior) {
      case CellBehavior.Chips:
        return rowCell.content.map(elem => (
          <Chip
            color={elem.color}
            label={elem.text}
            style={elem.style ? elem.style : {}}
          />
        ))
      case CellBehavior.Link:
        return <a href={rowCell.content[1]}>{rowCell.content[0]}</a>
      case CellBehavior.ButtonLink:
        const isIngested = rowCell.content[1]
        const isDefaultCement = rowCell.content[2]
        const isDefaultCO2 = rowCell.content[3]
        return displayButtonClickable(
          id,
          isIngested,
          isDefaultCement,
          isDefaultCO2
        )
      case CellBehavior.Checkbox:
        return (
          <Tooltip title={rowCell.content[0]?.tooltipTitle}>
            <Checkbox
              color={rowCell.content[0]?.color}
              checked={rowCell.content[0]?.checked}
              onChange={e => onToggle(id, e)}
              disabled={rowCell.content[0]?.disabled || areTableRowsDisabled}
            />
          </Tooltip>
        )
      case CellBehavior.DropdownWithOther:
        return (
          <>
            <TextField
              select
              size="small"
              variant="outlined"
              value={rowCell.content[0]?.outlierReasonDropdown}
              label={
                rowCell.content[0]?.hasOutlierReasonDropdownError ? (
                  <>
                    <span className={classes.errorText}>Select Reason</span>
                    <ReportProblemOutlinedIcon className="baleenWarningIcon" />
                  </>
                ) : (
                  'Select Outlier Reason'
                )
              }
              onChange={e => handleTextFieldSelectChange(e, id)}
              disabled={rowCell.content[0]?.disabled || areTableRowsDisabled}
              error={rowCell.content[0].hasOutlierReasonDropdownError}
              className={classes.root}
              InputProps={{ style: { backgroundColor: 'white' } }}
            >
              {rowCell.content[0]?.options.map((option: string | number) => (
                <MenuItem key={option[0]} value={option[0]}>
                  {option[1]}
                </MenuItem>
              ))}
            </TextField>
            {rowCell.content[0]?.outlierReasonDropdown === 'Other' && (
              <BaleenOutlierReasonTextField
                batchId={id as number}
                variationId={rowCell.content[0]?.variationId}
                updateOutlierReasonTextFieldExternal={handleTextFieldChange}
                outlierReasonTextField={
                  rowCell.content[0]?.outlierReasonTextField
                }
                disabled={rowCell.content[0]?.disabled || areTableRowsDisabled}
                setIsSaveBtnDisabled={setIsSaveBtnDisabled}
                error={rowCell.content[0]?.hasOutlierReasonTextFieldError}
              />
            )}
          </>
        )
      case CellBehavior.Undo:
        return displayButtonClickable(
          id,
          undefined,
          undefined,
          undefined,
          'Undo'
        )
      case CellBehavior.EditOutlined:
        return displayButtonClickable(
          id,
          undefined,
          undefined,
          undefined,
          'Edit'
        )
      case CellBehavior.Close:
        return displayButtonClickable(
          id,
          undefined,
          undefined,
          undefined,
          'Close'
        )
      case CellBehavior.StyledText:
        return (
          <div style={rowCell.content[0].style}>{rowCell.content[0].text}</div>
        )
      case CellBehavior.TruncatedArrayWithTooltip: {
        const tooltipTitle = rowCell.content[0].tooltipTitle
        const cellData = rowCell.content[0].data

        return (
          <Box>
            <span>{cellData.cellData}</span>
            {cellData.cellPlusItem && (
              <Tooltip arrow title={tooltipTitle}>
                <span className={classes.echoCementTablePlusItem}>
                  {cellData.cellPlusItem}
                </span>
              </Tooltip>
            )}
          </Box>
        )
      }

      default:
        return null
    }
  }

  const displayCell = (
    rowCell:
      | string
      | number
      | SimpleTableComplexCell
      | null
      | undefined
      | Array<string>,
    id: string | number,
    key: string = ''
  ) => {
    if (rowCell === null) return null

    if (typeof rowCell === 'string') {
      return handleStringCell(rowCell, key)
    }

    if (typeof rowCell === 'number') {
      return rowCell
    }

    if (typeof rowCell === 'object') {
      return handleObjectCell(rowCell, id)
    }

    return null
  }

  const handleStringCell = (rowCell: string, key: string) => {
    return tableType === SimpleTableTypes.MaterialManager
      ? getMaterialCellString(key, rowCell)
      : rowCell
  }

  const handleObjectCell = (
    rowCell: SimpleTableComplexCell | Array<string>,
    id: string | number
  ) => {
    if (tableType === SimpleTableTypes.MaterialManager) {
      return handleMaterialManagerCell(rowCell, id)
    }

    if (tableType === SimpleTableTypes.EchoCementTable) {
      return handleEchoCementTableCell(rowCell, id)
    }

    //@ts-ignore (For FUTURE RESOLUTION)
    return processBehaviour(rowCell, id)
  }

  const handleMaterialManagerCell = (
    rowCell: SimpleTableComplexCell | Array<string>,
    id: string | number
  ) => {
    if (Array.isArray(rowCell)) {
      return rowCell.join(', ')
    }

    return processBehaviour(rowCell, id)
  }

  /**
   * Handle the array string and display the tooltip
   * @param rowCell
   * @param id
   */
  const handleEchoCementTableCell = (
    rowCell: SimpleTableComplexCell | Array<string>,
    id: string | number
  ) => {
    if (Array.isArray(rowCell)) {
      const moreThanOne = rowCell.length > 1

      const newData = moreThanOne
        ? { cellData: `${rowCell[0]}`, cellPlusItem: `+${rowCell.length - 1}` }
        : { cellData: `${rowCell[0]}`, cellPlusItem: null }

      const truncatedItem: SimpleTableComplexCell = {
        behavior: CellBehavior.TruncatedArrayWithTooltip,
        content: [
          {
            tooltipTitle: moreThanOne ? rowCell.join(', ') : '',
            data: newData,
          },
        ],
      }

      return processBehaviour(truncatedItem, id)
    }

    return processBehaviour(rowCell, id)
  }

  const getOrderDirection = (headCellId: string) => {
    return orderBy === headCellId ? order : 'asc'
  }

  const getCollapsibleRows = (component: string, row: SimpleTableRow) => {
    if (
      component === SimpleTableTypes.AddDataMixGroup ||
      component === SimpleTableTypes.ViewDesignMixGroup
    ) {
      return (
        <MixVariationCollapsibleRow
          row={row}
          displaySelectors={displaySelectors}
          displayCell={displayCell}
          headCells={headCells}
          parentClasses={classes}
          selected={selected}
        />
      )
    }
    if (
      component === SimpleTableTypes.MaterialManager &&
      materialCards?.length &&
      setCurrentView
    ) {
      return (
        <MaterialTableCollapsibleRow
          roles={roles}
          row={row}
          materialCards={materialCards}
          displayCell={displayCell}
          setCurrentView={setCurrentView}
          handleEditMaterial={handleEditMaterial}
          setIsFilterPanelOpen={setIsFilterPanelOpen}
        />
      )
    }
    if (component === SimpleTableTypes.EchoCementTable) {
      return <CementTableCollapsibleRow row={row} displayCell={displayCell} />
    }
  }

  interface BaleenRowHelperVariables {
    outlierType: BaleenOutlierType
  }
  const getBodyRowClass = (helperVariables: BaleenRowHelperVariables) => {
    if (
      tableType === SimpleTableTypes.BaleenOutliersTable &&
      helperVariables.outlierType === null
    ) {
      return classes['baleenBodyRow']
    }
  }

  const getDivClassName = row => {
    if (
      (tableType === SimpleTableTypes.BaleenSamplesTable &&
        !row.cells[2].content[0].isSample) ||
      (tableType === SimpleTableTypes.BaleenOutliersTable &&
        !row.helperVariables.isSample)
    ) {
      return 'baleen-row-open'
    } else if (
      (tableType === SimpleTableTypes.BaleenSamplesTable &&
        row.cells[2].content[0].isSample) ||
      (tableType === SimpleTableTypes.BaleenOutliersTable &&
        row.helperVariables.isSample)
    ) {
      return 'baleen-row-close'
    } else return
  }

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="medium"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {headCells.map(headCell => (
                <Tooltip key={headCell.id} arrow title={headCell.tooltip || ''}>
                  <TableCell
                    sortDirection={orderBy === headCell.id ? order : undefined}
                    className={classes[headCell.id]}
                  >
                    {headCell.sortable && onSort ? (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={getOrderDirection(headCell.id)}
                        onClick={event => onSort(event, headCell.id)}
                      >
                        {headCell.name}
                      </TableSortLabel>
                    ) : (
                      headCell.name
                    )}
                  </TableCell>
                </Tooltip>
              ))}
            </TableRow>
          </TableHead>
          {tableType === SimpleTableTypes.BaleenOutliersTable ||
          tableType === SimpleTableTypes.BaleenSamplesTable ? (
            <TransitionGroup component="tbody">
              {rows.map(row => (
                <CSSTransition key={row.id} timeout={500} classNames="fade">
                  <TableRow
                    hover={enableRowHover}
                    selected={selected.includes(row.id)}
                    className={getBodyRowClass(row.helperVariables)}
                  >
                    {addFirstColumn && (
                      <TableCell
                        padding="checkbox"
                        className={classes.dropdownCol}
                      >
                        <div style={{ display: 'inline-block', width: '28px' }}>
                          {isExpandable &&
                            displayExpanders(row.id, row, tableType)}
                        </div>
                        {displaySelectors(row.id)}
                      </TableCell>
                    )}
                    {row.cells.map((rowCell, index) => (
                      <TableCell
                        className={`${classes.tableCell} ${
                          addFirstColumn
                            ? classes[headCells[index + 1].id]
                            : classes[headCells[index].id]
                        }`}
                        key={
                          addFirstColumn
                            ? parentClasses[headCells[index + 1].id]
                            : parentClasses[headCells[index].id]
                        }
                        style={{ padding: 0 }}
                      >
                        <div className={getDivClassName(row)}>
                          {displayCell(rowCell, row.id)}
                        </div>
                      </TableCell>
                    ))}
                  </TableRow>
                </CSSTransition>
              ))}
            </TransitionGroup>
          ) : (
            <TableBody>
              {rows.map(row => (
                <React.Fragment key={row.id}>
                  <TableRow
                    hover={enableRowHover}
                    selected={selected.includes(row.id)}
                  >
                    {addFirstColumn && (
                      <TableCell
                        padding={
                          tableType === SimpleTableTypes.MaterialManager
                            ? 'default'
                            : 'checkbox'
                        }
                        className={classes.dropdownCol}
                      >
                        <div style={{ display: 'inline-block', width: '28px' }}>
                          {isExpandable &&
                            displayExpanders(row.id, row, tableType)}
                        </div>
                        {displaySelectors(row.id)}
                      </TableCell>
                    )}
                    {row.cells.map((rowCell, index) => (
                      <TableCell
                        className={
                          addFirstColumn
                            ? classes[headCells[index + 1].id]
                            : classes[headCells[index].id]
                        }
                        key={
                          addFirstColumn
                            ? parentClasses[headCells[index + 1].id]
                            : parentClasses[headCells[index].id]
                        }
                      >
                        {displayCell(rowCell, row.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={headCells.length}
                      style={{ padding: 0, borderBottom: 'none' }}
                    >
                      <Collapse
                        in={isExpandable && expanded.includes(row.id)}
                        timeout="auto"
                        unmountOnExit
                      >
                        {getCollapsibleRows(tableType, row)}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {/* TS warning are ok, this is not going to be fixed in MUI v4 */}
      {hasPagination && enablePagination && (
        <SimpleTablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          count={rowCount}
          rowsPerPage={rowsPerPage}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </>
  )
}

SimpleTablePresentational.defaultProps = defaultProps

export default SimpleTablePresentational
