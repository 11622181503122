import React, { useCallback, useEffect, useState } from 'react'
import {
  IAtomCurrentCustomer,
  IDigestedMaterial,
  IMaterial,
  AddDefaultMaterialsType,
  IMixGroupOptionWithConditions,
  ICommissionReportSettings,
  IMixSelection,
  CommissionReportOutlierRow,
  VariationTypes,
  IReportMixSelectionSettings,
  testResultPropertiesKelownaId,
  NumericIntervals,
  DefaultFilterSectionExpanded,
  IUseGenerateCommissionReportPDF,
  ILibraryReport,
} from './Types'
import { isDefaultCO2, isDefaultCement } from './TSSLogic'
import { defaultCO2, defaultCement } from '../Constants/AddDataConstants'
import {
  getDivisionNames,
  postReportPDF,
} from '../../Common/Helpers/DataHelpers'
import {
  getCommissionReportPDFByReportId,
  getCompanyLogoByDivisionId,
  getMixGroupWithSamples,
  getReportMixGroupsByDivision,
} from '../Data/TSSDataHelpers'
import { ICustomerOption } from '../Views/BaleenView'
import {
  digestMixSelection,
  populateBarGraphIntervals,
  populateHistogramIntervals,
  populateVariationSettings,
  getTestResultsIntervalOptions,
  updateOutlierSettings,
  getOutlierRows,
} from '../Helpers/CommissionReportHelpers'
import cloneDeep from 'lodash.clonedeep'
import { IMixSelectionRow } from '../Views/CommissionReportGeneratorView'
import { defaultSelectedTestResultsProperties } from '../Constants/CommissionReportConstants'
import { drawDOM, exportPDF } from '@progress/kendo-drawing'
import { useSetRecoilState } from 'recoil'
import {
  atomErrorOpeningReport,
  atomReviewedReport,
} from '../../Common/tssAtomsB'
import { RouteComponentProps } from 'react-router-dom'

export interface IUseMixSelectionHookParams {
  reportSettings: ICommissionReportSettings
  setMixSelections: React.Dispatch<React.SetStateAction<IMixSelection[]>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  sendRequest: boolean
  setSendRequest: React.Dispatch<React.SetStateAction<boolean>>
  setReportSettings: React.Dispatch<
    React.SetStateAction<ICommissionReportSettings>
  >
  setMixSelectionRows: React.Dispatch<React.SetStateAction<IMixSelectionRow[]>>
  mixSelections: IMixSelection[]
  redirect: boolean
  history: RouteComponentProps
}

/**
 * This custom React Hook is used to control the state of the filter drawer.
 *
 * It will automatically open the filter drawer and expand the "customer" and "mixDetails" sections
 * whenever the divisionId array is empty and data loading is completed.
 *
 * @param {boolean} isLoading - Indicates if data is currently being loaded.
 * @param {boolean} isFirstRender - Indicates if this is the first render of the component.
 * @param {string[]} divisionId - Array of division IDs. If empty, the filter drawer will be opened.
 * @param {function} setOpen - State setter function to open or close the filter drawer.
 * @param {function} setExpandFilters - State setter function to control which sections of the filter drawer are expanded.
 */
export function useFilterPanelOpen(
  isLoading: boolean,
  isFirstRender: boolean,
  divisionId: string[],
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setExpandFilters: React.Dispatch<
    React.SetStateAction<DefaultFilterSectionExpanded>
  >
  // setExpandFilters: React.Dispatch<React.SetStateAction<{ customer: boolean; mixDetails: boolean; samples: boolean }>>
) {
  useEffect(() => {
    if (!isLoading && !isFirstRender && !divisionId.length) {
      setOpen(true)
      // @ts-ignore
      setExpandFilters(prev => {
        if ('samples' in prev) {
          return {
            customer: true,
            mixDetails: false,
            samples: prev.samples,
          }
        } else {
          return {
            customer: true,
            mixDetails: false,
          }
        }
      })
    }
  }, [divisionId, isFirstRender, isLoading, setOpen, setExpandFilters])
}

/**
 * useAddDefaultMaterials Hook
 * 
 * This hook is responsible for checking and adding default materials (Cement and CO2) 
 * to a given list of materials.
 * @param {Array<IDigestedMaterial>} materials - The current list of materials.
 * @param {IAtomCurrentCustomer} currentCustomer - The current customer data which contains information about their divisions.
 * @param {SetMaterials} setMaterials - Setter function to update the list of materials.
 * @param {AddDefaultMaterialsType} type - The type which determines how materials should be added (e.g., AddBatch).

 * @returns {void}
 * 
 * - First, it checks whether default cement and CO2 materials are already present in the given list of materials.
 * - If not, it adds the default cement / default CO2 to the materials array.
 * - If the customer does not have any materials, both default cement and CO2 are added.
 * 
 * Note: The function employs a conditional approach based on the `type` parameter 
 * to decide how the default materials are added by using setMaterials function.
 */
export function useAddDefaultMaterials(
  materials: Array<IDigestedMaterial> | Array<IMaterial>,
  currentCustomer: IAtomCurrentCustomer,
  setMaterials: (prev: any) => void, // using any type for setting both add mix and add batch
  type: AddDefaultMaterialsType
) {
  useEffect(() => {
    const defaultCementExists = materials.some(material =>
      isDefaultCement(material)
    )
    const defaultCO2Exists = materials.some(material => isDefaultCO2(material))

    if (!defaultCementExists && materials.length) {
      type === AddDefaultMaterialsType.AddBatch
        ? setMaterials(prev => ({
            ...prev,
            materials: [...prev.materials, defaultCement],
          }))
        : setMaterials(prev => [...prev, defaultCement])
    }

    if (!defaultCO2Exists && materials.length) {
      type === AddDefaultMaterialsType.AddBatch
        ? setMaterials(prev => ({
            ...prev,
            materials: [...prev.materials, defaultCO2],
          }))
        : setMaterials(prev => [...prev, defaultCO2])
    }

    //If the customer does not have any materials
    if (!materials.length && currentCustomer.division?.divisionId) {
      type === AddDefaultMaterialsType.AddBatch
        ? setMaterials(prev => ({
            ...prev,
            materials: [...prev.materials, defaultCement, defaultCO2],
          }))
        : setMaterials([defaultCement, defaultCO2])
    }
  }, [setMaterials, materials, currentCustomer.division?.divisionId, type])
}

/**
 * useCustomerOptions Hook
 *
 * Custom React hook for managing and fetching customer options.
 *
 * @param {Array<ICustomerOption>} customerOptions - The current list of customer options.
 * @param {(arg0: Array<ICustomerOption>) => void} setCustomerOptions - Function to set customer options.
 * @param {(arg0: boolean) => void} setIsLoading - Function to set loading state.
 *
 * @returns {void}
 *
 * - If the current list of customer options is empty, it fetches division names.
 * - It sets loading state to true before making the API call.
 * - Once the API call is successful, it sets the customer options and updates the loading state.
 * - If there is an error during the fetch, it logs the error.
 */
export function useCustomerOptions(
  customerOptions: Array<ICustomerOption>,
  setCustomerOptions: (arg0: []) => void,
  setIsLoading: (arg0: boolean) => void
) {
  useEffect(() => {
    if (customerOptions.length === 0) {
      setIsLoading(true)
      getDivisionNames(true)
        .then(res => {
          if (res.ok) {
            res
              .json()
              .then(data => {
                setCustomerOptions(data)
                setIsLoading(false)
              })
              .catch(e => console.log(e))
          } else {
            console.error('Failed to fetch division names:', res.statusText)
          }
        })
        .catch(e => console.error('Error during division names fetch:', e))
    }
  }, [customerOptions, setCustomerOptions, setIsLoading])
}

/**
 * useMixGroupOptions Hook
 *
 * Custom React hook for fetching and managing Mix Group options based on the provided current customer information.
 *
 * @param {IAtomCurrentCustomer} currentCustomer - The current customer information, including divisionId and plantId.
 * @param {(arg0: Array<IMixGroupOptionWithConditions>) => void} setMixGroupOptions - Function to set Mix Group options.
 * @param {(arg0: boolean) => void} setIsLoading - Function to set loading state.
 *
 * @returns {void}
 *
 * - If the current customer plant information is available, it fetches Mix Group options for the specified plant.
 * - It sets loading state to true before making the API call.
 * - Once the API call is successful, it converts and sorts the Mix Group options by mixCode.
 * - The sorted options are then set using setMixGroupOptions, and the loading state is updated.
 */
export function useMixGroupOptions(
  currentCustomer: IAtomCurrentCustomer,
  setMixGroupOptions: (arg0: Array<IMixGroupOptionWithConditions>) => void,
  setIsLoading: (arg0: boolean) => void,
  onlyIncludeReportMixGroups: boolean = false
) {
  const convertAndSortOptions = (
    data: any
  ): IMixGroupOptionWithConditions[] => {
    const convertedOptions: IMixGroupOptionWithConditions[] = data.map(
      (option: any) => ({
        id: option.mixDesignId,
        name: option.mixCode,
        mixCode: option.mixCode,
        baseLineCementReduction: option.baseLineCementReduction,
        baseLineCO2: option.baseLineCO2,
      })
    )

    return [...convertedOptions].sort((a, b) =>
      a.mixCode.localeCompare(b.mixCode)
    )
  }

  useEffect(() => {
    if (
      !currentCustomer.division?.divisionId ||
      !currentCustomer.plant?.plantId
    )
      return
    setIsLoading(true)
    const constraints = {
      plantId: currentCustomer.plant.plantId,
      onlyIncludeReportMixGroups: onlyIncludeReportMixGroups,
    }
    getReportMixGroupsByDivision(
      currentCustomer.division.divisionId,
      constraints
    )
      .then(res => {
        if (res.ok) {
          res.json().then(data => {
            const options = convertAndSortOptions(data)
            setMixGroupOptions(options)
            setIsLoading(false)
          })
        }
      })
      .catch(e => console.error(e))
  }, [
    currentCustomer.division?.divisionId,
    currentCustomer.plant?.plantId,
    onlyIncludeReportMixGroups,
    setIsLoading,
    setMixGroupOptions,
  ])
}

/**
 * Custom hook to fetch mix selection data based on commission report settings.
 * This hook fetches mix selection data for each mix design specified in the commission report settings
 * and updates the state with the fetched mix selections.
 *
 * @param {IUseMixSelectionHookParams} params The parameters for this hook. Includes the following:
 * reportSettings - The commission report settings containing mix design ids and their settings.
 * setMixSelections - React state setter for mix selections.
 * setIsLoading - React state setter for loading indicator.
 * sendRequest - Whether or not to send a request to get mix information
 * setSendRequest - React state setter for whether or not we should send a request to get mix information.
 * setReportSettings - React state setter for commission report settings.
 * setMixSelectionRows - React state setter for mix selection rows.
 * mixSelections - Array of digested mix selections.
 * redirect - Whether the link is invalid or not
 */
export const useMixSelectionData = (params: IUseMixSelectionHookParams) => {
  const {
    reportSettings,
    setMixSelections,
    setIsLoading,
    sendRequest,
    setSendRequest,
    setReportSettings,
    setMixSelectionRows,
    mixSelections,
    redirect,
    history,
  } = params

  const setShowErrorOpeningReportModal = useSetRecoilState(
    atomErrorOpeningReport
  )

  useEffect(() => {
    if (
      reportSettings?.mixDesignSettings.length === 0 ||
      !sendRequest ||
      redirect
    )
      return
    setIsLoading(true)
    const reportSettingsCopy = cloneDeep(reportSettings)
    const selectedMixDesignIds = reportSettings.mixDesignSettings.map(
      mixDesignSetting => mixDesignSetting.mixDesignId
    )
    const mixDesignsToRequest = []
    // newDigestedMixDesigns will only hold newly selected mix designs. indices that hold previously digested mix designs will be empty.
    const newDigestedMixDesigns: IMixSelection[] = Array(
      selectedMixDesignIds.length
    )
    /**
     * this mix design index helper variable keeps track of which mixSelection indices contain digested mix designs.
     * a value of null at an index means the newly selected mix at this index (matching the UI order) has not been digested yet.
     * a numeric value (mix design id) means the mix at this index has been digested
     * */

    const mixDesignIndexHelper: (number | null)[] = []

    // loop through the selected mix design ids
    for (const id of selectedMixDesignIds) {
      const mixDesignSettings = reportSettings.mixDesignSettings.find(
        mix => mix.mixDesignId === id
      )
      // Prepare to request a information on a mix design if
      // 1. the report settings has no baseline or carboncure variation for that mix currently or
      // 2. there are no mix selections stored in state (Should only be the case at the very beginning.
      if (
        (!mixDesignSettings?.baselineVariation &&
          !mixDesignSettings?.carbonCureVariation) ||
        mixSelections.length === 0
      ) {
        mixDesignsToRequest.push(id)
        mixDesignIndexHelper.push(null)
      } else {
        mixDesignIndexHelper.push(id)
      }
    }

    const mixSelectionRows: IMixSelectionRow[] = []
    const abortController = new AbortController()

    Promise.all(
      mixDesignsToRequest.map(mixDesignId => {
        return getMixGroupWithSamples(mixDesignId, {}, {}, abortController)
      })
    ).then(results => {
      results.forEach(result => {
        if (result.ok) {
          result.json().then(data => {
            let allMixesDigested = true
            const digestedMix = digestMixSelection(data, reportSettingsCopy)
            // find the index position that should hold this newly digested mix in order to match UI
            const matchedIdx = selectedMixDesignIds.findIndex(
              id => digestedMix.mixDesignId === id
            )

            mixDesignIndexHelper[matchedIdx] = digestedMix.mixDesignId

            newDigestedMixDesigns[matchedIdx] = digestedMix

            const baselineVariation = digestedMix.digestedVariations.find(
              variation =>
                variation.orcaVariationType === VariationTypes.BASELINE
            )
            const carbonCureVariation = digestedMix.digestedVariations.find(
              variation =>
                variation.orcaVariationType === VariationTypes.OPTIMIZED
            )
            const mixDesignSettings = reportSettingsCopy.mixDesignSettings.find(
              (settings: IReportMixSelectionSettings) =>
                settings.mixDesignId === digestedMix.mixDesignId
            )
            if (
              !baselineVariation ||
              !carbonCureVariation ||
              !mixDesignSettings
            ) {
              setIsLoading(false)
              setShowErrorOpeningReportModal(true)
              history.push('/Producers/ReportLibrary')
              return
            }

            populateVariationSettings(
              digestedMix.mixDesignId,
              mixDesignSettings,
              baselineVariation,
              carbonCureVariation
            )
            populateBarGraphIntervals(
              mixDesignSettings.avgStrengthGraph,
              digestedMix.intervalOptions,
              digestedMix.specifiedMaturityAge
            )
            populateHistogramIntervals(
              mixDesignSettings.frequencyGraph,
              digestedMix.intervalOptions,
              digestedMix.specifiedMaturityAge
            )
            updateOutlierSettings(digestedMix, mixDesignSettings)

            // if every index in the mixDesignIndexHelper variable has a mix design id, all mixes have been digested.
            for (const element of mixDesignIndexHelper) {
              if (element === null) {
                allMixesDigested = false
                break
              }
            }

            if (allMixesDigested) {
              for (let i = 0; i < mixDesignIndexHelper.length; i++) {
                mixSelectionRows.push({
                  id: i,
                  mixDesignId: mixDesignIndexHelper[i],
                })
              }
              setSendRequest(false)
              setMixSelections(prevMixSelections => {
                const newMixSelections = []
                for (let i = 0; i < mixDesignIndexHelper.length; i++) {
                  // if the value at the index is empty, this mix was previously digested and is not new. use the previous state's value.
                  if (!newDigestedMixDesigns[i]) {
                    newMixSelections.push(prevMixSelections[i])
                  } else {
                    newMixSelections.push(newDigestedMixDesigns[i])
                  }
                }
                return newMixSelections
              })
              setMixSelectionRows([...mixSelectionRows])
              setReportSettings(reportSettingsCopy)
              setIsLoading(false)
            }
          })
        } else {
          console.error(result)
          setSendRequest(false)
          setIsLoading(false)
        }
      })
    })

    return () => {
      abortController.abort()
    }
  }, [
    reportSettings,
    sendRequest,
    mixSelections,
    setSendRequest,
    setMixSelections,
    setIsLoading,
    setMixSelectionRows,
    setReportSettings,
    redirect,
    history,
    setShowErrorOpeningReportModal,
  ])
}

/**
 * Custom hook to manage outlier rows and update report settings based on mix selections and tab number.
 * @param mixSelections An array of digested mix selections.
 * @param tabValue The index of the currently selected tab default at 0.
 * @param setOutlierRows A function to update the outlier rows state.
 * @param setReportSettings A function to update the report settings state.
 */
export const useOutlierRows = (
  mixSelections: IMixSelection[],
  tabValue: number,
  setOutlierRows: React.Dispatch<
    React.SetStateAction<CommissionReportOutlierRow[]>
  >,
  setReportSettings: React.Dispatch<
    React.SetStateAction<ICommissionReportSettings>
  >
) => {
  useEffect(() => {
    if (!mixSelections.length) return

    const mixDesignId = mixSelections?.[tabValue]?.mixDesignId
    const baselineOutliers = getOutlierRows(
      mixSelections[tabValue],
      VariationTypes.BASELINE
    )
    const carbonCureOutliers = getOutlierRows(
      mixSelections[tabValue],
      VariationTypes.OPTIMIZED
    )
    const allOutliers = [...baselineOutliers, ...carbonCureOutliers]

    // Update outlier rows state if outliers exist, otherwise update report settings
    setOutlierRows(allOutliers)
    if (!allOutliers.length) {
      setReportSettings(prevSettings => {
        const newSettings = { ...prevSettings }
        const matchedMixDesignSettings = newSettings?.mixDesignSettings.find(
          settings => settings.mixDesignId === mixDesignId
        )
        if (!matchedMixDesignSettings) return prevSettings
        matchedMixDesignSettings.outlierSettings = {
          show: false,
          showReason: false,
        }
        return newSettings
      })
    }
  }, [mixSelections, tabValue, setOutlierRows, setReportSettings])
}

/**
 * Custom hook to update test results property options based on interval options and report settings.
 *
 * @param mixSelection The mix selection object.
 * @param reportSettings The report settings object.
 * @param tabValue The index of the currently selected tab default at 0.
 */
export const useTestResultsPropertyOptions = (
  mixSelection: IMixSelection,
  reportSettings: ICommissionReportSettings,
  tabValue: number,
  isCO2DosagePercentage?: boolean
) => {
  useEffect(() => {
    if (!mixSelection) return

    // Clear out existing strength options first (strength's kelownaId is a number while others' is a string)
    mixSelection.testResultsPropertyOptions =
      mixSelection.testResultsPropertyOptions?.filter(
        option => typeof option.kelownaId === 'string'
      ) || []

    // Update CO2 Dose Label if the unit is percentage
    if (isCO2DosagePercentage && mixSelection.testResultsPropertyOptions.length)
      mixSelection.testResultsPropertyOptions[0].labelWithUnit =
        'CO₂ Dose. (% bwc)'

    const intervalOptions = getTestResultsIntervalOptions(
      reportSettings,
      tabValue
    )
    const unit = reportSettings?.isMetric ? 'MPa' : 'psi'

    for (const interval of intervalOptions) {
      let optionId = ''
      let optionLabel = ''
      let optionLabelWithUnit = ''

      if (interval > 0 && interval < 24) {
        optionId = interval + 'StrengthHours'
        optionLabel = interval + ' Hour Strength'
        optionLabelWithUnit = `${optionLabel} (${unit})`
      } else if (interval > 23) {
        optionId = interval + 'StrengthHours'
        optionLabel = interval / 24 + ' Day Strength'
        optionLabelWithUnit = `${optionLabel} (${unit})`
      }

      mixSelection.testResultsPropertyOptions?.push({
        id: optionId,
        kelownaId: interval,
        label: optionLabel,
        labelWithUnit: optionLabelWithUnit,
        type: 'strength',
      })

      mixSelection.testResultsPropertyOptions?.push({
        id: optionId + 'StDev',
        kelownaId: interval,
        label: optionLabel + ' St. Dev.',
        labelWithUnit: `${optionLabel} St. Dev. (${unit})`,
        type: 'standardDeviation',
      })
    }
  }, [mixSelection, reportSettings, tabValue, isCO2DosagePercentage])
}

/**
 * Custom hook to set default selected test results properties if none are selected initially.
 *
 * @param allSelectedPropertiesArrayLength The length of the allSelectedProperties array.
 * @param selectedTestResultsInterval The selected test results interval.
 * @param tabValue The index of the currently selected tab default at 0.
 * @param setReportSettings A function to update the report settings state.
 */

export const useDefaultSelectedTestResultsProperties = (
  allSelectedPropertiesArrayLength: number,
  selectedTestResultsInterval: NumericIntervals,
  tabValue: number,
  setReportSettings: React.Dispatch<
    React.SetStateAction<ICommissionReportSettings>
  >
) => {
  useEffect(() => {
    if (!allSelectedPropertiesArrayLength) {
      setReportSettings(prevSettings => {
        const newSettings = cloneDeep(prevSettings) as ICommissionReportSettings
        const mixDesignSettings = newSettings.mixDesignSettings[tabValue]

        const updatedTestResultsSettings = {
          ...mixDesignSettings.testResults,
          selectedProperties: defaultSelectedTestResultsProperties as testResultPropertiesKelownaId[],
          selectedStrengthHours: [
            selectedTestResultsInterval,
          ] as NumericIntervals[],
          selectedStrengthStDevHours: [
            selectedTestResultsInterval,
          ] as NumericIntervals[],
        }
        mixDesignSettings.testResults = updatedTestResultsSettings
        return newSettings
      })
    }
  }, [
    allSelectedPropertiesArrayLength,
    tabValue,
    setReportSettings,
    selectedTestResultsInterval,
  ])
}

/**
 * Custom hook to fetch the company logo URL based on the report settings
 * producerId and set the company logo URL in state.
 *
 * @param {ICommissionReportSettings} reportSettings The report settings object.
 * @param {React.Dispatch<React.SetStateAction<string>>} setCompanyLogoUrl A function to update the company logo URL state.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsLoading A function to update the isLoading state.
 */
export const useCompanyLogo = (
  reportSettings: ICommissionReportSettings,
  setCompanyLogoUrl: React.Dispatch<React.SetStateAction<string>>,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (!reportSettings?.producerId) return

    setIsLoading(true)
    const getCompanyLogo = async () => {
      try {
        const response = await getCompanyLogoByDivisionId(
          reportSettings.producerId
        )
        if (response.ok) {
          const blob = await response.blob()
          const imageUrl = URL.createObjectURL(blob)
          setCompanyLogoUrl(imageUrl)
          setIsLoading(false)
        }
      } catch (err) {
        setIsLoading(false)
        console.log(err)
      }
    }
    getCompanyLogo()
  }, [reportSettings.producerId, setCompanyLogoUrl, setIsLoading])
}

export const useGenerateCommissionReportPDF = (
  props: IUseGenerateCommissionReportPDF
) => {
  const {
    history,
    isPrinting,
    setIsPrinting,
    pdfExportComponent,
    setReportSettings,
    pdfTemplate,
    setIsCommissionReportViewMode,
    reportId,
    isCommissionReportViewMode,
    divisionName,
    reviewedReportModalMixInfo,
    setIsReviewErrorModalOpen,
  } = props

  const setReviewedReportModalInfo = useSetRecoilState(atomReviewedReport)

  const generatePDF = useCallback(async () => {
    if (pdfExportComponent.current) {
      try {
        const group = await drawDOM(pdfExportComponent.current, {
          paperSize: 'Letter',
          template: pdfTemplate,
          forcePageBreak: '.page-break',
          scale: 0.45,
          margin: {
            top: '0.5cm',
            left: '1cm',
            right: '1cm',
            bottom: '0.5cm',
          },
        })
        const dataUri = await exportPDF(group, { paperSize: 'Letter' })
        const byteCharacters = atob(dataUri.split(',')[1])
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512)

          const byteNumbers = new Array(slice.length)
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
          }

          const byteArray = new Uint8Array(byteNumbers)
          byteArrays.push(byteArray)
        }
        const blob = new Blob(byteArrays, { type: 'application/pdf' })
        const formData = new FormData()
        formData.append('file', blob)

        const res = await postReportPDF(reportId, formData)
        if (res.ok) {
          setIsPrinting(false)
          setReviewedReportModalInfo(prevState => ({
            ...prevState,
            reportId: Number(reportId),
            divisionName: divisionName,
            mixes: reviewedReportModalMixInfo,
          }))
          history.push('/Producers/ReportLibrary')
        } else {
          const errorResponse = await res.json()
          throw new Error(errorResponse.error)
        }
      } catch (err) {
        console.error(err)
        setIsPrinting(false)
        setReportSettings(prevSettings => ({
          ...prevSettings,
          reportStatus: 'PendingReview',
        }))
        setIsCommissionReportViewMode(false)
        setIsReviewErrorModalOpen(true)
      }
    }
  }, [
    history,
    pdfTemplate,
    pdfExportComponent,
    setIsCommissionReportViewMode,
    setReportSettings,
    setIsPrinting,
    reportId,
    divisionName,
    reviewedReportModalMixInfo,
    setReviewedReportModalInfo,
    setIsReviewErrorModalOpen,
  ])

  useEffect(() => {
    if (!isPrinting || !isCommissionReportViewMode || !reportId) return
    // provide time for highchart graphs to finish drawing before generating PDF
    const timer = setTimeout(() => {
      generatePDF()
    }, 1500)
    return () => {
      clearTimeout(timer)
    }
  }, [isPrinting, isCommissionReportViewMode, generatePDF, reportId])
}

/**
 * Custom hook to handle the preview of commissioining reports in PDF format.
 *
 * @param {ILibraryReport[]} reports - Array of reports in report library table.
 * @returns {object} An object containing:
 * - isLoading: Boolean state to manage the loading indicator during the PDF fetch process.
 * - pdfData: The PDF data as an object URL string.
 * - selectedReport: The currently selected report.
 * - previewOpen: Boolean state to manage the visibility of the preview modal.
 * - handlePreviewClick: Function to handle the click event for previewing a report.
 * - handlePreviewModalClose: Function to handle the closing of the preview modal.
 */
export const usePreviewReport = (reports: ILibraryReport[]) => {
  const [pdfData, setPDFData] = useState<string>('')
  const [selectedReport, setSelectedReport] = useState<ILibraryReport>()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handlePreviewClick = useCallback(
    async (reportId: number) => {
      try {
        setIsLoading(true)
        const response = await getCommissionReportPDFByReportId(reportId)
        if (response.ok) {
          const blob = await response.blob()
          const pdfData = URL.createObjectURL(blob)
          setPDFData(pdfData)

          const selectedReport = reports.find(
            report => report.reportId === reportId
          )
          setSelectedReport(selectedReport)
          setPreviewOpen(true)
        } else {
          console.log('Error', response.statusText)
        }
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [reports]
  )

  const handlePreviewModalClose = (
    _event: React.SyntheticEvent,
    reason: string
  ) => {
    // This will prevent the preview modal from being closed if the background/backdrop is clicked
    if (reason !== 'backdropClick') {
      setPreviewOpen(false)
      setPDFData('')
      setSelectedReport(undefined)
    }
  }

  return {
    isLoading,
    pdfData,
    selectedReport,
    previewOpen,
    handlePreviewClick,
    handlePreviewModalClose,
  }
}

export const useBaleenFilterPanelOpen = (
  isLoading: boolean,
  isFirstRender: boolean,
  mixGroup: number[],
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setExpandFilters: React.Dispatch<
    React.SetStateAction<DefaultFilterSectionExpanded>
  >
) => {
  useEffect(() => {
    if (!isLoading && !isFirstRender && !mixGroup.length) {
      setOpen(true)
      setExpandFilters(prev => {
        return {
          ...prev,
          customer: true,
          mixDesign: true,
        }
      })
    }
  }, [isLoading, isFirstRender, mixGroup, setOpen, setExpandFilters])
}
