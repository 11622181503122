import { TextField } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import React from 'react'
import hook from './useSonarSnoozeReasonDetails'

interface IDownSystemSnoozeReasonDetails {
  reasonDetails: string
  parentClasses: ClassNameMap
  handleReasonDetailsChange: (reasonDetails: string) => void
}

const DownSystemSnoozeReasonDetails = (
  props: IDownSystemSnoozeReasonDetails
) => {
  const { reasonDetails, parentClasses, handleReasonDetailsChange } = props

  const {
    reasonDetailsInternal,
    handleInternalReasonDetailsChange,
  } = hook.useSonarSnoozeReasonDetails({
    reasonDetails,
    handleReasonDetailsChange,
  })

  return (
    <TextField
      size="small"
      variant="outlined"
      placeholder="Please provide reason details here*"
      className={parentClasses.textField}
      value={reasonDetailsInternal ?? null}
      onChange={handleInternalReasonDetailsChange}
    />
  )
}

export default DownSystemSnoozeReasonDetails
