import React, { Ref } from 'react'
import {
  Button,
  Grid,
  Typography,
  Divider,
  TextField,
  Backdrop,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core'
import {
  MaterialObject,
  CustomerOptions,
  HandleChangeEvent,
  HandleChangeValue,
  IMaterialTypeMetadata,
  MaterialDataType,
  MaterialKey,
  MaterialObjectKeys,
  MaterialType,
  MaterialExceptionType,
  IMaterial,
  MaterialRequestType,
  IAliasUpdate,
  CurrentPlantCompositions,
  MaterialManagerComponent,
  PlantComposition,
} from '../../Logic/Types'
import { Add } from '@material-ui/icons'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { baseColors } from '../../../theme/colors'
import { Autocomplete } from '@material-ui/lab'
import { tssCanWrite } from '../../Logic/TSSLogic'
import MaterialManagerModalLogical from '../MaterialManagerModal/MaterialManagerModalLogical'
import PlantCompositionCardLogical from '../PlantCompositionCard/PlantCompositionCardLogical'
import {
  convertCO2ToSubscript,
  convertToNameFormat,
  showMaterialAliasInputs,
} from '../../Helpers/MaterialManagerHelpers'
import ConditionalDeleteChip from '../ConditionalDeleteChip/ConditionalDeleteChip'
import { preventEventOnKeyDown } from '../../../Common/Helpers/GeneralHelpers'

const useStyles = makeStyles(theme => ({
  inputField: {
    minWidth: 240,
    maxWidth: 350,
    background: '#FFFFFF',
  },
  card: {
    backgroundColor: baseColors.table.background,
    padding: '1em',
  },
  cardHeader: {
    backgroundColor: baseColors.primary.twentySixMainOpacity,
    padding: '2px',
    marginRight: '6em',
  },
}))

interface IAddMaterialPresentational {
  /** Function that renders additional alias fields */
  addAliasField: () => void
  /** Tracks the amount of alias fields */
  aliasFields: number[]
  /** The customers that can be selected */
  customerOptions: CustomerOptions[]
  /** Standard handler for input fields */
  handleChange: (
    event: HandleChangeEvent,
    value: HandleChangeValue,
    keyName?: string
  ) => void
  /** Handler for alias inputs */
  handleAliasChange: (
    event: HandleChangeEvent,
    value: HandleChangeValue,
    index: number
  ) => void
  /** Handler for canceling material adding/editing */
  handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Handler for date change inputs */
  handleDateChange: (event: HandleChangeEvent) => void
  /** The new material data to be sent to Kelowna */
  materialPostObject: MaterialObject
  /** A function that changes the new material data to be sent to Kelowna */
  setMaterialPostObject: (arg0: MaterialObject) => void
  /** The material type that is currently selected */
  materialType: string
  /** The material type that is currently selected */
  setMaterialType: (arg0: string) => void
  /** The list of options for material types */
  materialTypeOptions: MaterialObject[]
  /** The list of options for material subtypes */
  materialSubTypeOptions: MaterialObject[]
  /** The list of options for Cement Suppliers */
  cementSupplierOptions: MaterialObject[]
  /** This list of plants based on the selected customer */
  plantOptions: MaterialObject[]
  /** The plants data of the current customer */
  plantData: PlantComposition[]
  /** Creates an array of plant options*/
  setPlantOptions: (arg0: MaterialObject[]) => void
  /** A function that checks an array of metadata keys to see which fields are default */
  isDefaultField: (arg0: IMaterialTypeMetadata) => boolean | undefined
  /** The filtered metadata based on the selected material type */
  materialHashMap: IMaterialTypeMetadata[] | undefined
  /** For mapping. Gets a list of options from the "applicableValues" property in filtered metadata */
  getFieldOptions: (arg0: string) => string[]
  /** Helps get the value from object to display in field */
  getValueFromOption: (arg0: any[], arg1: string, arg2: string[]) => any[]
  /** Tracks the plants that are selected for the Cement Composition view */
  selectedPlants: string[]
  /** Creates an array of selected plants */
  setSelectedPlants: (arg0: string[]) => void
  /** Determines when the loading spinner will be shown */
  isLoading: boolean
  /** Clears POST object and reset fields */
  formReset: (arg0?: boolean) => void
  /** Determines if fields can be edited or just viewed */
  isEditMode: boolean
  /** The role(s) of the user. Taken from the JWT atom */
  roles: Array<string>
  /** An API call to Kelowna that POST/PUT data */
  handleSave: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Determines if modal is open */
  modalOpen: boolean
  /** Controls the opening of the modal */
  setModalOpen: (arg0: boolean) => void
  /** The type of the modal (Similar Material, Duplicate Material, etc.) */
  modalType: MaterialExceptionType | null
  /** Sets the type of the modal (Similar Material, Duplicate Material, etc.) */
  setModalType: (arg0: MaterialExceptionType) => void
  /** States containing material data for comparisons (Similar/Duplicate Modals) */
  existingMaterial: MaterialObject | null
  currentMaterial: MaterialObject | null
  /** The material data used for display on Success Modal */
  successData: MaterialObject | null
  setSuccessData: (arg0: MaterialObject | null) => void
  /** POST/PUT API call for submitting Material Data */
  sendMaterialData: (
    arg0: IMaterial | IAliasUpdate,
    arg1: MaterialRequestType,
    arg2?: boolean
  ) => void
  sendMaterialAndAliasData: (
    arg0: IMaterial,
    arg1: IAliasUpdate,
    arg2?: boolean
  ) => void
  /** yup Validation Hooks */
  register: Ref<any>
  handleSubmit: Function
  errors: MaterialObject
  /** The error data for duplicate aliases. Helps with alias field validation on Add Material Page */
  duplicateAliasFieldError: number[] | null
  /** The error data for duplicate aliases. Helps with alias field validation on Modals */
  duplicateAliasModalError: string[] | null | undefined
  /** The error data for duplicated aliases within the same material. Helps with alias field validation on Add Material Page  */
  duplicateMaterialAliasFieldError: number[] | null
  /** Sets the error data to help with alias field validation on modals */
  setDuplicateAliasModalError: (arg0: string[] | null) => void
  /** Resets current/existing material data */
  modalDataReset: () => void
  /** Manages Unsaved Changes Modal */
  setHasUserInput: (arg0: boolean) => void
  /** Determines if fields can be edited or just viewed */
  isDefaultMaterial: boolean | undefined
  /** Determines if the selected material is unclassified */
  isUnclassifiedMaterial?: boolean
  /** Sets the composition data used for the currentPlantCompositions property */
  setCementCompositionData: (arg0: CurrentPlantCompositions[]) => void
  /** Determines if save button is enabled */
  canSave: (
    materialType: string,
    isDefaultMaterial: boolean | undefined,
    materialPostObject: MaterialObject
  ) => boolean
  /** Changes the view between Add/Edit/View Materials */
  setCurrentView: (view: MaterialManagerComponent) => void
  /** Set the material to be viewed in Add/Edit view */
  setSelectedMaterial: (arg0: IMaterial) => void
  /** The number of rendered alias input fields  */
  setAliasFields: (arg0: number[]) => void
  /** An array containing each value typed in the alias fields */
  setAliasFieldValues: (arg0: string[]) => void
  /** Kelowna generic errors */
  errorMessage: string | undefined
}

function MaterialFieldsPresentational(props: IAddMaterialPresentational) {
  const {
    addAliasField,
    aliasFields,
    customerOptions,
    handleAliasChange,
    handleChange,
    handleCancel,
    materialPostObject,
    setMaterialPostObject,
    materialType,
    setMaterialType,
    materialTypeOptions,
    materialSubTypeOptions,
    cementSupplierOptions,
    plantOptions,
    plantData,
    isDefaultField,
    materialHashMap,
    getFieldOptions,
    getValueFromOption,
    setPlantOptions,
    selectedPlants,
    setSelectedPlants,
    isLoading,
    formReset,
    isEditMode,
    roles,
    handleSave,
    modalOpen,
    setModalOpen,
    modalType,
    setModalType,
    existingMaterial,
    currentMaterial,
    successData,
    setSuccessData,
    register,
    handleSubmit,
    errors,
    sendMaterialData,
    sendMaterialAndAliasData,
    duplicateAliasFieldError,
    duplicateAliasModalError,
    duplicateMaterialAliasFieldError,
    setDuplicateAliasModalError,
    modalDataReset,
    setHasUserInput,
    isDefaultMaterial,
    isUnclassifiedMaterial,
    setCementCompositionData,
    canSave,
    setSelectedMaterial,
    setCurrentView,
    setAliasFields,
    setAliasFieldValues,
    errorMessage,
  } = props

  const classes = useStyles()
  const isViewOnly = (isEditMode && !tssCanWrite(roles)) || isDefaultMaterial

  const renderNumericTextField = (meta: IMaterialTypeMetadata) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <TextField
          data-testid={`test-${meta.key}`}
          key={meta.key}
          id={meta.key}
          name={meta.kelownaKeyName}
          label={meta.displayName}
          value={materialPostObject[meta.kelownaKeyName as string]}
          variant="outlined"
          onChange={event => handleChange(event, event.target.value, meta.key)}
          size="small"
          className={classes.inputField}
          type="number"
          inputProps={{
            step: '0.01',
            min: '0',
          }}
          onKeyPress={event => {
            if (!/^[0-9.]*$/.test(event.key)) {
              event.preventDefault()
            }
          }}
          disabled={isViewOnly}
          error={!!errors[meta.kelownaKeyName as string]}
          helperText={errors[meta.kelownaKeyName as string]?.message}
          inputRef={register}
          InputProps={{
            endAdornment: !!errors[meta.kelownaKeyName as string] && (
              <InputAdornment position="end">
                <ReportProblemOutlinedIcon color="error" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    )
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      {/* Typescript doesn't consider zIndex prop to be valid when used with a string. Forcing error ignore */}
      {/* @ts-ignore */}
      <Backdrop open={isLoading} style={{ zIndex: '50' }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container direction="row" spacing={4}>
        {/* Render Company Name field.  */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            data-testid={`test-${MaterialKey.DIVISIONAME}`}
            id={MaterialKey.DIVISIONAME}
            options={customerOptions}
            value={getValueFromOption(customerOptions, 'name', [
              MaterialObjectKeys.DivisionName,
            ])}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            onChange={(_, newValue) => {
              setSelectedPlants([])
              setCementCompositionData([])
              setMaterialPostObject((prevItems: MaterialObject) => ({
                ...prevItems,
                [MaterialObjectKeys.DivisionName]: newValue
                  ? newValue.name
                  : '',
                [MaterialObjectKeys.DivisionId]: newValue
                  ? newValue.divisionId
                  : '',
              }))
              !newValue && setPlantOptions([])
            }}
            size="small"
            className={classes.inputField}
            renderInput={params => (
              <TextField
                {...params}
                label={'Company Name'}
                variant="outlined"
                required
              />
            )}
            disabled={isEditMode}
          />
        </Grid>
        {/* Render Material Type field */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Autocomplete
            data-testid={`test-${MaterialKey.PRIMARYMATERIALTYPE}`}
            id={MaterialKey.PRIMARYMATERIALTYPE}
            options={materialTypeOptions}
            value={getValueFromOption(materialTypeOptions, 'option', [
              MaterialObjectKeys.PrimaryMaterialType,
            ])}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => convertCO2ToSubscript(option.name)}
            onChange={(_, newValue) => {
              setHasUserInput(true)
              setMaterialPostObject((prevItems: MaterialObject) => ({
                ...prevItems,
                [MaterialObjectKeys.MaterialType]: '',
                [MaterialObjectKeys.PrimaryMaterialType]: newValue
                  ? newValue.option
                  : '',
                [MaterialObjectKeys.CementSupplier]: null,
              }))
              setSelectedPlants([])
              setCementCompositionData([])
              setMaterialType(newValue ? newValue.name : '')
              !newValue && formReset(true)
            }}
            size="small"
            className={classes.inputField}
            renderInput={params => (
              <TextField
                {...params}
                label={'Material Type'}
                variant="outlined"
                required
              />
            )}
            renderOption={option => {
              return convertCO2ToSubscript(option.name)
            }}
            disabled={isEditMode && !isUnclassifiedMaterial}
          />
        </Grid>
        {/* Render Material SubType (only when Material Type is selected) */}
        {materialType && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              data-testid={`test-${MaterialKey.MATERIALTYPE}`}
              id={MaterialKey.MATERIALTYPE}
              options={materialSubTypeOptions}
              value={getValueFromOption(materialSubTypeOptions, 'option', [
                MaterialObjectKeys.MaterialType,
              ])}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={option => convertCO2ToSubscript(option.name)}
              onChange={(_, newValue) => {
                setHasUserInput(true)
                setMaterialPostObject((prevItems: MaterialObject) => ({
                  ...prevItems,
                  [MaterialObjectKeys.MaterialType]: newValue
                    ? newValue.option
                    : '',
                }))
              }}
              size="small"
              className={classes.inputField}
              renderInput={params => (
                <TextField
                  {...params}
                  label={'Material Subtype'}
                  variant="outlined"
                  required
                />
              )}
              renderOption={option => {
                return convertCO2ToSubscript(option.name)
              }}
              disabled={isViewOnly}
            />
          </Grid>
        )}
        {materialType && materialType === MaterialType.CEMENT && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Autocomplete
                data-testid={`test-${MaterialKey.CEMENTSUPPLIER}`}
                key={MaterialKey.CEMENTSUPPLIER}
                options={cementSupplierOptions}
                // @ts-ignore - function takes a variable amount of arguments, but typescript still expects three
                value={getValueFromOption(
                  cementSupplierOptions,
                  'plantName',
                  [MaterialObjectKeys.CementSupplier],
                  ['plantName']
                )}
                getOptionSelected={(option, value) =>
                  option.plantName === value.plantName
                }
                getOptionLabel={option => option.plantName}
                onChange={(_, newValue) => {
                  setHasUserInput(true)
                  setMaterialPostObject((prevItems: MaterialObject) => ({
                    ...prevItems,
                    [MaterialObjectKeys.CementSupplier]: newValue
                      ? newValue
                      : '',
                  }))
                }}
                size="small"
                className={classes.inputField}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={'Cement Supplier'}
                    variant="outlined"
                    required
                  />
                )}
                disabled={isViewOnly}
              />
            </Grid>
          </>
        )}
        {materialType &&
          materialType !== MaterialType.CEMENT &&
          materialType !== MaterialType.CO2 && (
            <>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  data-testid={`test-${MaterialKey.SUPPLIERCOMPANY}`}
                  key={MaterialKey.SUPPLIERCOMPANY}
                  value={materialPostObject[MaterialObjectKeys.SupplierCompany]}
                  id={MaterialKey.SUPPLIERCOMPANY}
                  label={'Supplier Company'}
                  variant="outlined"
                  onChange={event =>
                    handleChange(
                      event,
                      event.target.value,
                      MaterialKey.SUPPLIERCOMPANY
                    )
                  }
                  size="small"
                  className={classes.inputField}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField
                  data-testid={`test-${MaterialKey.SUPPLIERPLANT}`}
                  key={MaterialKey.SUPPLIERPLANT}
                  value={materialPostObject[MaterialObjectKeys.SupplierPlant]}
                  id={MaterialKey.SUPPLIERPLANT}
                  label={'Supplier Plant'}
                  variant="outlined"
                  onChange={event =>
                    handleChange(
                      event,
                      event.target.value,
                      MaterialKey.SUPPLIERPLANT
                    )
                  }
                  size="small"
                  className={classes.inputField}
                  disabled={isViewOnly}
                />
              </Grid>
            </>
          )}
        {showMaterialAliasInputs(
          isEditMode,
          isUnclassifiedMaterial,
          materialType
        ) && (
          <>
            {/* Render multiple Alias Fields as needed */}
            {aliasFields.map(index => (
              <>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    key={index}
                    value={
                      materialPostObject['aliases']
                        ? materialPostObject['aliases'][index]
                        : ''
                    }
                    data-testid={`test-alias-${index}`}
                    label={'Alias'}
                    variant="outlined"
                    onChange={event =>
                      handleAliasChange(event, event.target.value, index)
                    }
                    size="small"
                    className={classes.inputField}
                    disabled={isEditMode && !tssCanWrite(roles)}
                    error={
                      duplicateAliasFieldError?.includes(index) ||
                      duplicateMaterialAliasFieldError?.includes(index)
                    }
                    helperText={
                      duplicateAliasFieldError?.includes(index) ||
                      duplicateMaterialAliasFieldError?.includes(index)
                        ? 'This alias has already been used. Please choose another.'
                        : ''
                    }
                    InputProps={{
                      endAdornment: (duplicateAliasFieldError?.includes(
                        index
                      ) ||
                        duplicateMaterialAliasFieldError?.includes(index)) && (
                        <InputAdornment position="end">
                          <ReportProblemOutlinedIcon color="error" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ))}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Button
                color="primary"
                variant="outlined"
                startIcon={<Add />}
                onClick={addAliasField}
                style={{ marginTop: '10px' }}
                disabled={isEditMode && !tssCanWrite(roles)}
              >
                <Typography>
                  <strong>Add Alias</strong>
                </Typography>
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <Grid container direction="row" spacing={4}>
        {materialType && (
          <Grid item xs={12} style={{ marginBottom: '-20px' }}>
            <Divider />
          </Grid>
        )}
        {/* Render remaining dynamic fields */}
        {materialType &&
          // eslint-disable-next-line array-callback-return
          materialHashMap?.map((meta: IMaterialTypeMetadata) => {
            if (!isDefaultField(meta) && materialType !== MaterialType.CO2) {
              switch (meta.dataType) {
                case MaterialDataType.ENUM:
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Autocomplete
                        data-testid={`test-${meta.key}`}
                        key={meta.key}
                        id={meta.key}
                        value={convertToNameFormat(
                          materialPostObject[meta.kelownaKeyName as string]
                        )}
                        options={getFieldOptions(meta.key)}
                        getOptionSelected={(option, value) => option === value}
                        getOptionLabel={option => option}
                        onChange={(_, newValue) => {
                          setHasUserInput(true)
                          setMaterialPostObject(
                            (prevItems: MaterialObject) => ({
                              ...prevItems,
                              [MaterialObjectKeys[
                                meta.key as keyof typeof MaterialObjectKeys
                              ]]: newValue ? newValue : '',
                            })
                          )
                        }}
                        size="small"
                        className={classes.inputField}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={meta.displayName}
                            variant="outlined"
                          />
                        )}
                        disabled={isViewOnly}
                      />
                    </Grid>
                  )
                case MaterialDataType.STRING:
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        data-testid={`test-${meta.key}`}
                        key={meta.key}
                        id={meta.key}
                        value={
                          materialPostObject[meta.kelownaKeyName as string]
                        }
                        label={meta.displayName}
                        variant="outlined"
                        onChange={event =>
                          handleChange(event, event.target.value, meta.key)
                        }
                        size="small"
                        className={classes.inputField}
                        disabled={isEditMode && !tssCanWrite(roles)}
                      />
                    </Grid>
                  )
                case MaterialDataType.NUMERIC:
                  return renderNumericTextField(meta)
                default:
                  return null
              }
            } else if (
              materialType === MaterialType.CO2 &&
              meta.dataType === MaterialDataType.NUMERIC
            ) {
              return renderNumericTextField(meta)
            }
          })}
      </Grid>
      {materialType === MaterialType.CEMENT && (
        <Grid container direction="row" spacing={4} alignItems="center">
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              align="left"
              style={{ marginBottom: '-10px' }}
            >
              Assigned Plant
            </Typography>
            <Typography align="left" style={{ marginBottom: '-20px' }}>
              Select one or more concrete plants to view and adjust cement
              composition.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              data-testid={'test-selectPlant'}
              id={'selectPlant'}
              value={
                selectedPlants &&
                plantOptions.filter(option =>
                  selectedPlants.includes(option['displayName'])
                )
              }
              options={plantOptions}
              getOptionLabel={option => option.displayName}
              onChange={(_, newValue) => {
                const selectedDisplayNames = newValue.map(
                  option => option.displayName
                )
                setSelectedPlants(selectedDisplayNames)
              }}
              size="small"
              className={classes.inputField}
              multiple
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  label={'Select Plant'}
                  variant="outlined"
                  onKeyDown={isEditMode && preventEventOnKeyDown(['Backspace'])}
                />
              )}
              renderTags={value =>
                value.map((option, index) => (
                  <ConditionalDeleteChip
                    key={`index-${option.displayName}`}
                    label={option.displayName}
                    onDelete={() => {
                      const newSelected: string[] = [...value]
                      newSelected.splice(index, 1)
                      setSelectedPlants(
                        newSelected.map(option => option.displayName)
                      )
                    }}
                    isDeletable={!isEditMode}
                  />
                ))
              }
              disabled={isViewOnly}
              disableClearable={isEditMode}
            />
          </Grid>
          <PlantCompositionCardLogical
            roles={roles}
            selectedPlants={selectedPlants}
            setSelectedPlants={setSelectedPlants}
            isEditMode={isEditMode}
            divisionId={materialPostObject.divisionId}
            currentMaterialId={materialPostObject.materialId || null}
            currentMaterialSubtype={materialPostObject.materialType}
            currentPlantName={
              materialPostObject?.cementSupplier?.plantName || ''
            }
            currentAliases={materialPostObject?.aliases}
            setCementCompositionData={setCementCompositionData}
          />
        </Grid>
      )}
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="center"
        justify="flex-end"
        style={{ paddingTop: '20px' }}
      >
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleCancel}
            disabled={isEditMode && !tssCanWrite(roles)}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={canSave(
              materialType,
              isDefaultMaterial,
              materialPostObject
            )}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      {modalOpen && (
        <MaterialManagerModalLogical
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          modalType={modalType}
          setModalType={setModalType}
          existingMaterial={existingMaterial}
          currentMaterial={currentMaterial}
          plantData={plantData}
          successData={successData}
          setSuccessData={setSuccessData}
          sendMaterialData={sendMaterialData}
          sendMaterialAndAliasData={sendMaterialAndAliasData}
          duplicateAliasModalError={duplicateAliasModalError}
          setDuplicateAliasModalError={setDuplicateAliasModalError}
          modalDataReset={modalDataReset}
          setCurrentView={setCurrentView}
          setSelectedMaterial={setSelectedMaterial}
          setMaterialPostObject={setMaterialPostObject}
          setAliasFields={setAliasFields}
          setAliasFieldValues={setAliasFieldValues}
          errorMessage={errorMessage}
        />
      )}
    </form>
  )
}

export default MaterialFieldsPresentational
