import React, { useState, ChangeEvent, useEffect } from 'react'
import { Collapse, Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { isValid, parse } from 'date-fns'
import {
  DateRange,
  SonarFilterType,
  SonarDateRange,
} from '../../../TSS/Logic/Types'
import {
  convertFormattedDateToISO,
  convertUTCISOToLocalISO,
  formatISODate,
  getDefaultSelectedRange,
  getDefaultStartDate,
  getLabelText,
} from '../Helpers/SonarHelpers'
import sonarDateRangeSelectHelper from './SonarDateRangeSelectHelper'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { ILabelValue } from '../../../Common/Logic/Types'

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  textFieldSelect: {
    '& .MuiInputBase-root': {
      paddingRight: '48px', // Prevent overlap w/dropdown icon
    },
  },
  smallIcon: {
    fontSize: '20px',
  },
}))

export interface ISonarDateRangeSelect {
  dateRangeStart: DateRange
  dateRangeEnd: DateRange
  parentClasses?: ClassNameMap
  filterType?: SonarFilterType | null
  rangeSelected?: SonarDateRange | null
  setRangeSelected?: (value: SonarDateRange | null) => void
  setStartDateSelected?: (val: string) => void
  setEndDateSelected?: (val: string) => void
  clearRangeSelected?: boolean
  showOnlyStartDate?: boolean
  showOnlyEndDate?: boolean
  endDateMin?: string
  endDateMax?: string
  disabled?: () => boolean
  disableCustomRangeEndDate?: boolean
}

const SonarDateRangeSelect: React.FC<ISonarDateRangeSelect> = ({
  dateRangeStart,
  dateRangeEnd,
  filterType,
  rangeSelected,
  setRangeSelected,
  setStartDateSelected,
  setEndDateSelected,
  clearRangeSelected,
  showOnlyStartDate,
  showOnlyEndDate,
  parentClasses,
  endDateMin,
  endDateMax,
  disabled,
  disableCustomRangeEndDate,
}) => {
  const classes = useStyles()

  // Convert ISO dates received from Kelowna (from UTC to local time)
  // This is to ensure that the date inputs are initially using local time for dates
  const newDateISO = new Date().toISOString()

  const localDateRangeStart = dateRangeStart
    ? convertUTCISOToLocalISO(dateRangeStart as string)
    : getDefaultStartDate(filterType)

  const localDateRangeEnd = dateRangeEnd
    ? convertUTCISOToLocalISO(dateRangeEnd as string)
    : newDateISO

  const [dateStart, setDateStart] = useState<DateRange>(localDateRangeStart)
  const [dateEnd, setDateEnd] = useState<DateRange>(localDateRangeEnd)

  // Show Custom Date Range fields if either a start or end date range has been passed down to the component
  const [selectedRange, setSelectedRange] = useState<string | null>(
    rangeSelected ??
      getDefaultSelectedRange(filterType, dateRangeStart, dateRangeEnd)
  )

  // Options for the Date Range
  const [sonarDateRangeOptions, setSonarDateRangeOptions] = useState<
    ILabelValue[]
  >([])

  const handleLastSeenDateRangeAutoComplete = (
    _event: ChangeEvent<{}>,
    selectedOption: ILabelValue | null
  ) => {
    const value = selectedOption?.value as SonarDateRange | null

    const {
      startDate,
      endDate,
      selectedRange,
    } = sonarDateRangeSelectHelper.handleDateRange(value, dateStart, dateEnd)

    setDateStart(startDate)
    setDateEnd(endDate)
    setSelectedRange(selectedRange)

    setStartDateSelected?.((startDate as string) || '')
    setEndDateSelected?.((endDate as string) || '')
    setRangeSelected?.(value)
  }

  const handleCustomDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputName = e.currentTarget.name

    // Before conversion, we need to ensure that the date is valid
    // Otherwise, an error is triggered that hangs the application
    // This usually happens when the date is typed in the input field
    const currentTargetValue = e.currentTarget.value
    const parsedDate = parse(currentTargetValue, 'yyyy-MM-dd', new Date())
    if (!isValid(parsedDate)) return

    const selectedDate: string = convertFormattedDateToISO(
      currentTargetValue
    ).substring(0, 10)

    if (inputName === 'dateStart') {
      setDateStart(selectedDate)
      setStartDateSelected?.(selectedDate)
    } else {
      setDateEnd(selectedDate)
      setEndDateSelected?.(selectedDate)
    }
  }

  useEffect(() => {
    if (clearRangeSelected) setSelectedRange(null)
  }, [clearRangeSelected])

  useEffect(() => {
    if (rangeSelected) setSelectedRange(rangeSelected)
  }, [rangeSelected])

  useEffect(() => {
    let tempDateRangeOptions: ILabelValue[] = []

    switch (filterType) {
      // Used by Down Snooze Modal
      case SonarFilterType.DownSnoozedModal:
        tempDateRangeOptions = [
          {
            label: '1 Day',
            value: SonarDateRange.OneDay,
          },
          {
            label: '7 Days',
            value: SonarDateRange.SevenDays,
          },
          {
            label: '30 Days',
            value: SonarDateRange.ThirtyDays,
          },
          {
            label: '90 Days',
            value: SonarDateRange.NinetyDays,
          },
          {
            label: 'Custom Date',
            value: SonarDateRange.CustomRange,
          },
        ]
        break
      // Used by Down System Tab of Sonar
      case SonarFilterType.Down: {
        tempDateRangeOptions = [
          { label: 'All Time', value: SonarDateRange.All },
          {
            label: 'Last 7 Days',
            value: SonarDateRange.Last7Days,
          },
          {
            label: 'Last 30 Days',
            value: SonarDateRange.Last30Days,
          },
          {
            label: 'Last 90 Days',
            value: SonarDateRange.Last90Days,
          },
          {
            label: 'Last 180 Days',
            value: SonarDateRange.Last180Days,
          },
          {
            label: 'Custom Range',
            value: SonarDateRange.CustomRange,
          },
        ]
        break
      }
      case SonarFilterType.Alarms:
        tempDateRangeOptions = [
          { label: 'All Time', value: SonarDateRange.All },
          { label: 'Today', value: SonarDateRange.Today },
          { label: 'Yesterday', value: SonarDateRange.Yesterday },
          {
            label: 'Last 7 Days',
            value: SonarDateRange.Last7Days,
          },
          {
            label: 'Last 30 Days',
            value: SonarDateRange.Last30Days,
          },
          {
            label: 'Last 90 Days',
            value: SonarDateRange.Last90Days,
          },
          {
            label: 'Last 180 Days',
            value: SonarDateRange.Last180Days,
          },
          {
            label: 'Custom Range',
            value: SonarDateRange.CustomRange,
          },
        ]
        break
      // Used by Alarms Tab of Sonar
      case SonarFilterType.AlarmSnoozedModal: {
        tempDateRangeOptions = [
          { label: '1 Day', value: SonarDateRange.OneDay },
          { label: '2 Days', value: SonarDateRange.TwoDays },
          {
            label: '7 Days',
            value: SonarDateRange.SevenDays,
          },
          {
            label: '14 Days',
            value: SonarDateRange.FourteenDays,
          },
          {
            label: 'Custom Date',
            value: SonarDateRange.CustomRange,
          },
        ]
        break
      }

      default: {
        // This sets all the SonarDateRange enum options
        tempDateRangeOptions = Object.values(SonarDateRange).map(value => ({
          label:
            value.charAt(0).toUpperCase() +
            value
              .slice(1)
              .replace(/([A-Z])/g, ' $1')
              .trim(),
          value,
        }))
        break
      }
    }
    //@ts-ignore
    setSonarDateRangeOptions(tempDateRangeOptions)
  }, [filterType])

  /* Fix defaults not being picked up from URL parameters */
  useEffect(() => {
    setDateStart(dateRangeStart)
    setDateEnd(dateRangeEnd)
  }, [dateRangeStart, dateRangeEnd])

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Autocomplete
          data-testid="date-selection"
          options={sonarDateRangeOptions}
          getOptionLabel={option => option.label}
          size="small"
          value={
            sonarDateRangeOptions.find(
              option => option.value === selectedRange
            ) || null
          }
          onChange={handleLastSeenDateRangeAutoComplete}
          renderInput={params => (
            <TextField
              {...params}
              label={getLabelText(filterType)}
              variant="outlined"
              autoComplete="off"
            />
          )}
          disabled={disabled ? disabled() : false}
        />
      </Grid>

      <Collapse
        in={selectedRange === SonarDateRange.CustomRange}
        timeout="auto"
        unmountOnExit
        className={classes.fullWidth}
      >
        {!showOnlyEndDate && (
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              label="Start Date"
              type="date"
              name="dateStart"
              value={formatISODate(dateStart as string)}
              disabled={disableCustomRangeEndDate}
              onChange={handleCustomDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{ 'data-testid': 'start-date' }}
              classes={{
                root: parentClasses?.textField,
              }}
            />
          </Grid>
        )}
        {!showOnlyStartDate && (
          <Grid item xs={12}>
            <TextField
              size="small"
              variant="outlined"
              label={
                showOnlyStartDate || showOnlyEndDate
                  ? 'Date Selection'
                  : 'End Date'
              }
              type="date"
              name="dateEnd"
              value={formatISODate(dateEnd as string)}
              onChange={handleCustomDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                'data-testid': 'end-date',
                min: endDateMin,
                max: endDateMax,
              }}
              classes={{
                root: parentClasses?.textField,
              }}
            />
          </Grid>
        )}
      </Collapse>
    </Grid>
  )
}

export default SonarDateRangeSelect
