import { TSortOrder } from '../../../../Common/Logic/Types'
import { SnoozeAlarmReason } from '../../../Logic/Types'
import { getCommonTableHeadCells } from '../../Helpers/SonarHelpers'

// Get the order direction for the table header
const getOrderDirection = (
  column: string,
  order: TSortOrder,
  orderBy: string
) => {
  return orderBy === column ? order : 'asc'
}

const convertTechnologyType = (technologyType: string) => {
  switch (technologyType) {
    case 'Plc':
      return 'PLC'
    case 'Pcb':
      return 'PCB'
    case 'ReclaimedWater':
      return 'RW'
    default:
      return technologyType
  }
}

const getAlarmsTableHeadCells = (isUTCBooleanString?: string) => {
  const isUTC = isUTCBooleanString === 'true'
  const additionalCells = [
    {
      align: 'left',
      id: 'alarmSeverity',
      name: 'Severity',
      sortable: true,
      width: '80px',
    },
    {
      align: 'left',
      id: 'description',
      name: 'Type',
      sortable: true,
      width: '200px',
    },
  ]

  return getCommonTableHeadCells(
    isUTC,
    'timeStamp',
    'Timestamp',
    '',
    'technology',
    additionalCells
  )
}

const getAlarmSnoozeReasonLabel = (reason: string | null | undefined) => {
  switch (reason) {
    case SnoozeAlarmReason.CarbonCureMaintenance:
      return 'Maintenance (CarbonCure)'
    case SnoozeAlarmReason.ProducerMaintenance:
      return 'Maintenance (Producer)'
    case SnoozeAlarmReason.PartInShipment:
      return 'Part in Shipment'
    case SnoozeAlarmReason.Other:
      return 'Other'
    default:
      return null
  }
}

const alarmsTableHelper = {
  convertTechnologyType,
  getAlarmsTableHeadCells,
  getOrderDirection,
  getAlarmSnoozeReasonLabel,
}

export default alarmsTableHelper
