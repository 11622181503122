import React, { useEffect, useState } from 'react'
import {
  Tabs,
  Tab,
  Box,
  makeStyles,
  Typography,
  Paper,
} from '@material-ui/core'
import TabPanel from '../../../Common/Components/TabPanel'
import ServiceCasesLogical from './ServiceCasesLogical'
import SystemDataLogical from './SystemDataLogical'
import InjectionGraphLogical from './InjectionGraphLogical'
import FilterPanelChips from '../../../Common/Components/FilterPanel/FilterPanelChips'
import { baseColors } from '../../../theme/colors'

const useStyles = makeStyles(() => ({
  tabLabel: {
    fontWeight: 'bold',
    fontSize: '14px',
    fontFamily: 'Urbanist',
    textTransform: 'capitalize',
    marginBottom: '-4px',
  },
  selectedTab: {
    color: baseColors.primary.main,
  },
  innerTabPanel: {
    padding: '3em',
    marginBottom: '3rem',
  },
  indicator: {
    marginLeft: '20px',
    maxWidth: 120,
  },
}))

function AlarmTabs(props) {
  const {
    handleTableSort,
    setOpen,
    selectedServiceCase,
    setSelectedServiceCase,
    tabValue,
    setTabValue,
    selectedTab,
    showClosedCases,
    setShowClosedCases,
    systemDataType,
    graphVisibility,
    activeFilters,
    filterChipsHandler,
    clearAllFilters,
  } = props

  const classes = useStyles()

  // add logic for pagination here

  const [order, setOrder] = useState()
  const [orderBy, setOrderBy] = useState()
  const [page, setPage] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(10)

  useEffect(() => {
    setOrder('asc')
    setOrderBy(null)
    setPage(0)
    setRowsPerPage(10)
  }, [tabValue])

  /** Switch to a different tab */
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <>
      <Box>
        <FilterPanelChips
          activeFilters={activeFilters}
          chipClickHandler={filterChipsHandler}
          buttonClickHandler={clearAllFilters}
        />
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          indicatorColor="primary"
          classes={{
            indicator: classes.indicator,
          }}
        >
          {['Service Cases', 'System Data', 'Injection Graphs'].map(
            (label, index) => (
              <Tab
                key={label}
                label={
                  <span
                    style={{ padding: '9px 16px' }}
                    className={
                      tabValue === index
                        ? `${classes.tabLabel} ${classes.selectedTab}`
                        : classes.tabLabel
                    }
                  >
                    {label}
                  </span>
                }
              />
            )
          )}
        </Tabs>
      </Box>
      <TabPanel tabValue={tabValue} index={0}>
        <ServiceCasesLogical
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          selectedServiceCase={selectedServiceCase}
          setSelectedServiceCase={setSelectedServiceCase}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          setOpen={setOpen}
          selectedTab={selectedTab}
          showClosedCases={showClosedCases}
          setShowClosedCases={setShowClosedCases}
        />
      </TabPanel>
      <TabPanel tabValue={tabValue} index={1}>
        <SystemDataLogical
          setOpen={setOpen}
          handleTableSort={handleTableSort}
          order={order}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          systemDataType={systemDataType}
        />
      </TabPanel>
      <TabPanel tabValue={tabValue} index={2}>
        <Paper variant="outlined" className={classes.innerTabPanel}>
          <>
            <Typography variant="h3">Injection Graphs</Typography>
            <InjectionGraphLogical graphVisibility={graphVisibility} />
          </>
        </Paper>
      </TabPanel>
    </>
  )
}

export default AlarmTabs
