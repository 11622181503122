import React, { useEffect, useState, useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { atomJWT, atomMaterialMetadata } from '../../Common/atoms'
import { tssCanWrite } from '../Logic/TSSLogic'
import { Add } from '@material-ui/icons'
import AddMaterialLogical from '../Components/AddMaterial/AddMaterialLogical'
import MaterialTableLogical from '../Components/MaterialTable/MaterialTableLogical'
import {
  IMaterialTypeMetadata,
  IMetaData,
  MaterialKey,
  MaterialManagerComponent,
  MaterialObject,
  MaterialSubTypeOptions,
  IMaterial,
  IManualPrompt,
  CustomerDivision,
} from '../Logic/Types'
import MaterialManagerBreadcrumb from '../Components/MaterialManagerBreadcrumb'
import FilterButton from '../../Common/Components/FilterPanel/FilterButton'
import theme from '../../theme/muiTheme'
import {
  getNewSettings,
  getUrlFromFilters,
  getMaterialMetadata,
} from '../Data/TSSDataHelpers'
import {} from '../../Common/Helpers/GeneralHelpers'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import FilterPanelLogical from '../../Common/Components/FilterPanel/FilterPanelLogical'
import { getDivisionNames } from '../../Common/Helpers/DataHelpers'
import FilterPanelChips from '../../Common/Components/FilterPanel/FilterPanelChips'
import {
  Filter,
  FilterComponentTypes,
  FilterOption,
  IActiveFilter,
} from '../../Common/Logic/Types'
import { useLocation } from 'react-router-dom'
import {
  getMaterialTypesMetadata,
  createMaterialTypeOptions,
  getMetadataFieldsByKey,
  searchMaterialHashmap,
  sortByKey,
  getSupplierCompanyAndPlantOptionsForFilterPanel,
  createDivisionData,
  convertCO2ToSubscript,
} from '../Helpers/MaterialManagerHelpers'
import AddDataProgressLostAlertLogical from '../Components/AddDataProgressLostAlert/AddDataProgressLostAlertLogical'
import MaterialHistoricalTablePresentational from '../Components/MaterialHistoricalTable/MaterialHistoricalTablePresentational'

const useStyles = makeStyles(() => ({
  floatingMetricSwitch: {
    position: 'absolute',
    right: '-0.5em',
  },
  //Filter Panel Styling
  ...theme.customClasses.filterPanel,
  filterPanelOpen: {
    ...theme.customClasses.filterPanel.filterPanelOpen,
    top: '340px',
    zIndex: 50,
    [theme.breakpoints.up(1378)]: {
      top: '290px',
    },
    [theme.breakpoints.down(600)]: {
      top: '340px',
    },
  },
  filterPanel: {
    ...theme.customClasses.filterPanel.filterPanel,
    zIndex: -50,
    top: '340px',
    [theme.breakpoints.up(1378)]: {
      top: '290px',
    },
  },
  btnContainer: {
    ...theme.customClasses.filterPanel.btnContainer,
    paddingLeft: '0px',
  },
}))

export interface IFilterSettings {
  customer: Array<number | null>
  materialType: Array<string | null>
  materialSubtype: Array<string | null>
  showOnlyIngested: boolean
  supplierCompany: Array<string | null>
  supplierPlant: Array<string | null>
}

function MaterialManagerView() {
  const [isLoading, setIsLoading] = useState(false)
  const [currentView, setCurrentView] = useState(
    MaterialManagerComponent.MaterialTable
  )
  /** Used for checking First Render */
  const [isFirstRender, setIsFirstRender] = useState(true)
  /** Material metadata */
  const [metaData, setMetaData] = useState<IMetaData>()
  /** The hashmap created from Metadata */
  const setMetadataMap = useSetRecoilState(atomMaterialMetadata)
  /** The details of the material being viewed/edited */
  const [selectedMaterial, setSelectedMaterial] = useState<
    IMaterial | null | undefined
  >()
  /** Determines if Data Progress Lost modal will appear when triggered */
  const [hasUserInput, setHasUserInput] = useState<boolean>(false)
  /** The manual prompt handler for the Data Progress Lost modal */
  const [manualPrompt, setManualPrompt] = useState<IManualPrompt | null>(null)
  /** Determines if the selected material is unclassified */
  const [isUnclassifiedMaterial, setIsUnclassifiedMaterial] = useState<boolean>(
    false
  )

  const JWT = useRecoilValue(atomJWT)
  const classes: ClassNameMap = useStyles()

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false)
  const [expandFilters, setExpandFilters] = useState({
    customer: false,
    materialDetails: false,
  })
  const [filterSettings, setFilterSettings] = useState<IFilterSettings>({
    customer: [],
    materialType: [],
    materialSubtype: [],
    showOnlyIngested: false,
    supplierCompany: [],
    supplierPlant: [],
  })
  const [filterCount, setFilterCount] = useState(0)
  const [activeFilters, setActiveFilters] = useState<
    { property: string; label: string }[]
  >([])
  const [customerOptions, setCustomerOptions] = useState<
    {
      name: string
      corporationId: number
      divisionId: number
      isImperial: boolean
    }[]
  >([])
  const [
    customerFilterSelection,
    setCustomerFilterSelection,
  ] = useState<CustomerDivision | null>(null)
  const [materialTypeOptions, setMaterialTypeOptions] = useState<
    MaterialObject
  >([])
  const [materialSubtypeOptions, setMaterialSubtypeOptions] = useState<
    FilterOption[]
  >([])
  const [supplierCompanyOptions, setSupplierCompanyOptions] = useState<
    FilterOption[]
  >([])
  const [supplierPlantOptions, setSupplierPlantOptions] = useState<
    FilterOption[]
  >([])
  const { search } = useLocation()

  const viewSwitch = (currentView: string) => {
    switch (currentView) {
      case MaterialManagerComponent.AddMaterial:
        return (
          <AddMaterialLogical
            metaData={metaData}
            setCurrentView={setCurrentView}
            isEditMode={false}
            setHasUserInput={setHasUserInput}
            setSelectedMaterial={setSelectedMaterial}
            customerFilterSelection={customerFilterSelection}
          />
        )
      case MaterialManagerComponent.EditMaterial:
        return (
          <AddMaterialLogical
            metaData={metaData}
            setCurrentView={setCurrentView}
            selectedMaterial={selectedMaterial}
            isEditMode={true}
            setHasUserInput={setHasUserInput}
            setSelectedMaterial={setSelectedMaterial}
            isUnclassifiedMaterial={isUnclassifiedMaterial}
          />
        )
      default:
        return (
          <MaterialTableLogical
            setCurrentView={setCurrentView}
            setIsLoading={setIsLoading}
            filterSettings={filterSettings}
            setSelectedMaterial={setSelectedMaterial}
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            isFilterPanelOpen={isFilterPanelOpen}
            setIsUnclassifiedMaterial={setIsUnclassifiedMaterial}
          />
        )
    }
  }

  const handleFilterButtonClick = () => {
    setIsFilterPanelOpen(prev => !prev)
  }

  const handleCustomerChange = (
    _: Filter,
    division: { divisionId: number }
  ) => {
    const divisionId = division ? [division.divisionId] : []
    setFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        customer: divisionId,
      }
    })
  }

  const handleMaterialTypeChange = (
    _: Filter,
    materialType: MaterialObject
  ) => {
    const value = materialType ? [materialType.option] : []
    setFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        materialType: value,
      }
    })
  }

  const handleMaterialSubtypeChange = (
    _: Filter,
    materialSubtype: FilterOption
  ) => {
    const value = materialSubtype ? [materialSubtype.id] : []
    setFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        materialSubtype: value,
      }
    })
  }

  const handleSupplierCompanyChange = (
    _: Filter,
    supplierCompany: FilterOption
  ) => {
    const value = supplierCompany ? [supplierCompany.name] : []
    setFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        supplierCompany: value,
      }
    })
  }

  const handleSupplierPlantChange = (
    _: Filter,
    supplierPlant: FilterOption
  ) => {
    const value = supplierPlant ? [supplierPlant.name] : []
    setFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        supplierPlant: value,
      }
    })
  }

  const handleShowOnlyIngestedChange = () => {
    setFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        showOnlyIngested: !prevSettings.showOnlyIngested,
      }
    })
  }

  const getValue = (filter: Filter) => {
    if (filter.property === 'customer') {
      const customer = customerOptions.find(
        option => option.divisionId === Number(filterSettings.customer[0])
      )
      return customer ? customer : null
    } else if (filter.property === 'materialType') {
      const materialType = materialTypeOptions.find(
        (option: MaterialObject) =>
          option.option === filterSettings.materialType[0]
      )
      return materialType ? materialType : null
    } else if (filter.property === 'materialSubtype') {
      const materialSubtype = materialSubtypeOptions.find(
        (option: FilterOption) =>
          option.id === filterSettings.materialSubtype[0]
      )
      return materialSubtype ? materialSubtype : null
    } else if (filter.property === 'supplierCompany') {
      const supplierCompany = supplierCompanyOptions.find(
        (option: FilterOption) =>
          option.name === filterSettings.supplierCompany[0]
      )
      return supplierCompany ? supplierCompany : null
    } else if (filter.property === 'supplierPlant') {
      const supplierPlant = supplierPlantOptions.find(
        (option: FilterOption) =>
          option.name === filterSettings.supplierPlant[0]
      )
      return supplierPlant ? supplierPlant : null
    } else if (filter.property === 'showOnlyIngested') {
      return filterSettings.showOnlyIngested
    }
  }

  const filterPanel = [
    {
      name: 'CUSTOMER',
      category: 'customer',
      filters: [
        {
          property: 'customer',
          name: 'Producer',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: customerOptions,
          onChangeHandler: handleCustomerChange,
        },
      ],
    },
    {
      name: 'MATERIAL DETAILS',
      category: 'materialDetails',
      filters: [
        {
          property: 'materialType',
          name: 'Material Type',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: materialTypeOptions,
          onChangeHandler: handleMaterialTypeChange,
          renderOption: (option: FilterOption) => {
            return convertCO2ToSubscript(option.name)
          },
          getOptionLabel: (option: FilterOption) => {
            return convertCO2ToSubscript(option.name)
          },
        },
        {
          property: 'materialSubtype',
          name: 'Material Subtype',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: materialSubtypeOptions,
          onChangeHandler: handleMaterialSubtypeChange,
          renderOption: (option: FilterOption) => {
            return convertCO2ToSubscript(option.name)
          },
          getOptionLabel: (option: FilterOption) => {
            return convertCO2ToSubscript(option.name)
          },
        },
        {
          property: 'supplierCompany',
          name: 'Supplier Company',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: supplierCompanyOptions,
          onChangeHandler: handleSupplierCompanyChange,
        },
        {
          property: 'supplierPlant',
          name: 'Supplier Plant',
          componentType: FilterComponentTypes.AutocompleteSingle,
          options: supplierPlantOptions,
          onChangeHandler: handleSupplierPlantChange,
        },
        {
          property: 'showOnlyIngested',
          name: 'Only show Ingested Data',
          componentType: FilterComponentTypes.Checkbox,
          onChangeHandler: handleShowOnlyIngestedChange,
          label: 'Only show Ingested Data',
          labelPlacement: 'end',
          color: 'primary',
        },
      ],
    },
  ]

  const handleChipAddition = useCallback(
    (filter, chipArray) => {
      switch (filter) {
        case 'customer':
          const customer = customerOptions.find(
            option => option.divisionId === Number(filterSettings.customer[0])
          )
          if (customer)
            chipArray.push({ property: 'customer', label: customer.name })
          break
        case 'materialType':
          const materialType = materialTypeOptions.find(
            (option: MaterialObject) =>
              option.option === filterSettings.materialType[0]
          )
          if (materialType)
            chipArray.push({
              property: 'materialType',
              label: convertCO2ToSubscript(materialType.name),
            })
          break
        case 'materialSubtype':
          const materialSubtype = materialSubtypeOptions.find(
            (option: FilterOption) =>
              option.id === filterSettings.materialSubtype[0]
          )
          if (materialSubtype)
            chipArray.push({
              property: 'materialSubtype',
              label: convertCO2ToSubscript(materialSubtype.name),
            })
          break
        case 'supplierCompany':
          const supplierCompany = supplierCompanyOptions.find(
            (option: FilterOption) =>
              option.name === filterSettings.supplierCompany[0]
          )
          if (supplierCompany)
            chipArray.push({
              property: 'supplierCompany',
              label: supplierCompany.name,
            })
          break
        case 'supplierPlant':
          const supplierPlant = supplierPlantOptions.find(
            option => option.name === filterSettings.supplierPlant[0]
          )
          if (supplierPlant)
            chipArray.push({
              property: 'supplierPlant',
              label: supplierPlant.name,
            })
          break
        case 'showOnlyIngested':
          if (filterSettings.showOnlyIngested)
            chipArray.push({
              property: 'showOnlyIngested',
              label: 'Show Only Ingested',
            })
          break
        default:
          break
      }
    },
    [
      customerOptions,
      materialTypeOptions,
      materialSubtypeOptions,
      supplierCompanyOptions,
      supplierPlantOptions,
      filterSettings,
    ]
  )

  const deleteChipHandler = (filterToDelete: IActiveFilter) => {
    if (filterToDelete.property !== 'showOnlyIngested') {
      setFilterSettings(prevSettings => {
        return {
          ...prevSettings,
          [filterToDelete.property]: [],
        }
      })
    } else {
      setFilterSettings(prevSettings => {
        return {
          ...prevSettings,
          [filterToDelete.property]: false,
        }
      })
    }
  }

  const deleteAllChipHandler = () => {
    setFilterSettings(prevSettings => {
      return {
        ...prevSettings,
        customer: [],
        materialType: [],
        materialSubtype: [],
        supplierCompany: [],
        supplierPlant: [],
        showOnlyIngested: false,
      }
    })
  }

  const getAllMaterialSubtypeOptions = () => {
    const allSubtypeOptions = []
    for (const key in MaterialSubTypeOptions) {
      allSubtypeOptions.push({
        id: key,
        name:
          MaterialSubTypeOptions[key as keyof typeof MaterialSubTypeOptions],
      })
    }
    return allSubtypeOptions
  }

  /** Get Division Names for Customer filter options */
  useEffect(() => {
    setIsLoading(true)
    getDivisionNames(true)
      .then(result => {
        if (result.ok)
          result
            .json()
            .then(data => {
              setCustomerOptions(data)
              setIsLoading(false)
            })
            .catch(e => console.log(e))
      })
      .catch(e => console.log(e))
  }, [])

  /** Get Material Data and set up Metadata. Only needs to be called on first render */
  useEffect(() => {
    if (isFirstRender) {
      // FIRST RENDER
      const params = new URLSearchParams(search)
      const newSettings = getNewSettings(filterSettings, params)
      setFilterSettings(newSettings as IFilterSettings)
      setIsFirstRender(false)
      if (!metaData) {
        setIsLoading(true)
        getMaterialMetadata()
          .then(res => {
            if (res.ok)
              res
                .json()
                .then(data => {
                  setMaterialTypeOptions(createMaterialTypeOptions(data))
                  const materialSubtypeOptionsUnsorted = getAllMaterialSubtypeOptions()
                  const materialSubtypeOptionsSorted = sortByKey(
                    materialSubtypeOptionsUnsorted,
                    'name'
                  )
                  setMaterialSubtypeOptions(
                    materialSubtypeOptionsSorted as FilterOption[]
                  )
                  setMetaData(data)
                  const orcaMetaDataMap = getMaterialTypesMetadata(data)
                  const admixtureMap = orcaMetaDataMap.get(
                    'ADMIXTURE'
                  ) as IMaterialTypeMetadata[]

                  const supplierCompanies = searchMaterialHashmap(
                    admixtureMap,
                    MaterialKey.SUPPLIERCOMPANY,
                    'applicableValues'
                  )
                  const supplierPlants = searchMaterialHashmap(
                    admixtureMap,
                    MaterialKey.SUPPLIERPLANT,
                    'applicableValues'
                  )
                  const cementSuppliers = getMetadataFieldsByKey(
                    data,
                    MaterialKey.CEMENTSUPPLIER,
                    'cementPlants'
                  )

                  const {
                    supplierCompanyOptionsUnsorted,
                    supplierPlantOptionsUnsorted,
                  } = getSupplierCompanyAndPlantOptionsForFilterPanel(
                    data,
                    supplierCompanies,
                    supplierPlants,
                    cementSuppliers
                  )
                  setSupplierCompanyOptions(
                    sortByKey(
                      supplierCompanyOptionsUnsorted,
                      'name'
                    ) as FilterOption[]
                  )
                  setSupplierPlantOptions(
                    sortByKey(
                      supplierPlantOptionsUnsorted,
                      'name'
                    ) as FilterOption[]
                  )
                  setMetadataMap(orcaMetaDataMap)
                  setIsFirstRender(false)
                  setIsLoading(false)
                })
                .catch(e => console.log(e))
          })
          .catch(e => console.log(e))
      }
    } else {
      window.history.replaceState(
        null,
        'New Page Title',
        getUrlFromFilters(filterSettings, 'MaterialManager')
      )
    }
  }, [
    metaData,
    setMetaData,
    isFirstRender,
    setMetadataMap,
    filterSettings,
    search,
  ])

  useEffect(() => {
    const chipValues: Array<IActiveFilter> = []
    for (const filter in filterSettings) {
      handleChipAddition(filter, chipValues)
    }
    setFilterCount(chipValues.length)
    setActiveFilters(chipValues)
  }, [filterSettings, handleChipAddition])

  useEffect(() => {
    if (filterSettings?.customer.length > 0) {
      setCustomerFilterSelection(
        createDivisionData(customerOptions, filterSettings.customer[0])
      )
    }
  }, [customerOptions, filterSettings])

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: parseInt('50', 10) }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Container
        maxWidth="xl"
        style={{ marginBottom: '64px', padding: '0em 0.5em' }}
      >
        <FilterPanelLogical
          open={isFilterPanelOpen}
          setOpen={setIsFilterPanelOpen}
          filterPanel={filterPanel}
          parentClasses={classes}
          expandFilters={expandFilters}
          setExpandFilters={setExpandFilters}
          getValue={getValue}
        />
        <div
          className={
            isFilterPanelOpen
              ? classes.wrapperShifted
              : classes.wrapperUnshifted
          }
        >
          <Grid container direction="column" spacing={2} justify="space-around">
            <Grid
              item
              xs
              style={{
                marginBottom: '40px',
              }}
            >
              <Typography variant="h2">Material Manager</Typography>
              <Typography>Upload, edit and or review material data.</Typography>
            </Grid>
            {currentView === MaterialManagerComponent.MaterialTable && (
              <>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                  style={{
                    marginTop: '1em',
                    padding: '0em 1em',
                    height: '40px',
                  }}
                >
                  <Grid item>
                    <FilterButton
                      open={isFilterPanelOpen}
                      parentClasses={classes}
                      filterCount={filterCount}
                      onClickHandler={handleFilterButtonClick}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Add />}
                      disabled={!tssCanWrite(JWT.roles)}
                      onClick={() => {
                        setCurrentView(MaterialManagerComponent.AddMaterial)
                        setIsFilterPanelOpen(false)
                      }}
                    >
                      Add Material
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginBottom: '40px',
                  }}
                >
                  <FilterPanelChips
                    activeFilters={activeFilters}
                    chipClickHandler={deleteChipHandler}
                    buttonClickHandler={deleteAllChipHandler}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              style={{ marginTop: '-0.5em', paddingTop: 0, paddingBottom: 0 }}
            >
              <MaterialManagerBreadcrumb
                currentView={currentView}
                setCurrentView={setCurrentView}
                hasUserInput={hasUserInput}
                setManualPrompt={setManualPrompt}
              />
            </Grid>
            <Grid item xs style={{ marginTop: '0.6em' }}>
              {viewSwitch(currentView)}
            </Grid>
          </Grid>
        </div>
        <AddDataProgressLostAlertLogical
          hasUserInput={hasUserInput}
          manualPrompt={manualPrompt}
          setManualPrompt={setManualPrompt}
        />
        <MaterialHistoricalTablePresentational />
      </Container>
    </>
  )
}

export default MaterialManagerView
