import { Button, Grid, Typography, makeStyles } from '@material-ui/core'
import React, { Dispatch, SetStateAction } from 'react'
import {
  ICommissionReportSettings,
  IMixDesignDetailsSettings,
  IMixSelection,
  IReportMixSelectionSettings,
} from '../Logic/Types'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import cloneDeep from 'lodash.clonedeep'
//@ts-ignore
import { Link } from 'react-router-dom'
import {
  getMixDesignDetailLabels,
  getPropertyValue,
} from '../Helpers/CommissionReportHelpers'
import CommissionReportMixDesignDetailsRow from './CommissionReportMixDesignDetailsRow'

export interface ICommissionReportMixDesignDetailsProps {
  mixSelection: IMixSelection
  setReportSettings: Dispatch<SetStateAction<ICommissionReportSettings>>
  reportSettings: ICommissionReportSettings
  isMultiMix?: boolean
  setHasUserInput: Dispatch<SetStateAction<boolean>>
}

export interface IMixSelectionProperty {
  id: string
  name: string
  isReadOnly?: boolean
}

const useStyles = makeStyles(theme => ({
  singleMixContainer: {
    paddingRight: '32px',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  multiMixContainer: {
    padding: '24px',
    borderRadius: '4px',
    boxShadow:
      '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    height: '100%',
  },
  buttonLinkGrid: {
    marginTop: '18px',
  },
  arrowIcon: {
    fontSize: '20px',
    marginRight: '8px',
  },
}))

const CommissionReportMixDesignDetails = (
  props: ICommissionReportMixDesignDetailsProps
) => {
  const {
    reportSettings,
    mixSelection,
    setReportSettings,
    isMultiMix,
    setHasUserInput,
  } = props
  const classes = useStyles()

  const hasScmPercent = !!mixSelection?.scmPercent
  const mixDesignDetailsPropertyLabels = getMixDesignDetailLabels(
    reportSettings?.plantType,
    reportSettings?.isMetric,
    hasScmPercent
  )

  const handleCheckboxClick = (
    property: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setReportSettings(prevSettings => {
      const newSettings = cloneDeep(prevSettings)
      if (!mixSelection.mixDesignId) return prevSettings
      const matchedMixSettings = newSettings.mixDesignSettings.find(
        (settings: IReportMixSelectionSettings) =>
          settings.mixDesignId === mixSelection.mixDesignId
      )
      if (!matchedMixSettings) return prevSettings
      const matchedMixDesignSettings = matchedMixSettings
      matchedMixDesignSettings.mixDesignPropertiesIncludedOnCustomerReport[
        property as keyof IMixDesignDetailsSettings
      ] = e.target.checked
      return newSettings
    })
    setHasUserInput(true)
  }

  const getMixDesignDetailsPropertyValue = (
    property: IMixSelectionProperty
  ) => {
    if (!mixSelection.mixDesignId) return false
    const matchMixDesignSettings = reportSettings?.mixDesignSettings.find(
      (settings: IReportMixSelectionSettings) =>
        settings.mixDesignId === mixSelection.mixDesignId
    )
    if (!matchMixDesignSettings) return false
    const value = getPropertyValue(
      mixSelection,
      property.id,
      reportSettings?.isMetric
    )
    const isChecked =
      matchMixDesignSettings.mixDesignPropertiesIncludedOnCustomerReport[
        property.id as keyof IMixDesignDetailsSettings
      ]
    if (property.id === 'designStrength') {
      return value ? isChecked : false
    }
    if (['cementTypes', 'scmTypes'].includes(property.id)) {
      return Array.isArray(value) && value.length ? isChecked : false
    }
    return value === null || value === undefined ? false : isChecked
  }

  const getDisplayProperty = (propertyId: string) => {
    const value = getPropertyValue(
      mixSelection,
      propertyId,
      reportSettings?.isMetric
    )
    const hasProperty = Array.isArray(value) ? value.length > 0 : value != null

    let displayProperty = '—'

    if (Array.isArray(value) && value.length) {
      displayProperty = value.join(',\n')
    } else if (hasProperty) {
      displayProperty = value as string
    }

    return { displayProperty, hasProperty }
  }

  const getTooltipTitle = (propertyId: string) => {
    const propertyValue = getPropertyValue(
      mixSelection,
      propertyId,
      reportSettings?.isMetric
    )
    if (propertyValue === null || propertyValue === undefined) {
      return 'Unable to select property with no value'
    } else if (propertyId === 'designStrength') {
      return 'Design Strength is required'
    } else {
      return ''
    }
  }

  const path = `/Concrete/AddData/AddMixDesign?divisionId=${reportSettings?.producerId}&plantId=${reportSettings?.plantId}&mixDesignId=${mixSelection?.mixDesignId}`
  return (
    <Grid
      container
      direction="row"
      className={
        isMultiMix ? classes.multiMixContainer : classes.singleMixContainer
      }
    >
      <Grid item>
        <Typography
          style={{ marginBottom: 0 }}
          variant={isMultiMix ? 'h5' : 'h4'}
        >
          {isMultiMix ? mixSelection.mixCode : 'Mix Design Details'}
        </Typography>
      </Grid>
      <Grid container item>
        {mixDesignDetailsPropertyLabels.map(property => {
          const { displayProperty, hasProperty } = getDisplayProperty(
            property.id
          )
          const hasValue = Boolean(getMixDesignDetailsPropertyValue(property))

          return (
            <CommissionReportMixDesignDetailsRow
              key={property.id}
              property={property}
              displayProperty={displayProperty}
              hasProperty={hasProperty}
              hasValue={hasValue}
              handleCheckboxClick={handleCheckboxClick}
              getTooltipTitle={getTooltipTitle}
              getMixDesignDetailsPropertyValue={
                getMixDesignDetailsPropertyValue
              }
            />
          )
        })}
      </Grid>
      <Grid item className={classes.buttonLinkGrid}>
        <Button
          component={Link}
          style={{ paddingLeft: 0, textTransform: 'capitalize' }}
          color="primary"
          type="Link"
          target="_blank"
          to={path}
        >
          <ArrowBackIcon className={classes.arrowIcon} /> View Mix Data
        </Button>
      </Grid>
    </Grid>
  )
}

export default CommissionReportMixDesignDetails
